<template>
  <div class="modal-event-details">
    <div class="content">
      <div class="header">
        <div class="info">
          <div class="product-info">Lucky Six</div>
          <div class="round-info">{{'general_round' | translate}} {{ round }}</div>
        </div>
        <i class="icon icon-close-a" @click="toggleStats()"></i>
      </div>
      <div class="body">
        <DrawDetails :tabs="eventDetailsTabs"></DrawDetails>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DrawDetails from '../drawDetails/DrawDetailsWrapper';

export default {
  name: 'ModalEventDetails',
  components: {
    DrawDetails,
  },
  data() {
    return {
      eventDetailsTabs: [
        {
          id: 0,
          name: 'general_statistics',
          component: 'Statistics',
        },
        {
          id: 1,
          name: 'general_results',
          component: 'Results',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'round',
      'appLayout',
    ]),
  },
  methods: {
    ...mapActions([
      'toggleStats',
    ]),
  },
};
</script>

<style lang="scss">
  .modal-event-details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;

    .content {
      height: 100%;

      .header {
        float: left;
        width: 100%;
        height: 100px;
        background-color: var(--modal-header-bg, var(--brand-bg));
        position: relative;
        color: var(--text-primary-1);

        .info, .icon {
          position: absolute;
        }

        .info {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          div {
            width: 100%;
            margin-top: 7px;
          }

          .product-info {
            font-size: 22px;
            font-weight: 500;
          }

          .round-info {
            font-size: 14px;
            font-weight: 700;
            text-align: center;
          }
        }

        .icon {
          top: 16px;
          right: 16px;
          font-size: 24px;
          cursor: pointer;
        }
      }

      .body {
        float: left;
        width: 100%;
        height: 100%;
        background-color: var(--card-bg);
      }
    }
  }

  @media (max-width: 660px) {
    .modal-event-details {
      .content {
        .header {
          height: 81px;
        }
      }
    }
  }

  @media (min-width: 661px) {
    .modal-event-details {
      display: none;
      .content {
        .body {
          min-height: 25vh;
          max-height: 50vh;
          overflow: auto;
        }
      }
    }
  }
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "colors-wrap" }, [
    _c(
      "div",
      {
        staticClass: "checkbox color",
        class: [
          { active: _vm.color.active || _vm.allColorsSelected },
          "color-group-" + _vm.index,
        ],
        on: {
          click: function ($event) {
            return _vm.selectColor(_vm.color, _vm.index)
          },
        },
      },
      [_c("input", { attrs: { type: "checkbox" } }), _c("label")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
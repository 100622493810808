import { v4uuid } from '@nsoft/games-sdk-js/src/utility/index';
import { upperFirst } from 'lodash';
import http from './http';
import common from './common';
import { serverEnv } from '../../config';

export default {
  getAppConfig(data) {
    const { endpoint } = data;
    const path = data.paths.config;

    const options = {
      method: 'GET',
      url: `${endpoint}${path}`,
      params: {
        cpvUuid: data.product.cpvUuid,
      },
      headers: common.getGlobalHeaders(data),
    };

    return http(options);
  },
  getSevenAppConfig(data) {
    const { endpoint } = data;
    const path = data.paths.config;

    const options = {
      method: 'GET',
      url: `${endpoint}${path}/${data.product.cpvUuid}`,
      params: {
        cpvUuid: data.product.cpvUuid,
        product: data.product.productName,
        channel: upperFirst(data.clientplatform || data.application || 'web'),
      },
      headers: common.getGlobalHeaders(data),
    };

    return http(options);
  },
  getPrintTemplate(data) {
    let template = '';
    const messages = data.translations;
    const { currency } = data.appConfig.company;
    const { bets } = data.ticket;
    template += 'LuckySix;';
    template += "{{ ticketDateTimeUTC | date('d.m.Y H:i', false) }};";
    template += `${messages.general_pay_in} {{ payin | number_format(2, '.', ',') }}${currency} `;
    template += `${messages.general_payout} {{ payout | number_format(2, '.', ',') }}${currency} `;
    return (
      template + bets.map((bet) => {
        const betOutcome = bet.value;
        const betMarket = bet.subtitle;
        let body = '';
        let round = '';
        for (let i = 0; i < bet.rounds; i += 1) {
          round = `${messages.general_round} : {{bets[0].eventId + ${i}}}`;
          body += `${round} `;
          body += `${betOutcome} `;
          body += `${betMarket} `;
        }
        return body;
      }).join('; ') + '{{ id }}'
    );
  },
  payinTicket(payload) {
    const ticketData = {
      ticket: common.formatTicketData(payload.ticket),
      metadata: common.getMetaData(payload.player),
      additionalInfo: null,
    };

    const additionalHeaders = {
      'X-AUTH-TOKEN': payload.player.auth.token,
    };

    const { endpoint } = payload.platform;
    const path = payload.platform.paths.ticket.add;

    const options = {
      method: 'POST',
      url: `${endpoint}${path}`,
      headers: { ...common.getGlobalHeaders(payload.appConfig), ...additionalHeaders },
      data: ticketData,
    };

    return http(options);
  },
  payinSevenTicket(payload) {
    const headers = common.getSevenHeaders(payload);
    const path = payload.platform.paths.ticket.add;
    const { ticketPrintEnabled } = payload.appConfig.company;
    const additionalInfo = {
      printTemplate: this.getPrintTemplate(payload),
      timezone: {
        offset: parseInt(payload.player.timezoneOffset, 10),
      },
    };
    const { dc = '' } = payload.appConfig.company;
    let url = `https://services${dc}.7platform.com${path}`;
    if (serverEnv === 'staging') {
      url = `https://services-staging.7platform.com${path}`;
    }
    const ticketData = {
      ticket: common.formatTicketData(payload.ticket),
      metadata: common.getSevenMetaData(payload),
      additionalInfo: ticketPrintEnabled ? additionalInfo : null,
    };
    const options = {
      method: 'POST',
      url,
      headers,
      params: {
        requestUuid: v4uuid.generate(),
      },
      data: ticketData,
    };

    return http(options);
  },
  playTechLogin(data) {
    const loginData = {
      localeCode: data.language,
      gameId: 'LS',
      secureToken: data.tempToken,
      accountId: '',
      skinId: data.tenantId,
      playerId: data.username,
      channelType: data.clientplatform,
      _meta: common.getAdditionalMeta(data),
    };

    const { endpoint } = data;
    const path = data.paths.player.login;

    const options = {
      method: 'POST',
      url: `${endpoint}${path}`,
      headers: common.getGlobalHeaders(data),
      data: loginData,
    };

    return http(options);
  },
  getTempToken(data) {
    const { endpoint } = data;
    const path = data.paths.player.tempToken;

    const testLoginData = {
      skinId: data.tenantId,
      playerId: data.username,
      password: data.password,
      language: 'en',
      gameId: 'LS',
      _meta: common.getAdditionalMeta(data),
    };

    const options = {
      method: 'POST',
      url: `${endpoint}${path}`,
      headers: common.getGlobalHeaders(data),
      data: testLoginData,
    };

    return http(options);
  },
  getStats(data) {
    const { endpoint } = data;
    const path = data.paths.stats;

    const options = {
      method: 'GET',
      url: `${endpoint}${path}`,
      params: {
        cpvUuid: data.product.cpvUuid,
        product: data.product.productName,
      },
      headers: common.getGlobalHeaders(data),
    };

    return http(options);
  },
  getResults(data) {
    const { endpoint } = data;
    const path = data.paths.events;

    const options = {
      method: 'GET',
      url: `${endpoint}${path}`,
      params: {
        cpvUuid: data.product.cpvUuid,
        product: data.product.productName,
        count: 11,
      },
      headers: common.getGlobalHeaders(data),
    };

    return http(options);
  },
  getLastTickets(data) {
    const { endpoint } = data;
    const path = data.paths.ticket.lastTickets;

    const additionalHeaders = {
      'X-AUTH-TOKEN': data.auth.token,
      Authorization: `Bearer ${data.auth.token}`,
    };

    const options = {
      method: 'GET',
      url: `${endpoint}${path}`,
      params: {
        count: 5,
        cpvUuid: data.product.cpvUuid,
        product: data.product.productName,
        player: data.username,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      headers: { ...common.getGlobalHeaders(data), ...additionalHeaders },
    };

    return http(options);
  },
  addZ(n) {
    return n < 10 ? `0${n}` : `${n}`;
  },
  getTicketHistory(data, filters) {
    const { endpoint, platform } = data;
    const path = data.paths.ticket.history;

    const start = filters ? filters.start : new Date();
    const end = filters ? filters.end : new Date();
    const startD = this.addZ(start.getDate());
    const startM = this.addZ(start.getMonth() + 1);
    const startY = start.getFullYear();
    const startT = '00:00:00';
    const startDate = `${startY}-${startM}-${startD} ${startT}`;

    const endD = this.addZ(end.getDate());
    const endM = this.addZ(end.getMonth() + 1);
    const endY = end.getFullYear();
    const endT = '23:59:59';
    const endDate = `${endY}-${endM}-${endD} ${endT}`;

    const additionalHeaders = {
      'X-AUTH-TOKEN': data.auth.token,
      Authorization: `Bearer ${data.auth.token}`,
    };

    const options = {
      method: 'GET',
      url: `${endpoint}${path}`,
      params: {
        product: 'LuckySix',
        player: data.username,
        cpvUuid: data.product.cpvUuid,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      headers: { ...common.getGlobalHeaders(data), ...additionalHeaders },
    };

    if (platform.name === 'seven') {
      options.params.timeFrom = startDate;
      options.params.timeTo = endDate;
    } else {
      options.params.startDate = startDate;
      options.params.endDate = endDate;
    }

    return http(options);
  },
  cancelSevenTicket(payload) {
    const headers = common.getSevenHeaders(payload);
    const path = payload.appConfig.paths.ticket.cancel;
    const { dc = '' } = payload.appConfig.company;
    const url = serverEnv === 'production' ? `https://services${dc}.7platform.com`
      : 'https://services-staging.7platform.com';

    const ticketData = {
      ticket: {
        id: payload.ticket.id,
      },
      metadata: common.getSevenMetaData(payload),
      additionalInfo: {
        printTemplate: '',
        timezone: {
          offset: 1,
        },
      },
    };

    const options = {
      method: 'DELETE',
      url: `${url}${path}`,
      headers,
      params: {
        requestUuid: v4uuid.generate(),
      },
      data: ticketData,
    };

    return http(options);
  },
  cancelPlaytechTicket(data, ticket) {
    const { endpoint } = data;
    const path = data.paths.ticket.cancel;

    const options = {
      method: 'DELETE',
      url: `${endpoint}${path}`,
      data: {
        metadata: {
          player: {
            uuid: data.username,
            username: data.username,
          },
        },
        barcode: ticket.id,
      },
      headers: common.getGlobalHeaders(data),
    };

    return http(options);
  },
  ticketCheck(config, user, requestUuid) {
    const data = {
      appConfig: config,
      player: user,
    };
    const endpoint = 'https://services-staging.7platform.com';
    const path = config.paths.ticket.check;
    const { productName } = config.product;
    const headers = common.getSevenHeaders(data);

    const options = {
      method: 'GET',
      url: `${endpoint}${path}/${requestUuid}/product/${productName}/check.json`,
      headers,
    };

    return http(options);
  },
  authSevenUser(config, token, id, authStrategy = 'token') {
    const { companyUuid, dc = '' } = config.company;
    const headers = {
      Authorization: `Bearer ${token}`,
      'HTTP-X-SEVEN-CLUB-UUID': companyUuid,
      'HTTP-X-NAB-DP': upperFirst(config.clientplatform) || 'Web',
      'SEVEN-LOCALE': config.language,
    };
    const path = config.paths.auth;
    const url = serverEnv === 'production' ? `https://services${dc}.7platform.com`
      : 'https://services-staging.7platform.com';

    const authData = {
      id,
      token,
      authStrategy,
    };

    const options = {
      method: 'POST',
      url: `${url}${path}.json`,
      headers,
      data: authData,
    };

    return http(options);
  },
  sevenLoginCheck(config, currentToken) {
    const { companyUuid, dc = '' } = config.company;
    const url = serverEnv === 'production' ? `https://services${dc}.7platform.com`
      : 'https://services-staging.7platform.com';
    const headers = {
      Authorization: `Bearer ${currentToken}`,
      'HTTP-X-SEVEN-CLUB-UUID': companyUuid,
      'HTTP-X-NAB-DP': upperFirst(config.clientplatform) || 'Web',
      'SEVEN-LOCALE': config.language,
    };

    const options = {
      method: 'GET',
      url: `${url}/jwt/login_check`,
      headers,
    };

    return http(options);
  },
  getSevenBalance(config, token) {
    const { companyUuid, dc = '' } = config.company;
    const headers = {
      Authorization: `Bearer ${token}`,
      'HTTP-X-SEVEN-CLUB-UUID': companyUuid,
      'X-NSFT-WALLET-USERGROUP': companyUuid,
      'HTTP-X-NAB-DP': config.clientPlatform,
      'SEVEN-LOCALE': config.language,
    };
    const url = serverEnv === 'production' ? `https://services${dc}.7platform.com`
      : 'https://services-staging.7platform.com';
    const path = config.paths.b2bBalance;

    const options = {
      method: 'GET',
      url: `${url}${path}`,
      headers,
    };

    return http(options);
  },
};

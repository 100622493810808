var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.notifications.length
    ? _c(
        "div",
        { staticClass: "notification-wrapper", class: { wide: true } },
        _vm._l(_vm.notifications, function (item, key) {
          return _c(
            "div",
            { key: key, staticClass: "item", class: item.status },
            [
              _c("div", { staticClass: "item-icon" }, [
                _c("i", { staticClass: "icon", class: "icon-" + item.icon }),
              ]),
              _c(
                "div",
                {
                  staticClass: "item-title",
                  attrs: { title: "" + item.message },
                },
                [_vm._v(" " + _vm._s(_vm._f("translate")(item.message)) + " ")]
              ),
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
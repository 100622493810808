<template>
  <div class="new-features-wrapper"
       v-if="showNewFeatures && newFeatures">
    <Modal>
      <div slot="header">
        <LocalizedLabel translation="newFeatures" />
      </div>
      <div slot="body">
        <div class="feature-list"
             v-for="feature in newFeatures"
             :key="feature.id">
          <img :src="require(`../../../assets/${feature.icon}.png`)" alt="">
          <div class="feature-text">
            <span class="title">
              <LocalizedLabel :translation="feature.title" />
            </span>
            <span class="description">
              <LocalizedLabel :translation="feature.description" />
            </span>
          </div>
        </div>
      </div>
      <div slot="footer">
        <button @click="toggleNewFeatures">
          <LocalizedLabel translation="play" />
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Modal from './Modal';

export default {
  name: 'NewFeatures',
  components: {
    Modal,
  },
  computed: {
    ...mapGetters([
      'appConfig',
      'showNewFeatures',
    ]),
    newFeatures() {
      return this.appConfig.product.newFeatures;
    },
  },
  methods: {
    ...mapActions([
      'toggleNewFeatures',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.new-features-wrapper {
  ::v-deep .modal-mask {
    .modal-wrapper {
      width: 550px;
      .modal-container {
        padding: 40px 75px;
        .modal-header, .modal-body, .modal-footer {
          float: left;
          width: 100%;
        }
        .modal-header {
          margin-bottom: 40px;
          height: auto;
          padding: 0;
          div {
            color: var(--text-primary-1);
            text-align: left;
            font-size: 48px;
            font-weight: 300;
            font-family: Roboto, sans-serif;
          }
        }
        .modal-body {
          margin-bottom: 20px;
          .feature-list {
            display: flex;
            margin-bottom: 40px;
            img {
              width: 64px;
              height: 64px;
              margin-right: 32px;
            }
            .feature-text {
              span {
                float: left;
                width: 100%;
              }
              .title {
                font-size: 28px;
                color: var(--text-primary-1);
              }
              .description {
                font-size: 16px;
                color: var(--text-primary-3);
                line-height: 1.3;
              }
            }
          }
        }
        .modal-footer {
          button {
            float: left;
            width: 100%;
            height: 60px;
            background-color: var(--add-bet-button-bg, var(--brand-bg));
            color: var(--text-primary-1);
            font-weight: 700;
            border-radius: 2px;
            font-size: 20px;
            text-transform: none;
            transition: all .5s ease;

            &:hover {
              background-color: var(--add-bet-button-hover-bg);
            }

            &:active {
              background-color: var(--add-bet-button-pressed-bg);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .new-features-wrapper {
    ::v-deep .modal-mask {
      .modal-wrapper {
        width: 100%;
        .modal-container {
          padding: 30px;
          .modal-header {
            margin-bottom: 20px;
            height: auto;
            padding: 0;
            div {
              font-size: 36px;
            }
          }
          .modal-body {
            margin-bottom: 10px;
            background-color: var(--card-bg);
            height: auto;
            .feature-list {
              margin-bottom: 20px;
              .feature-text {
                span {
                  line-height: 1.3;
                }
                .title {
                  font-size: 24px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <component :is="appLayout"/>
</template>

<script>
import { mapGetters } from 'vuex';
import Expanded from '@/layouts/Expanded';
import Compact from '@/layouts/Compact';

export default {
  name: 'Home',
  components: {
    Expanded,
    Compact,
  },
  computed: {
    ...mapGetters([
      'appLayout',
    ]),
  },
};
</script>

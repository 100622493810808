import Vue from 'vue';
import VueMq from 'vue-mq';
import '@nsoft/games-sdk-js/src/utility/filters';
import gateway from '@/utility/gravityGateway';
import webViewIntegration from '@/utility/webViewIntegration';
import LocalizedLabel from '@/components/web/common/LocalizedLabel';
import App from './App';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(VueMq, {
  breakpoints: {
    s: 360,
    m: 640,
    smallMin: 660,
    l: 720,
    xl: 770,
    smallMax: 960,
    mediumMax: 1100,
    largeMin: 1268,
    large: 1483,
    largeMax: 1920,
  },
});
Vue.use(LocalizedLabel);

const qp = new URLSearchParams(window.location.search);
const isGateway = qp.get('integrationType');

new Vue({
  router,
  store,
  beforeCreate() {
    if (isGateway) {
      gateway.init(store);
    }
    if (webViewIntegration.isActive()) {
      webViewIntegration.init();
    }
  },
  render: (h) => h(App),
}).$mount('#app');

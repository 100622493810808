import filter from 'lodash/filter';
import store from '@/store';
import api from '../api/index';

export default class GameStrategy {
  loadGame() {
    store.dispatch('updateAppConfig', this.config);
  }

  missingParams(passedParams) {
    const queryParams = Object.keys(passedParams);
    return this.mandatoryParams.filter((param) => !queryParams.includes(param)).length;
  }

  filterSpecialBets() {
    const { bets } = this.config.product;
    return filter(bets, (bet) => bet.id >= 5);
  }

  setSpecialsVisibility() {
    const specialBets = this.filterSpecialBets();
    store.dispatch('setSpecialsBetVisibility', specialBets);
  }

  async setStats() {
    const stats = await api.getStats(this.config);
    store.dispatch('setStats', stats.data);
  }

  async setResults() {
    const results = await api.getResults(this.config);
    store.dispatch('setResults', results.data);
  }

  setDrawDetails() {
    this.setStats();
    this.setResults();
  }
}

<template>
  <button class="button">
    {{label}}
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    label: {
      type: String,
      default: 'Button',
      required: false,
    },
  },
};
</script>

<template>
  <div class="dropdown"
       :class="{expanded: expanded}"
       v-click-outside="closeDropdown">
    <label @click="getCurrentValue">
      {{ label | translate }} {{ item }}
    </label>
    <i class="icon"
       @click="expandDropdown"
       :class="dropdownIcon">
    </i>
    <div class="dropdown-list"
         v-if="expanded">
      <div class="dropdown-item"
           @click="setSelection(option)"
           v-for="(option, key) in options"
           :key="key">
        {{ option.value }}
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import isMobile from 'ismobilejs';
import eventBus from '@/utility/eventBus';

export default {
  name: 'DropDown',
  directives: {
    ClickOutside,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      expanded: false,
      item: null,
    };
  },
  computed: {
    dropdownIcon() {
      return this.expanded ? 'icon-arrow-up-a' : 'icon-arrow-down-a';
    },
  },
  methods: {
    expandDropdown() {
      if (isMobile().any) {
        this.$emit('modalOpen');
        return;
      }
      this.expanded = !this.expanded;
    },
    closeDropdown() {
      this.expanded = false;
    },
    getCurrentValue() {
      if (this.item) this.$emit('getCurrentValue', this.item);
    },
    setSelection(pick) {
      this.item = pick.value || pick;
      this.closeDropdown();
      this.$emit('setSelectionOption', this.item);
    },
  },
  created() {
    this.item = this.options[0].value;
    eventBus.$on('selectPick', (value) => {
      this.setSelection(value);
    });
  },
};
</script>

<style lang="scss">
  .dropdown {
    border-radius: 2px;
    background-color: var(--dropdown-bg, var(--button-bg));
    color: var(--text-primary-1);
    font-size: 14px;
    padding: 0 15px;
    position: relative;
    margin-right: 8px;
    transition: all .5s ease;

    &:hover {
      background-color: var(--dropdown-hover-bg, var(--button-hover-bg));
    }

    &:active {
      background-color: var(--dropdown-pressed-bg, var(--button-pressed-bg));
    }

    &.disabled {
      background-color: var(--dropdown-pressed-bg, var(--button-pressed-bg));
      color: var(--text-primary-3);
    }

    &.expanded {
      &:hover {
        background-color: var(--dropdown-bg, var(--button-bg));
      }
    }

    label {
      float: left;
      line-height: 40px;
      width: calc(100% - 25px);
      cursor: pointer;
    }

    .icon {
      position: absolute;
      right: 0;
      line-height: 40px;
      width: 40px;
      text-align: center;
      cursor: pointer;
      font-size: 18px;
      border-left: 2px solid var(--card-bg);
    }

    .dropdown-list {
      position: absolute;
      top: 42px;
      width: 100%;
      background-color: var(--button-bg);
      left: 0;
      z-index: 1;

      .dropdown-item {
        float: left;
        width: 100%;
        height: 40px;
        cursor: pointer;
        line-height: 40px;
        padding: 0 16px;
        transition: all .5s ease;
        text-align: left;

        &:hover {
          background-color: var(--button-hover-bg);
        }
      }
    }
  }
</style>

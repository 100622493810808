var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "draw-details-wrapper" }, [
    _c(
      "div",
      { staticClass: "tabs" },
      _vm._l(_vm.drawDetailsTabs, function (tab, key) {
        return _c(
          "div",
          {
            key: key,
            staticClass: "tab",
            class: [
              { active: key === _vm.selectedTab },
              { disabled: tab.disabled },
            ],
            attrs: { disabled: tab.disabled },
            on: {
              click: function ($event) {
                return _vm.selectTab(tab, key)
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm._f("translate")(tab.name)) + " ")]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "draw-details-body" },
      [_c(_vm.activeComponent, { tag: "component" })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="modal-mask"
       @click="closeModal">
    <div class="modal-wrapper">
      <div class="modal-container"
           @click="$event.stopPropagation()">
        <div class="modal-header">
          <slot name="header">
            default header
          </slot>
        </div>
        <div class="modal-body">
          <slot name="body">
            default body
          </slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <button class="modal-default-button"
                    @click="$emit('close')">
              Close
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'Modal',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    ClickOutside,
  },
  methods: {
    closeModal() {
      if (this.open) this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: table;
    transition: opacity 0.3s ease;

    .modal-wrapper {
      position: absolute;
      top: 5%;
      left: 50%;
      transform: translate(-50%, 10%);
      width: 100%;
    }

    .modal-container {
      max-width: 896px;
      margin: 0 auto;
      padding: 24px;
      background-color: var(--card-bg);
      border-radius: 2px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      overflow: auto;

      .modal-header {
        float: left;
        width: 100%;
        height: 40px;
        padding: 8px 16px;

        h3 {
          width: 95%;
          margin-top: 0;
          color: var(--text-primary-1);
          text-align: center;
          font-weight: 400;
          font-size: 24px;
        }
      }

      .modal-footer {
        button {
          background: transparent;
          border: 0;
          outline: 0;
          font-size: 14px;
          color: var(--accent-bg, var(--brand-bg));
          text-transform: uppercase;
          cursor: pointer;
          float: right;
        }
      }
    }
  }

  .ticket-details {
    .modal-wrapper {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 30%);
      width: 100%;
    }
    .modal-container {
      background-color: var(--card-bg);

      .modal-header {
        display: none;
      }
    }
  }

  .ticket-history-modal,
  .game-help {
    .modal-container {
      background-color: var(--bg-color-2);
      .modal-header {
        display: none;
      }
    }
  }

  .game-help {
    .modal-mask {
      .modal-wrapper {
        transform: translate(-50%, 5%);
        .modal-container {
          padding: 0;
        }
      }
    }
  }

  .modal-event-details {
    position: relative;
  }

  .cancel-ticket {
    .modal-wrapper {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 30%);
      width: 100%;

      .modal-container {
        background-color: var(--card-bg);

        .modal-header {
          height: 60px;
          h3 {
            text-align: left;
            line-height: 45px;
          }
        }

        .modal-body {
          height: calc(100vh - 133px);
          padding: 8px 16px;
          float: left;
          width: 100%;
          color: var(--text-primary-1);
        }

        .modal-footer {
          float: left;
          width: 100%;
          padding: 0 16px;
          height: 73px;
          border-top: 2px solid var(--tab-border-color);

          .footer {
            height: 100%;
          }

          button {
            line-height: 73px;
            margin-left: 50px;
          }
        }
      }
    }
  }

  .modal-default-button {
    float: right;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  @media (max-width: 660px) {
    .modal-mask {
      .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
      }
      .modal-container {
        height: 100vh;
        padding: 0;

        .modal-body {
          height: calc(100vh - 40px);
          background-color: var(--bg-color-2);
        }
      }
    }
    .ticket-details {
      .modal-container {
        background-color: var(--card-bg);
        padding: 0;
      }
    }
    .game-help {
      .modal-mask {
        .modal-wrapper {
          transform: none;
        }
      }
    }
    .quick-pick {
      .modal-mask {
        .modal-wrapper {
          padding: 10px;
        }
        .modal-container {
          overflow: hidden;
          background-color: var(--bg-color-2);
          padding: 16px;

          .modal-header {
            h3 {
              text-align: left;
              font-size: 18px;
              font-weight: 700;
            }
          }

          .modal-body {
            float: left;
            width: 100%;
            padding: 8px 16px;
            height: 80vh;
            overflow: auto;

            .pick {
              .radio-button {
                margin-bottom: 20px;
              }
            }
          }

          .modal-footer {
            float: left;
            width: 100%;
            height: 40px;
          }
        }
      }
    }
  }

  @media (min-width: 661px) {
    .cancel-ticket {
      .modal-wrapper {
        position: relative;
        .modal-container {
          min-height: 192px;
          max-width: 344px;

          .modal-header {
            h3 {
              text-align: left;
            }
          }

          .modal-body {
            height: 100%;
            float: left;
            width: 100%;
          }

          .modal-footer {
            border: none;
            float: left;
            width: 100%;
            margin-top: 50px;
          }
        }
      }
    }
  }
</style>

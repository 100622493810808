<template>
  <div class="layout-compact"
       v-if="isGameLoaded">
    <div class="main-area">
      <div class="header">
        <GamesHeader :player="player"></GamesHeader>
      </div>
      <div class="visualization" :style="mobileVisualization">
        <Visualization />
      </div>
      <div class="betting-part" :style="modalOpenedStyle">
        <Betting></Betting>
        <stake-fade-transition>
          <StakeBox v-if="showStakeBox"
                @close="toggleStakeBox"
                :showPayment="isMob"
                :predefinedStakes="predefinedStakeValues"
                :showActions="isMob">
          </StakeBox>
        </stake-fade-transition>
        <!-- Mobile version of betslip -->
        <div class="betslip-component"
             v-show="showBetslip">
          <div class="betslip-header">
            <div class="header-item"
              v-for="(tab, key) in ticketWrapperTabs"
              :key="key"
              @click="selectTicketTab(key)"
                 :disabled="tab.disabled"
              :class="{active: key === selectedTicketTab, disabled: tab.disabled}">
                <span>
                  {{ tab.name | translate }}
                  <BetsCounter v-if="tab.indicator"></BetsCounter>
                </span>
            </div>
          </div>
          <div class="betslip-content">
            <ExpandedBetslip
              v-if="selectedTicketTab === 0 && !showStakeBox"
              :bettingLocked="bettingLocked"/>
            <LastTickets
              v-if="selectedTicketTab === 1 && !showStakeBox"
              :tickets="lastTicketsData"
              :isMob="isMob"/>
          </div>
        </div>
      </div>
      <div class="info-bar"></div>
      <div class="ticket-area" v-if="!showModalStats">
        <TicketWrapper></TicketWrapper>
      </div>
      <div class="modal-stats" v-if="showModalStats">
        <div class="content">
          <div class="header">
            <div class="info">
              <div class="product-info">Lucky Six</div>
              <div class="round-info">{{'general_round' | translate}} {{ round - 1 }}</div>
            </div>
            <i class="icon icon-close-a" @click="toggleStats()"></i>
          </div>
          <div class="body">
            <div class="tabs">
              <div class="tab"
                   :class="[{'active': tab.name === selectedTab.name}, {'disabled': tab.disabled}]"
                   v-for="(tab, key) in eventDetailsTabs"
                   @click="selectTab(tab)"
                   :disabled="tab.disabled"
                   :key="key">
                {{tab.name | translate}}
              </div>
            </div>
            <div class="tab-content">
              <component :is="selectedTab.component"></component>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ticket-history-modal">
        <Modal v-if="showTicketHistory && player.loggedIn"
              :open="showTicketHistory"
              @close="showTicketHistory = false">
          <h3 slot="header"></h3>
          <TicketsHistory slot="body" @close="showTicketHistory = false"></TicketsHistory>
          <div slot="footer"></div>
        </Modal>
      </div>
    <div class="ticket-details">
      <Modal v-if="showTicketDetails && player.loggedIn"
             :open="showTicketDetails"
             class="ticket-details"
             @close="showTicketDetails = false">
        <h3 slot="header"></h3>
        <TicketDetails slot="body"
                     :ticket="ticket"
                     @close="showTicketDetails = false">
        </TicketDetails>
        <div slot="footer"></div>
      </Modal>
    </div>
    <div class="cancel-ticket">
      <Modal v-if="showCancelTicket && player.loggedIn"
            :open="showCancelTicket"
            class="cancel-ticket"
            @close="showCancelTicket = false">
        <h3 slot="header">
          {{'general_cancel_ticket' | translate}}
        </h3>
        <div slot="body">
          {{'general_cancel_ticket_confirm' | translate}} {{ticket.id}}
        </div>
        <div class="footer" slot="footer">
          <button @click="showCancelTicket = false">
            {{'general_close' | translate}}
          </button>
          <button @click="approveTicketCancel">
            {{'general_accept' | translate}}
          </button>
        </div>
      </Modal>
    </div>
    <div class="quick-pick">
      <Modal v-if="showQuickPick"
            :open="showQuickPick"
            @close="showQuickPick = false">
        <h3 slot="header">{{ 'general_random' | translate }}</h3>
        <div slot="body">
          <div class="pick">
            <Radio v-for="(pick, index) in pickNumbers"
                   :key="index"
                   @change="setRandomNumbers"
                   :id="pick.value"
                   :label="pick.label"
                   :subLabel="pick.subLabel">
            </Radio>
          </div>
        </div>
        <div class="footer" slot="footer">
          <button @click="showQuickPick = false">
            {{'general_close' | translate}}
          </button>
        </div>
      </Modal>
    </div>
    <div class="quick-pick">
        <Modal v-if="showFutureBets && isMob"
              :open="showFutureBets"
              @close="showFutureBets = false">
          <h3 slot="header">{{ 'general_future_bet' | translate }}</h3>
          <div slot="body">
            <div class="pick">
              <Radio v-for="(item, index) in maxFutureBets"
                     @change="setFutureBets"
                     :key="index"
                     :id="item">
              </Radio>
            </div>
          </div>
          <div class="footer" slot="footer">
            <button @click="showFutureBets = false">
              {{'general_close' | translate}}
            </button>
          </div>
        </Modal>
    </div>
    <FullScreen v-if="player.id"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GamesHeader from '@/components/web/header/GameHeader';
import Visualization from '@/components/web/visualization/VisualizationWrapper';
import Betting from '@/components/web/betting/CompactBettingBody';
import Betslip from '@nsoft/games-sdk-js/src/components/Betslip/Betslip';
import Statistics from '@/components/web/drawDetails/Statistics';
import Results from '@/components/web/drawDetails/Results';
import TicketWrapper from '@/components/web/ticket/TicketWrapper';
import TicketsHistory from '@/components/web/ticketsHistory/TicketsHistoryWrapper';
import TicketDetails from '@/components/web/ticketDetails/TicketDetailsWrapper';
import ExpandedBetslip from '@nsoft/games-sdk-js/src/components/Betslip/Expanded';
import LastTickets from '@nsoft/games-sdk-js/src/components/LastTickets/LastTicketsWrapper';
import BetsCounter from '@/components/web/common/BetsCounter';
import StakeBox from '@nsoft/games-sdk-js/src/components/Betslip/StakeBox';
import StakeFadeTransition from '@nsoft/games-sdk-js/src/components/Betslip/StakeFadeTrasition';
import eventBus from '@/utility/eventBus';
import isMobile from 'ismobilejs';
import Modal from '@/components/web/common/Modal';
import webViewIntegration from '@/utility/webViewIntegration';
import integrator from '@/utility/integrator';
import { audio } from '@nsoft/games-sdk-js/src/utility/index';
import gravity from '@/utility/gravityGateway';
import { has, each } from 'lodash';
import Radio from '@/components/web/common/Radio';
import FullScreen from '@/components/web/common/FullScreen';

export default {
  name: 'CompactLayout',
  components: {
    GamesHeader,
    Visualization,
    Betting,
    Betslip,
    Statistics,
    Results,
    TicketWrapper,
    ExpandedBetslip,
    LastTickets,
    BetsCounter,
    StakeBox,
    StakeFadeTransition,
    TicketsHistory,
    TicketDetails,
    Modal,
    Radio,
    FullScreen,
  },
  data() {
    return {
      maxFutureBets: 0,
      showFutureBets: false,
      showStakeBox: false,
      showTicketHistory: false,
      ticket: {},
      ticketDetails: {},
      showTicketDetails: false,
      showCancelTicket: false,
      selectedTicketTab: 0,
      ticketWrapperTabs: [
        {
          name: 'general_betslip',
          indicator: true,
        },
        {
          name: 'general_active_tickets',
        },
      ],
      eventDetailsTabs: [
        {
          id: 0,
          name: 'general_statistics',
          component: 'Statistics',
        },
        {
          id: 1,
          name: 'general_results',
          component: 'Results',
        },
        {
          id: 2,
          name: 'general_help',
          component: 'GameHelp',
          disabled: true,
        },
      ],
      selectedTab: {
        id: 0,
        name: 'general_statistics',
        component: 'Statistics',
      },
      showQuickPick: false,
    };
  },
  computed: {
    ...mapGetters([
      'isGameLoaded',
      'player',
      'appConfig',
      'showModalStats',
      'round',
      'showBetslip',
      'bettingLocked',
      'isAudioOn',
      'lastTicketsData',
      'pickNumbers',
      'predefinedStakeValues',
    ]),
    mobileVisualization() {
      return this.isMob ? 'padding-top: 75%' : 'padding-top: 56.25%';
    },
    isMob() {
      return isMobile().any;
    },
    modalOpenedStyle() {
      return this.showModalStats || !this.isMob ? 'padding-bottom: 0' : 'padding-bottom: 90px';
    },
  },
  methods: {
    ...mapActions([
      'toggleStats',
      'cancelTicket',
    ]),
    setRandomNumbers(pick) {
      eventBus.$emit('selectPick', pick);
      this.showQuickPick = false;
    },
    toggleStakeBox() {
      this.showStakeBox = false;
    },
    getBetslipConfig() {
      return this.appConfig.company.betslip;
    },
    selectTab(tab) {
      if (!tab.disabled) {
        this.selectedTab = tab;
      }
    },
    selectTicketTab(key) {
      if (!this.player.id || this.bettingLocked) return;
      this.selectedTicketTab = key;
    },
    openTicketDetails(ticket) {
      this.ticket = ticket;
      this.showTicketDetails = true;
    },
    setFutureBets(futureBets) {
      this.showFutureBets = false;
      eventBus.$emit('setFutureBets', futureBets);
    },
    approveTicketCancel() {
      this.cancelTicket(this.ticket);
      this.showCancelTicket = false;
      this.showTicketDetails = false;
    },
  },
  created() {
    if (!this.player.id) {
      this.ticketWrapperTabs[1].disabled = true;
    }
    eventBus.$on('cancelTicket', (ticket) => {
      this.ticket = ticket;
      this.showCancelTicket = true;
    });
    eventBus.$on('openFutureBets', (maxFutureBets) => {
      this.showFutureBets = true;
      this.maxFutureBets = maxFutureBets;
    });
    eventBus.$on('openStakeBox', () => {
      this.showStakeBox = !this.showStakeBox;
    });
    eventBus.$on('modalQuickPick', () => {
      this.showQuickPick = true;
    });
    eventBus.$on('openTicketHistory', () => {
      if (this.player.loggedIn) {
        this.showTicketHistory = true;
      }
    });
    eventBus.$on('ticketDetails', (ticket) => {
      this.openTicketDetails(ticket);
    });
    eventBus.$on('loginRequired', () => {
      if (this.isAudioOn) {
        audio.playSound('click');
      }
      const { gravityEnabled } = this.appConfig.company;
      if (gravityEnabled) {
        if (webViewIntegration.isActive() && has(window, 'WebAppListener')) {
          /* eslint-disable */
          WebAppListener.sendMessage('LoginActivity.SignIn');
        } else {
          gravity.sendMessage('User.LoginRequired');
        }
      } else {
        integrator.sendMessage({
          type: 'loginRequired',
        });
      }
    });
    eventBus.$on('ticketUpdate', () => {
      eventBus.$emit('clearBetslip');
    });
    eventBus.$on('roundResults', (eventId) => {
      this.roundId = eventId;
    });
    eventBus.$on('rebet', () => {
      this.selectTicketTab(0);
      this.showCancelTicket = false;
      this.showTicketDetails = false;
      this.showTicketHistory = false;
    });
  },
  watch: {
    'player.id'(value) {
      this.ticketWrapperTabs[1].disabled = !value;
    },
    lastTicketsData(newValue) {
      if (newValue && this.roundId) {
        each(newValue, (ticket) => {
          if (ticket.status.value === 'PAYEDOUT' || ticket.status.value === 'WON') {
            each(ticket.bets, (bet) => {
              if (bet.eventId === this.roundId && this.isAudioOn) {
                audio.playSound('win');
                this.roundId = null;
              }
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
  .layout-compact {
    width: 100%;
    float: left;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: transparent;
    font-family: Roboto, 'sans-serif';

    .main-area {
      max-width: 848px;
      background-color: var(--bg-color-2);
      padding: 8px;
      position: relative;
      max-height: 100vh;
      overflow: auto;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0;
        height: 0;
      }

      .visualization {
        margin-bottom: 8px;
        float: left;
        width: 100%;
        max-height: 470px;
        position: relative;
      }

      .betting-part {
        float: left;
        width: 100%;
        position: relative;

        .betslip-component {
          display: none;
        }

        .stake-box {
          width: 45%;
          bottom: -35px;
        }
      }

      .ticket-area {
        float: left;
        width: 100%;

        .ticket-wrapper {
          width: 100%;
        }
      }

      .modal-stats {
        position: absolute;
        overflow: auto;
        top: 33px;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--event-details-bg, var(--card-bg));;
        z-index: 100;

        .content {
          .header {
            float: left;
            width: 100%;
            min-height: 100px;
            background: #3f4144;
            position: relative;
            color: #efefef;

            .info, .icon {
              position: absolute;
            }

            .info {
              text-align: center;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              div {
                width: 100%;
                margin-top: 7px;
              }

              .product-info {
                font-size: 22px;
                font-weight: 500;
              }

              .round-info {
                font-size: 14px;
                font-weight: 700;
              }
            }

            .icon {
              top: 16px;
              right: 16px;
              font-size: 24px;
              cursor: pointer;
            }
          }

          .body {
            float: left;
            width: 100%;
            background-color: transparent;

            .tabs {
              display: flex;
              justify-content: space-between;
              height: 41px;
              align-items: center;

              .tab {
                text-align: center;
                flex-grow: 1;
                color: #efefef;
                font-size: 14px;
                height: 100%;
                line-height: 41px;
                border-bottom: 2px solid #36383A;
                cursor: pointer;

                &.active {
                  border-bottom: 2px solid #ffc107;
                }

                &.disabled, &:disabled {
                  opacity: .3;
                }
              }
            }

            .tab-content {
              float: left;
              width: 100%;
              padding: 8px;

              .stats-wrapper {
                float: left;
                width: 100%;

                .number-bet-stats {
                  display: flex;
                  justify-content: space-evenly;
                }

                .color-bet-stats {
                  .bet-stats {
                    .colors {
                      justify-content: center;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .ticket-history-modal {
      .header {
        position: relative;

        .icon {
          cursor: pointer;
          font-size: 18px;
        }
      }

      .modal-container {
        height: 100%;
      }
    }
  }

  @media (min-width: 306px) and (max-width: 659px) {
    .layout-compact {
      .main-area {
        padding: 0;
        &.modal {
          height: 100vh;
          padding-bottom: 0;
        }
        .visualization {
          margin-bottom: 0;
        }
        .betting-part {
          position: relative;
          padding-bottom: 90px;
          .betslip-component {
            height: 100%;
            bottom: 0;

            display: block;
            position: absolute;
            width: 100%;
            z-index: 1;
            background-color: var(--betslip-bg, var(--bg-color-2));

            .betslip-header {
              float: left;
              width: 100%;
              height: 40px;

              .header-item {
                font-size: 14px;
                position: relative;
                float: left;
                width: 50%;
                height: 100%;
                padding: 10px;
                text-align: center;
                cursor: pointer;
                background-color: var(--card-bg);
                color: var(--text-primary-1);
                border-bottom: 2px solid var(--tab-border-color);

                &.active {
                  border-bottom: 2px solid var(--accent-bg, var(--brand-bg));
                  color: var(--accent-bg, var(--brand-bg));
                }

                &.disabled, &:disabled {
                  background-color: var(--tab-pressed-bg);
                  color: var(--text-primary-3);
                }

                span {
                  position: relative;

                  .bets-counter {
                    position: absolute;
                    right: -25px;
                    top: 0;
                  }
                }
              }
            }

            .expanded-wrapper {
              width: 100%;
              height: 375px;
              overflow: auto;
              padding-bottom: 20px;
            }
          }

          .stake-box {
            width: 100%;
            bottom: 0;
            left: 0;
            height: 100%;
            z-index: 100;
          }
        }

        .ticket-area {
          position: fixed;
          bottom: 0;
          width: auto;
          z-index: 10;

          .ticket-wrapper {
            width: 100%;
            bottom: 0;
            z-index: 2;
          }
        }
      }
    }
  }

  @media (min-width: 320px) {
    .layout-compact {
      .main-area {
        width: 100%;
      }
    }
  }

  @media (min-width: 660px) {
    .layout-compact {
      .main-area {
        .modal-stats {
          top: 40px;
        }
      }
    }
  }

  @media (min-width: 848px) {
    .layout-compact {
      padding-top: 8px;
      .main-area {
        width: 848px;

        .ticket-area {
          .ticket-wrapper {
            .footer {
              .betslip-footer-wrapper {
                display: flex;
                flex-wrap: wrap;
                .total-stake, .important {
                  display: none;
                }
                .future-bets {
                  flex-basis: 80%;
                  order: 1;
                  .footer-dropdown {
                    .dropdown-list {
                      top: -201px;
                      height: 200px;
                    }
                  }
                }
                .betslip-tax-info-wrapper {
                  flex-basis: 100%;
                  order: 3;
                }
                .payin-toolbar {
                  flex-basis: 20%;
                  order: 2;
                  float: right;
                  .empty-betslip {
                    float: right;
                    width: 90%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 961px) {
    .layout-compact {
      .main-area {
        .ticket-area {
          .ticket-wrapper {
            .betslip-body-wrapper {
              min-height: 271px;
              height: 271px;
            }

            .stake-input {
              position: relative;
              .input {
                position: absolute;
                left: 0;
              }
            }
            .last-tickets-body {
              height: 300px;

              .item {
                .body {
                  .bet-item {
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>

<template>
  <div class="bets-counter" v-if="ticket.bets.length">
    <span>{{betsCount}}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BetsCounter',
  computed: {
    ...mapGetters([
      'ticket',
    ]),
    betsCount() {
      return this.ticket.bets.length;
    },
  },
};
</script>

<style lang="scss" scoped>
  .bets-counter {
    position: absolute;
    width: 17px;
    height: 18px;
    background-color: var(--accent-bg);
    border-radius: 2px;

    span {
      float: left;
      width: 100%;
      height: 100%;
      line-height: 17px;
      color: var(--accent-text);
      font-size: 10px;
      font-weight: bold;
    }
  }
</style>

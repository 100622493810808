var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.balls, function (ball, index) {
      return _c(
        "button",
        {
          key: index,
          staticClass: "ball",
          class: [{ active: ball.active }, "color-border-" + ball.colorId],
          on: {
            mousedown: function ($event) {
              return _vm.handler(ball)
            },
          },
        },
        [_vm._v(" " + _vm._s(ball.number) + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "betting-body" },
    [
      _c("BetInfoBar"),
      _c("AddRemoveBet"),
      _c("mq-layout", { attrs: { mq: ["largeMin", "largeMax"] } }, [
        _c("div", { staticClass: "game-bets" }, [
          _c("div", { staticClass: "main-bets" }, [_c("selection-grid")], 1),
          _c(
            "div",
            { staticClass: "special-bets" },
            [_c("ShortcutBets"), _c("special-bets")],
            1
          ),
        ]),
      ]),
      _c(
        "mq-layout",
        {
          attrs: {
            mq: [
              "s",
              "m",
              "l",
              "xl",
              "smallMin",
              "smallMax",
              "mediumMax",
              "large",
            ],
          },
        },
        [
          _c(
            "div",
            { staticClass: "tabs" },
            _vm._l(_vm.tabs, function (tab, key) {
              return _c(
                "div",
                {
                  key: key,
                  staticClass: "tab",
                  class: [
                    { active: tab.id === _vm.selectedTab },
                    { visible: tab.visible },
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.selectTab(tab)
                    },
                  },
                },
                [
                  tab.visible
                    ? _c("span", [
                        _vm._v(_vm._s(_vm._f("translate")(tab.name))),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
          _c("div", { staticClass: "game-bets" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.selectedTab === 0,
                    expression: "selectedTab === 0",
                  },
                ],
                staticClass: "tab-content",
              },
              [
                _c(
                  "div",
                  { staticClass: "main-bets" },
                  [_c("selection-grid")],
                  1
                ),
                _c("ShortcutBets"),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.selectedTab === 1,
                    expression: "selectedTab === 1",
                  },
                ],
                staticClass: "tab-content",
              },
              [
                _c(
                  "div",
                  { staticClass: "special-bets" },
                  [_c("special-bets")],
                  1
                ),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="tickets-history-wrapper"
       v-if="player.loggedIn">
    <CircleLoader :loader="ticketHistoryInProgress"></CircleLoader>
    <div class="header">
      <div class="product-title">
        <span>{{'Lucky Six'}}</span>
        <i class="icon icon-close-a" @click="$emit('close')"></i>
      </div>
      <div class="date-picker">
          <span class="label">
            {{'general_date_range' | translate}}
          </span>
        <div class="date-pick">
          <i class="icon icon-calendar"></i>
          <date-picker :mode="mode"
                       v-model="date"
                       :popover="popover"
                       :masks="masks"
                       :locale="appConfig.language"
                       @input="applyFilters"
                       class="date-picker-field">
            <input id="date"
                   readonly
                   slot-scope="{ inputProps, inputEvents }"
                   v-bind="inputProps"
                   v-on="inputEvents" />
          </date-picker>
        </div>
      </div>
    </div>
    <Scrollable>
      <div class="body">
        <div class="bets-table">
          <div class="rows mobile">
            <div class="row"
                 v-for="(ticket, index) in ticketHistoryData"
                 :key="index"
                 @click="openTicketDetails(ticket)">
              <div class="bet-item">
                <div class="tickets-history-status"
                     :class="`status-${ticket.status.value.toLowerCase()}`">
                  {{ticket.status.name.toUpperCase()}}
                </div>
                <div class="tickets-history-payment-info">
                  <div class="info">
                  <span class="label">
                    {{'general_stake' | translate}}{{': '}}
                  </span>
                    <span class="value">
                    {{ticket.payin.toFixed(2)}}
                  </span>
                  </div>
                  <div class="info"
                       v-if="ticket.payinTax">
                  <span class="label">
                    {{'general_payin_tax' | translate}}{{': '}}
                  </span>
                    <span class="value">
                    {{ticket.payinTax.toFixed(2)}}
                  </span>
                  </div>
                  <div class="info">
                  <span class="label">
                    {{'general_payout' | translate}}{{': '}}
                  </span>
                    <span class="value">
                    {{ticket.payout.toFixed(2)}}
                  </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Scrollable>
    <div class="footer">
      <div class="notification-area"
         v-if="ticketHistoryRangeError.message"
         :class="{wide: true}">
      <div class="item"
           :class="ticketHistoryRangeError.status.toLowerCase()">
        <div class="item-icon">
          <i class="icon" :class="`icon-${ticketHistoryRangeError.icon}`"></i>
        </div>
        <div class="item-title"
             :title="`${ticketHistoryRangeError.message}`">
          {{ticketHistoryRangeError.message | translate}}
        </div>
      </div>
    </div>
      <div class="info"
      v-if="noDataMessage && !showNotification
      && !ticketHistoryRangeError.message">
        <i class="icon icon-info"></i>
        <span>{{'general_warning_no_data' | translate}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import eventBus from '@/utility/eventBus';
import CircleLoader from '@nsoft/games-sdk-js/src/components/CircleLoader';
import Scrollable from '@nsoft/games-sdk-js/src/components/Scrollable';

export default {
  name: 'TicketHistoryWrapper',
  components: {
    DatePicker,
    CircleLoader,
    Scrollable,
  },
  data() {
    return {
      mode: 'range',
      date: {
        start: new Date(),
        end: new Date(),
      },
      popover: {
        visibility: 'click',
      },
      masks: {
        input: 'DD-MM-YYYY',
      },
    };
  },
  computed: {
    ...mapGetters([
      'ticketHistoryData',
      'notifications',
      'player',
      'ticketHistoryInProgress',
      'ticketHistoryRangeError',
      'appConfig',
    ]),
    showNotification() {
      return !!(this.notifications.length && this.notifications[0].httpCode);
    },
    noDataMessage() {
      return !this.ticketHistoryData.length;
    },
  },
  methods: {
    ...mapActions([
      'loadTicketHistory',
    ]),
    applyFilters(value) {
      if (value) this.date = value;
      this.loadTicketHistory(this.date);
    },
    openTicketDetails(ticket) {
      eventBus.$emit('ticketDetails', ticket);
    },
  },
  created() {
    this.applyFilters();
  },
};
</script>

<style lang="scss">
  .vc-popover-content-wrapper {
    .vc-popover-content {
      box-shadow: none;
    }
    .vc-border {
      border-width: 0;
    }
    .vc-border-gray-400 {
      border-color: transparent;
    }
    .vc-bg-white {
      background-color: var(--brand-bg);
      border-color: var(---card-bg);
    }
    .vc-text-gray-500 {
      color: var(--text-primary-2);
      font-weight: 500;
    }
    .vc-bg-blue-600 {
      background-color: var(--brand-bg);
    }
    .vc-text-white {
      color: var(--brand-text-color);
    }
    .vc-bg-blue-200 {
      background-color: var(--brand-bg);
    }
    .vc-text-blue-900 {
      color: var(--brand-text-color);
    }
    .vc-day-content:hover {
      border-color: var(--brand-bg);
      color: var(--brand-text-color);
    }
    .vc-day-layer {
      right: -1px;
      left: -1px;
    }
    .vc-border-blue-700 {
      border-color: var(--brand-bg);
    }
    .vc-popover-caret {
      display: none;
    }
    .vc-w-full {
      background-color: var(--card-bg);
    }
    .vc-grid-container {
      background-color: var(--card-bg);
      color: var(--text-primary-1);
    }
    .vc-text-gray-600 {
      color: var(--text-primary-1);
      background-color: transparent;
    }
    vc-bg-gray-300:hover {
      background-color: transparent;
    }
    .vc-bg-blue-100 {
      background-color: var(--brand-bg);
      border: none;
      outline: none;
    }
    .vc-container {
      background-color: var(--card-bg);
      border-color: var(--card-bg);
      color: var(--text-primary-1);
    --day-content-margin: 1.6px auto;
    --day-content-transition-time: 0.13s ease-in;
    --day-content-bg-color-hover: var(--brand-bg);
    --day-content-dark-bg-color-hover: var(--brand-bg);
    --day-content-bg-color-focus: var(--brand-bg);
    --day-content-dark-bg-color-focus: var(--brand-bg);

      .vc-title {
        color: var(--text-primary-1);
        font-size: 16px;
      }
    }

    .vc-weekday {
      text-transform: uppercase;
    }
  }
  .tickets-history-wrapper {
    background-color: var(--bg-color-2);
    .header {
      float: left;
      width: 100%;
      padding: 8px 16px;
      z-index: 10;
      color: var(--text-primary-1);
      font-family: 'Roboto', sans-serif;

      .product-title {
        float: left;
        width: 100%;
        font-size: 24px;
        margin-bottom: 2px;
        text-align: center;

        .icon {
          float: right;
          cursor: pointer;
          font-size: 18px;
        }
      }

      .date-picker {
        float: left;
        width: 100%;
        margin: 16px 0;

        .label {
          color: var(--text-primary-2);
          opacity: .6;
          font-size: 12px;
        }

        .date-pick {
          width: 50%;
          position: relative;

          .icon {
            position: absolute;
            right: 0;
            top: 0;
            color: var(--text-primary-2);
            opacity: .6;
            z-index: -1;
          }

          .date-picker-field {
            float: left;
            width: 100%;
            border-bottom: 2px solid var(--tab-border-color);

            input {
              width: 100%;
              background: transparent;
              border: none;
              outline: 0;
              color: var(--text-primary-1);
              font-size: 14px;
              padding: 3px 0;
              cursor: pointer;
            }
          }
        }
      }
    }

    .body {
      float: left;
      width: 100%;
      z-index: 1;
      scrollbar-width: none;
      min-height: 25vh;
      flex: 1 0 auto;

      .bets-table {
        float: left;
        width: 100%;
        .rows {
          .row {
            display: flex;
            justify-content: space-between;
            background-color: var(--card-bg);
            padding: 14px 16px;
            height: 58px;
            font-size: 14px;
            color: #efefef;
            margin-bottom: 8px;
            border-radius: 3px;
            cursor: pointer;

            .bet-item {
              .tickets-history-status,
              .tickets-history-payment-info {
                float: left;
                width: 100%;
                font-size: 12px;
              }

              .tickets-history-status {
                font-weight: 500;
                &.status-lost {
                  color: var(--system-negative);
                }

                &.status-won, &.status-payedout {
                  color: var(--system-positive);
                }

                &.status-open {
                  color: var(--system-neutral);
                }
              }
              .tickets-history-payment-info {
                .info {
                  float: left;
                  color: var(--text-primary-2);
                  margin-right: 5px;

                  .value {
                    font-weight: 700;
                    color: var(--text-primary-1);
                  }
                }
              }
            }
          }
        }
      }
    }

    .footer {
      float: left;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 27px;

      .notification-area {
      float: left;
      width: 100%;
      height: 100%;
      font-size: 14px;
      font-weight: 500;
      cursor: default;

      .item {
        height: 100%;
        line-height: 1.5;
        padding: 0 10px;
        color: var(--text-primary-1);
        border-radius: 2px;

        &.info {
          background: var(--brand-bg);
          color: var(--brand-text-color, var(--text-primary-1));
        }

        &.resolving {
          background: var(--system-neutral);
          color: var(--system-neutral-text-color);
        }

        &.success, &.closed {
          background: var(--system-positive);
          color: var(--text-primary-1);
        }

        &.error, &.rejected {
          background: var(--system-negative);
          color: var(--system-positive-text-color, var(--text-primary-1));
        }

        .item-icon {
          height: 100%;
          float: left;
          line-height: 42px;
        }

        .item-title {
          margin-left: 10px;
          float: left;
          width: 90%;
          height: 100%;
          overflow: hidden;
          padding: 10px 0;
        }
      }
    }

      .info {
        height: 42px;
        line-height: 42px;
        width: 100%;
        border-radius: 2px;
        background-color: var(--system-neutral);
        color: var(--system-neutral-text-color);
        padding-left: 8px;
        font-size: 12px;
        position: relative;

        span {
          position: absolute;
          left: 30px;
        }

        .icon {
          position: absolute;
          top: 15px;
        }
      }
    }
  }

  @media (min-width: 320px) and (max-width: 500px) {
    .tickets-history-wrapper {
      .header {
        float: left;
        width: 100%;
        padding: 8px 16px;
        position: absolute;
        top: 0;
        background: var(--card-bg);
        height: 120px;

        .date-picker {
          .date-pick {
            width: 100%;
          }
        }
      }
      .body {
        .bets-table {
          margin-top: 120px;
          .rows {
            margin-top: 122px;
            .row {
              background-color: var(--card-section-bg);
              line-height: 1.2;
              padding: 16px 16px;
              margin-bottom: 1px;
            }
          }
        }
      }

      .footer {
        position: absolute;
        bottom: 0;
        background-color: var(--card-bg);
        width: 100%;
      }
    }
  }
  @media (min-width: 1024px) {
    .tickets-history-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      .body {
        max-height: 50vh;
      }
    }
  }
</style>

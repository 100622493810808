var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.errorOverlay.active
    ? _c("div", { staticClass: "overlay-wrapper" }, [
        _c("div", { staticClass: "overlay-message-box" }, [
          _vm._m(0),
          _c("div", { staticClass: "message" }, [
            _vm._v(" " + _vm._s(_vm.errorOverlay.message) + " "),
          ]),
          _c("div", { staticClass: "close-overlay" }, [
            _c("i", {
              staticClass: "icon icon-close-a",
              on: { click: _vm.closeOverlay },
            }),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "error-icon" }, [
      _c("i", { staticClass: "icon icon-void" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import Gateway from '@nsoft/seven-gravity-gateway';
import eventBus from '@/utility/eventBus';

let gateway = null;

export default {
  init(store) {
    const GatewayConstructor = Gateway.slave;
    gateway = GatewayConstructor({
      debug: process.env.NODE_ENV !== 'production',
      slaveId: 'PluginLuckySix',
      allowedOrigins: [
        'localhost:3000',
        'https://staging.seven-web.7platform.net',
        'https://staging-balkanbet.web.7platform.net',
        'https://qa.web.7platform.net',
        'https://expo.web.7platform.net',
        'https://demo.nsoft.com',
        'https://staging-bethappy.web.7platform.net',
        'https://staging-betvili.web.7platform.net',
        'https://future.bet',
        'https://bonusbet.live',
        'https://www.balkanbet.rs',
        'https://www.boulparyaj.web.7platform.net',
        'https://www.boulparyaj.com',
        'https://betmax888.com',
        'https://www.wett-bet.com',
        'https://staging-bugbet.web.7platform.net',
        'https://maxwin.me',
        'https://fairsplay.com',
        'https://www.sigmasupersport.com',
        'https://www.xybet.live',
        'https://staging-maxbet.web.7platform.net',
        'https://dual.maxbet.rs/ibet-web-client',
        'https://noktabet.web.7platform.net',
        'https://cashwin.web.7platform.net',
        'https://gameofbet.web.7platform.net',
        'https://circuscasino.rs',
        'https://circus.web.7platform.net',
        'https://atlasbet.web.7platform.net',
        'https://betvili.web.7platform.net',
        'https://oynabil.web.7platform.net',
        'https://staging-circus.web.7platform.net',
        'https://premiumbahis.web.7platform.net',
        'https://redfoxbet.web.7platform.net',
        'https://betasus.web.7platform.net',
        'https://staging-atlas.web.7platform.net',
        'https://kombobet.web.7platform.net',
        'https://paryerenovacash.web.7platform.net',
        'https://vbet.web.7platform.net',
        'https://staging-superbet.web.7platform.net',
        'https://www.stage.superbet.ro',
        'https://staging-wbrostage.web.7platform.net',
        'https://wbro.web.7platform.net',
        'https://mybetplus.web.7platform.net',
        'https://avalon.web.7platform.net',
        'https://qq288.web.7platform.net',
        'https://victory.web.7platform.net',
        'https://victory2.web.7platform.net',
        'https://betconstruct.web.7platform.net',
        'https://blueoceangaming.web.7platform.net',
        'https://staging-sportingbookmakers.web.7platform.net',
        'https://staging-atlas.web.7platform.net',
        'https://staging-betworld247.web.7platform.net',
        'https://betworld247.web.7platform.net',
        'https://staging-pionir.web.7platform.net',
        'https://atlas.web.7platform.net',
        'https://pionirinternacional.web.7platform.net',
        'https://sportingbookmakers.web.7platform.net',
        'https://staging-starsportsbet.web.7platform.net',
        'https://staging-winner.web.7platform.net',
        'https://staging-worldstarbetting.web.7platform.net',
        'https://starsportsbet.web.7platform.net',
        'https://mdshop.web.7platform.net',
        'https://gml.web.7platform.net',
        'https://gmleur.web.7platform.net',
        'https://roocasino.web.7platform.net',
        'https://roocasinoeur.web.7platform.net',
        'https://staging-fedora.web.7platform.net',
        'https://wgbnigeria.web.7platform.net',
        'https://lonabiss.web.7platform.net',
      ],
      data: {
        title: 'Lucky Six v2',
      },
      eventListeners: {
        scroll: true,
      },
      load: (message) => {
        eventBus.$emit('Slave.Load', message);
        gateway.sendMessage({
          action: 'Slave.Loaded',
          data: {},
        });
      },
    });
    this.setupListeners(store);
  },
  sendMessage(action, data) {
    gateway.sendMessage({
      action,
      data,
    });
  },
  setupListeners() {
    gateway.on('User.AuthorizationChanged', (message) => {
      eventBus.$emit('User.AuthorizationChanged', message);
    });
    gateway.on('User.BalanceChanged', (message) => {
      eventBus.$emit('User.BalanceChanged', message);
    });
    gateway.on('User.ProfileChanged', (message) => {
      eventBus.$emit('User.ProfileChanged', message);
    });
    gateway.on('User.Login', () => {});
  },
};

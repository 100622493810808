<template>
  <div class="main-bets-wrapper">
    <div class="main-bets-balls">
      <ball></ball>
    </div>
    <div class="color-groups">
      <color-group v-for="(color, index) in colors"
                   :key="index"
                   :color="color"
                   :index="index"
                   :reset="reset">
      </color-group>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Ball from './Ball';
import ColorGroup from './ColorGroup';

export default {
  name: 'GameMainBets',
  components: {
    Ball,
    ColorGroup,
  },
  data() {
    return {
      reset: true,
    };
  },
  computed: {
    ...mapGetters([
      'colors',
      'totalBalls',
      'balls',
      'bettingLocked',
    ]),
  },
  methods: {
    ...mapActions([
      'createBalls',
    ]),
  },
  created() {
    this.createBalls(this.totalBalls);
  },
};
</script>

<style scoped lang="scss">
  .main-bets-wrapper {
    &.disabled {
      opacity: .4;
    }
    .main-bets-balls {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media (min-width: 306px) and (max-width: 359px) {
    .main-bets-wrapper {
      .main-bets-balls, .color-groups {
        width: 280px;
        margin: 0 auto;
      }
    }
  }

  @media (min-width: 360px) and (max-width: 659px) {
    .main-bets-wrapper {
      .main-bets-balls, .color-groups {
        width: 344px;
        margin: 0 auto;
      }
    }
  }
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-event-details" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "product-info" }, [_vm._v("Lucky Six")]),
          _c("div", { staticClass: "round-info" }, [
            _vm._v(
              _vm._s(_vm._f("translate")("general_round")) +
                " " +
                _vm._s(_vm.round)
            ),
          ]),
        ]),
        _c("i", {
          staticClass: "icon icon-close-a",
          on: {
            click: function ($event) {
              return _vm.toggleStats()
            },
          },
        }),
      ]),
      _c(
        "div",
        { staticClass: "body" },
        [_c("DrawDetails", { attrs: { tabs: _vm.eventDetailsTabs } })],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default {
  palette: {},
  layout: '',
  appConfig: {
    company: {},
    platform: {
      name: '',
      skinId: '',
      real: '',
    },
    product: {},
    language: 'en',
    endpoint: '',
    paths: {},
  },
  player: {
    username: '',
    id: '',
    loggedIn: false,
    auth: {
      token: 'xx',
    },
    balance: {
      time: null,
      balanceType: '',
      balanceAmt: 0,
      currency: '',
      divide: 1,
      locale: 'en-EN',
    },
    audio: false,
  },
  platforms: {
    playtech: {
      development: {
        endpoint: 'https://games-playtech.staging.gb.nsoftcdn.com/playtech',
        paths: {
          player: {
            login: '/player/login',
            logout: '/player/logout',
            info: '/player/get-player-info',
            tempToken: '/player/get-token',
          },
          ticket: {
            history: '/ticket/player-history',
            lastTickets: '/ticket/last-tickets',
            check: '/ticket/request/check',
            add: '/ticket/add',
            cancel: '/ticket/cancel',
          },
          config: '/config',
          events: '/events',
          stats: '/statistics',
        },
        skinId: '',
        real: '',
      },
      staging: {
        endpoint: 'https://games-playtech.staging.gb.nsoftcdn.com/playtech',
        paths: {
          player: {
            login: '/player/login',
            logout: '/player/logout',
            info: '/player/get-player-info',
            tempToken: '/player/get-token',
          },
          ticket: {
            history: '/ticket/player-history',
            lastTickets: '/ticket/last-tickets',
            check: '/ticket/request/check',
            add: '/ticket/add',
            cancel: '/ticket/cancel',
          },
          config: '/config',
          events: '/events',
          stats: '/statistics',
        },
        skinId: '',
        real: '',
      },
      production: {
        endpoint: 'https://ngs.7platform.com/playtech',
        paths: {
          player: {
            login: '/player/login',
            logout: '/player/logout',
            info: '/player/get-player-info',
            tempToken: '/player/get-token',
          },
          ticket: {
            history: '/ticket/player-history',
            lastTickets: '/ticket/last-tickets',
            check: '/ticket/request/check',
            add: '/ticket/add',
            cancel: '/ticket/cancel',
          },
          config: '/config',
          events: '/events',
          stats: '/statistics',
        },
        skinId: '',
        real: '',
      },
    },
    seven: {
      development: {
        endpoint: 'https://ngs-staging.7platform.com/api_open',
        paths: {
          config: '/config',
          addTicket: '/web/tickets/add.json',
          cancelTicket: '/web/tickets/cancel.json',
          events: '/web/events',
          stats: '/statistics',
          ticket: {
            history: '/last-player-tickets',
            lastTickets: '/last-player-tickets',
            check: '/ticket/request/check',
            add: '/web/tickets/add',
            cancel: '/web/tickets/cancel.json',
          },
          auth: '/users/b2b/authenticate',
          b2bBalance: '/wallet/b2b/loggeduser/balance',
        },
      },
      staging: {
        endpoint: 'https://ngs-staging.7platform.com/api_open',
        paths: {
          config: '/config',
          addTicket: '/web/tickets/add.json',
          cancelTicket: '/web/tickets/cancel.json',
          events: '/web/events',
          stats: '/statistics',
          ticket: {
            history: '/last-player-tickets',
            lastTickets: '/last-player-tickets',
            check: '/web/tickets/request',
            add: '/web/tickets/add',
            cancel: '/web/tickets/cancel.json',
          },
          auth: '/users/b2b/authenticate',
          b2bBalance: '/wallet/b2b/loggeduser/balance',
        },
      },
      production: {
        endpoint: 'https://ngs.7platform.com/api_open',
        paths: {
          config: '/config',
          addTicket: '/web/tickets/add.json',
          cancelTicket: '/web/tickets/cancel.json',
          events: '/web/events',
          stats: '/statistics',
          ticket: {
            history: '/last-player-tickets',
            lastTickets: '/last-player-tickets',
            check: '/web/tickets/request',
            add: '/web/tickets/add',
            cancel: '/web/tickets/cancel.json',
          },
          auth: '/users/b2b/authenticate',
          b2bBalance: '/wallet/b2b/loggeduser/balance',
        },
      },
    },
  },
  platformName: 'seven',
  tenantId: '',
  notifications: [],
  translations: {},
  round: 0,
  roundInfo: 0,
  lang: 'en',
  clientApp: 'web',
  gameBgColor: '#1a1c1d',
  bettingBodyBgColor: '#242628',
  isGameLoaded: false,
  errorOverlay: {
    message: '',
    active: false,
  },
  balls: [],
  totalBalls: 48,
  bettingEnabled: true,
  timeForRemoveNotification: 3000,
  allColorsSelected: false,
  colors: [
    {
      colorId: 0,
      subtitle: 'luckysix_color_0',
      active: false,
      hex: '#dd1f1f',
      outcomes: [],
    },
    {
      colorId: 1,
      subtitle: 'luckysix_color_1',
      active: false,
      hex: '#1cc51c',
      outcomes: [],
    },
    {
      colorId: 2,
      subtitle: 'luckysix_color_2',
      active: false,
      hex: '#0087ff',
      outcomes: [],
    },
    {
      colorId: 3,
      subtitle: 'luckysix_color_3',
      active: false,
      hex: '#a82def',
      outcomes: [],
    },
    {
      colorId: 4,
      subtitle: 'luckysix_color_4',
      active: false,
      hex: '#844e14',
      outcomes: [],
    },
    {
      colorId: 5,
      subtitle: 'luckysix_color_5',
      active: false,
      hex: '#efc82d',
      outcomes: [],
    },
    {
      colorId: 6,
      subtitle: 'luckysix_color_6',
      active: false,
      hex: '#cb5b00',
      outcomes: [],
    },
    {
      colorId: 7,
      subtitle: 'luckysix_color_7',
      active: false,
      hex: '#9a9a9a',
      outcomes: [],
    },
  ],
  rules: {
    multiselect: {
      maxOutcomeSelection: {
        name: 'Max outcome selection',
        value: 4,
      },
      edgeCaseSelection: {
        name: 'Edge case selection',
        value: 3,
      },
    },
    minBallSelection: {
      name: 'Min ball selection',
      message: 'You need to pick min 6 numbers',
      value: 6,
    },
    maxBallSelection: {
      name: 'Max ball selection',
      value: 10,
    },
    minBetAmount: {
      name: 'Min bet amount',
      value: 20,
    },
    maxBetAmount: {
      name: 'Max bet amount is ',
      value: 500,
    },
    minCombBetAmount: {
      name: 'Min combination bet amount',
      value: 0.1,
    },
    maxBetNumber: {
      name: 'Max bet number',
      value: 10,
    },
  },
  specialBets: [
    {
      active: false,
      title: '',
      subtitle: 'games_first_ball_color',
      id: 5,
      elementType: 'checkbox',
      elementSubType: 'buttonCheckbox',
      multiselect: true,
      toggle: false,
      type: 'Special',
      outcomes: [
        {
          id: 0,
          title: 'luckysix_color_0',
          active: false,
        },
        {
          id: 1,
          title: 'luckysix_color_1',
          active: false,
        },
        {
          id: 2,
          title: 'luckysix_color_2',
          active: false,
        },
        {
          id: 3,
          title: 'luckysix_color_3',
          active: false,
        },
        {
          id: 4,
          title: 'luckysix_color_4',
          active: false,
        },
        {
          id: 5,
          title: 'luckysix_color_5',
          active: false,
        },
        {
          id: 6,
          title: 'luckysix_color_6',
          active: false,
        },
        {
          id: 7,
          title: 'luckysix_color_7',
          active: false,
        },
      ],
      odds: [7.2, 3.6, 2.4, 1.8],
    },
    {
      active: false,
      title: '',
      subtitle: 'games_first_ball_odd_even',
      id: 7,
      elementType: 'button',
      multiselect: false,
      toggle: true,
      type: 'Special',
      outcomes: [{
        id: 0,
        title: 'general_even',
        active: false,
      }, {
        id: 1,
        title: 'general_odd',
        active: false,
      },
      ],
      odds: 1.8,
    },
    {
      active: false,
      title: '',
      subtitle: 'luckysix_first_ball_low_high',
      id: 8,
      elementType: 'button',
      multiselect: false,
      toggle: true,
      type: 'Special',
      limit: 24.5,
      outcomes: [{
        id: 0,
        title: 'general_under',
        active: false,
      }, {
        id: 1,
        title: 'general_over',
        active: false,
      },
      ],
      odds: 1.8,
    },
    {
      active: false,
      title: '',
      subtitle: 'luckysix_more_preballs',
      id: 11,
      elementType: 'button',
      multiselect: false,
      toggle: true,
      type: 'Special',
      outcomes: [{
        id: 0,
        title: 'general_even',
        active: false,
      }, {
        id: 1,
        title: 'general_odd',
        active: false,
      }],
      odds: 1.8,
    },
    {
      active: false,
      title: '',
      subtitle: 'luckysix_preballs_sum',
      id: 6,
      elementType: 'button',
      multiselect: false,
      toggle: true,
      type: 'Special',
      limit: 122.5,
      outcomes: [{
        id: 0,
        title: 'general_under',
        active: false,
      }, {
        id: 1,
        title: 'general_over',
        active: false,
      },
      ],
      odds: 1.8,
    },
  ],
  preball: false,
  preballBet: {
    id: 10,
    title: '',
    subtitle: 'general_preball',
    odds: 8,
  },
  colorGroup: {},
  bet: {
    id: null,
    title: null,
    subtitle: null,
    odds: null,
    type: null,
    outcomes: [],
    stake: 1,
  },
  betsModel: {
    items: [],
  },
  ball: {
    id: null,
    number: null,
    active: null,
  },
  stats: {
    drawnMostTimes: [
      {
        count: 100,
        ball: 1,
      },
      {
        count: 100,
        ball: 28,
      },
      {
        count: 100,
        ball: 22,
      },
      {
        count: 100,
        ball: 23,
      },
      {
        count: 100,
        ball: 24,
      },
    ],
    drawnLeastTimes: [
      {
        count: 0,
        ball: 48,
      },
      {
        count: 0,
        ball: 36,
      },
      {
        count: 0,
        ball: 37,
      },
      {
        count: 0,
        ball: 39,
      },
      {
        count: 0,
        ball: 40,
      },
    ],
    firstBallOddEven: {
      even: '',
      odd: '',
    },
    firstBallLowHigh: {
      low: '',
      high: '',
    },
    preballsMoreOddEven: {
      even: '',
      odd: '',
    },
    preballsSumLowHigh: {
      low: '',
      high: '',
    },
    firstBallColor: {
      red: 100,
      green: 0,
      blue: 0,
      purple: 0,
      brown: 0,
      yellow: 0,
      orange: 0,
      black: 0,
    },
  },
  resultsData: [],
  randomNumbers: [],
  colorGroupValue: null,
  payinDisabled: true,
  requestDisabled: false,
  popTenants: {
    playtech800053: {
      tenants: [
        'nsoft_53_1',
        'nsoft_53_2',
        'nsoft_53_3',
        'nsoft_53_4',
        'nsoft_53_5',
      ],
      password: 'Pop123',
    },
    playtech800055: {
      tenants: [
        'nsoft_55_1',
        'nsoft_55_2',
        'nsoft_55_3',
        'nsoft_55_4',
        'nsoft_55_5',
      ],
      password: 'Pop123',
    },
    playtech800072: {
      tenants: [
        'nsoft_72_1',
        'nsoft_72_2',
        'nsoft_72_3',
        'nsoft_72_4',
        'nsoft_72_5',
      ],
      password: 'Pop123',
    },
    playtech850036: {
      tenants: [
        'nsoft_pop_ron_161',
        'nsoft_pop_ron_910',
        'nsoft_pop_ron_907',
        'nsoft_pop_ron_573',
        'nsoft_pop_ron_842',
      ],
      password: 'Q1w2e3r4',
    },
    playtech850037: {
      tenants: [
        'nsoft_pop_eur_279',
        'nsoft_pop_eur_319',
        'nsoft_pop_eur_423',
        'nsoft_pop_eur_381',
        'nsoft_pop_eur_399',
      ],
      password: 'Pop123',
    },
  },
  lastTicketsData: [],
  ticketHistoryData: [],
  expandTicketHistory: false,
  plugins: {},
  drawnBalls: [],
  showModalStats: false,
  pickNumbers: [
    {
      value: 6,
      label: 'Standard',
      subLabel: '',
    },
    {
      value: 7,
      label: 'general_system',
      subLabel: '6/7',
    },
    {
      value: 8,
      label: 'general_system',
      subLabel: '6/8',
    },
    {
      value: 9,
      label: 'general_system',
      subLabel: '6/9',
    },
    {
      value: 10,
      label: 'general_system',
      subLabel: '6/10',
    },
  ],
  bettingLocked: false,
  lastTicketsInProgress: false,
  ticketHistoryInProgress: false,
  ticketHistoryRangeError: {},
  bettingDisabled: false,
  isFullScreen: false,
  localizedTranslations: {},
  showNewFeatures: true,
  showFeed: false,
  thirdPartyToken: null,
};

module.exports = {
  development: {
    ngsApi: 'https://ngs-staging.7platform.com/app.php',
    ticketApi: 'https://ngs-staging.7platform.com/app.php',
    sevenApi: 'https://services-staging.7platform.com',
    gravityApi: 'https://staging-gravity-service.7platform.com',
    tcApi: 'https://tc-api.nsoft.com/v2/b2b/translations',
    streamApi: '://stream-staging.7platform.live',
    gcmApi: [
      'https://gcm-fra-staging-1.7platform.com:8008',
      'https://gcm-fra-staging-2.7platform.com:8008',
    ],
    visualizationPlugin: 'https://seven-luckysix-staging.nsoft.ba',
  },
  staging: {
    ngsApi: 'https://ngs-staging.7platform.com/app.php',
    ticketApi: 'https://ngs-staging.7platform.com/app.php',
    sevenApi: 'https://services-staging.7platform.com',
    gravityApi: 'https://staging-gravity-service.7platform.com',
    tcApi: 'https://tc-api.nsoft.com/v2/b2b/translations',
    streamApi: '://stream-staging.7platform.live',
    gcmApi: [
      'https://gcm-fra-staging-1.7platform.com:8008',
      'https://gcm-fra-staging-2.7platform.com:8008',
    ],
    visualizationPlugin: 'https://seven-luckysix-staging.nsoft.ba',
  },
  production: {
    ngsApi: 'https://ngs.7platform.com/app.php',
    ticketApi: 'https://ngs.7platform.com/app.php',
    sevenApi: 'https://services.7platform.com',
    gravityApi: 'https://gravity-service.7platform.com',
    tcApi: 'https://tc-api.nsoft.com/v2/b2b/translations',
    streamApi: '://stream-staging.7platform.live',
    gcmApi: [
      'https://gcm-fra-1.7platform.com:8008',
      'https://gcm-fra-2.7platform.com:8008',
      'https://gcm-fra-3.7platform.com:8008',
    ],
    visualizationPlugin: 'https://seven-luckysix.nsoft.ba',
  },
};

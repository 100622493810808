var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    staticClass: "feed-frame",
    attrs: { frameborder: "0", scrolling: "no", src: _vm.url },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="app"
         id="app"
         :class="`theme-${mode}`"
         ref="app" >
        <router-view></router-view>
      <NewFeatures />
    </div>
</template>

<script>
import isMobile from 'ismobilejs';
import {
  assign,
  each,
  camelCase,
  toLower,
  has,
  merge,
} from 'lodash';
import {
  applyPalette,
  translations,
  gcm,
  socket,
} from '@nsoft/games-sdk-js/src/utility';
import { mapGetters, mapActions } from 'vuex';
import PlatformContext from '@/utility/platformStrategy';
import eventBus from '@/utility/eventBus';
import integrator from '@/utility/integrator';
import gravity from '@/utility/gravityGateway';
import tenants from '@/tenants';
import webViewIntegration from '@/utility/webViewIntegration';
import getTenantByUuid from '../config/tenantByUuid';
import { serverEnv } from '../config/index';
import i18n from './i18n';
import NewFeatures from './components/web/common/NewFeatures';

let context;
const popEndpoint = 'https://games-playtech.integration.gb.nsoftcdn.com/playtech';

export default {
  name: 'App',
  components: {
    NewFeatures,
  },
  data() {
    return {
      // theme mode
      mode: 'light',
      newConfig: false,
    };
  },
  computed: {
    ...mapGetters([
      'palette',
      'platform',
      'platforms',
      'popTenants',
      'player',
      'plugins',
      'appConfig',
      'appLayout',
      'isGameLoaded',
      'gameBgColor',
    ]),
  },
  methods: {
    ...mapActions([
      'setTranslations',
      'setPopEndpoint',
      'loadPlugins',
      'rebetTicket',
      'updatePlayerInfo',
      'updatePlayerBalance',
      'checkPayin',
      'checkBalance',
      'loadLastTickets',
      'setLocalizedTranslations',
      'clearCheckTimeout',
      'authUser',
      'setThirdPartyToken',
    ]),
    getQueryParams() {
      return this.$route.query || {};
    },
    async connectToGcm(config, queryParams) {
      const route = await gcm.getRoute();
      const playerData = {
        id: this.player.id || queryParams.username,
      };
      await socket.init(route.url, 'web', config);
      await socket.subscribe(config, playerData);
      if (config.platform !== 'seven') {
        await socket.balanceSubscribe(config);
      }
    },
    boot() {
      const queryParams = this.getQueryParams();
      const tenant = toLower(camelCase(queryParams.tenantId || getTenantByUuid(queryParams.tenantUuid) || ''));
      const isPop = Object.keys(this.popTenants).includes(tenant);
      if (queryParams.platform) {
        const platformQueryParam = queryParams.platform.toLowerCase();
        if (isPop) {
          this.setPopEndpoint({
            platform: queryParams.platform,
            env: serverEnv,
            popEndpoint,
          });
        }
        const routes = (this.platforms[platformQueryParam] || this.platform)[serverEnv];

        (async () => {
          const tenantConfig = (await tenants[tenant]()).default;
          const config = assign(tenantConfig, routes, queryParams);
          if (queryParams.isIntegration || config.company.mode === 'integration') {
            const {
              token,
              id,
              authStrategy,
              auth,
            } = queryParams;
            if (token && !auth) {
              this.setThirdPartyToken(token);
              this.authUser({
                config,
                token,
                id,
                authStrategy,
              });
            }
          }
          const localizedTranslations = (await i18n.common(config.language)).default;
          this.setLocalizedTranslations(localizedTranslations);
          if (config.plugins) {
            this.loadPlugins(config.plugins);
            this.appendPlugins(config.plugins);
          }

          context = new PlatformContext(config, queryParams);
          context.contextInterface();
          if (!this.newConfig) {
            await this.connectToGcm(config, queryParams);
          }
          await this.translateApp(config);
          this.setTheme(config.company);
        })();
      }
    },
    appendPlugins(plugins) {
      if (plugins) {
        each(plugins, (clientPlugin) => {
          const plugin = clientPlugin;
          if (plugin.enabled) {
            each(plugin.appendTo[serverEnv], (tag, key) => {
              const pluginData = isMobile().any && plugin.data.mobile
                ? plugin.data.mobile : plugin.data;
              plugin.snippet = tag.snippet.supplant({
                id: pluginData.id,
                auth: pluginData.auth,
                preview: pluginData.preview,
              });
              const element = document.createElement(`${tag.type}`);
              element.innerText = plugin.snippet;
              document[key].appendChild(element);
            });
          }
        });
      }
    },
    async translateApp(config) {
      // Fix channel type, take value from the config
      const { language } = config;
      const { companyUuid } = config.company;
      const tcResponse = await translations.fetch(language, companyUuid, 'web');
      this.setTranslations(tcResponse);
    },
    setTheme(config) {
      this.mode = config.theme;
      const { palette } = config;
      applyPalette.set(palette, this.$refs.app);
    },
    addSubChannels(channel, playerId) {
      socket.addSubChannels(channel, playerId);
    },
    removeSubChannels() {
      socket.removeSubChannels(this.appConfig.product.cpvUuid);
    },
    checkClientPlatformInfo() {
      const queryParams = this.getQueryParams();
      const { clientplatform } = queryParams;
      const redirectUrl = this.appConfig.company.redirect.url;
      if (clientplatform && clientplatform === 'download') {
        window.location.replace(redirectUrl);
      }
    },
  },
  mounted() {
    this.boot();
    if (webViewIntegration.isActive() && has(window, 'WebAppListener')) {
      /* eslint-disable */
      WebAppListener.sendMessage('Slave.Init');
    }
    eventBus.$on('rebet', (ticket) => {
      this.rebetTicket(ticket);
    });
    eventBus.$on('loginRequired', () => {
      // if (webViewIntegration.isActive() && has(window, 'WebAppListener')) {
      this.checkClientPlatformInfo();
      // }
      integrator.sendMessage({
        type: 'loginRequired',
      });
    });
    eventBus.$on('newConfig', () => {
      this.newConfig = true;
      this.boot();
    });
    window.addEventListener('message', (event) => {
      const eventType = event.data.type;
      const { content } = event.data;
      switch (eventType) {
        case 'balanceChanged':
          eventBus.$emit('User.BalanceChanged', { data: content });
          break;
        case 'bettingDisabled':
          console.log('bettingDisabled!!!');
          break;
        case 'ticketRebet':
          this.rebetTicket(content.ticket);
          break;
        default:
          break;
      }
    });
    eventBus.$on('User.AuthorizationChanged', (message) => {
      const { data } = message;
      const { tempToken } = data.auth;
      const { id } = data.auth.user;
      if (!this.appConfig.company.gravityEnabled) {
        const queryParams = this.getQueryParams();
        const {
          authStrategy,
          auth,
        } = queryParams;
        if (tempToken && auth) {
          this.setThirdPartyToken(tempToken);
          this.authUser({
            config: this.appConfig,
            tempToken,
            id,
            authStrategy,
          });
        }
      }
      if (data.auth.tpToken) {
        this.updatePlayerInfo(merge(data.auth.user, { tpToken: data.auth.tpToken }));
      } else {
        this.updatePlayerInfo(data.auth.user);
      }
      if (data.auth.user.id) {
        this.addSubChannels(this.appConfig.product.cpvUuid, data.auth.user.id);
      } else {
        this.removeSubChannels();
      }
    });
    eventBus.$on('User.BalanceChanged', (message) => {
      const { data } = message;
      this.updatePlayerBalance(data);
      this.checkPayin();
      this.checkBalance();
    });
    eventBus.$on('User.ProfileChanged', (message) => {
      if (message.data.profile.balance) this.loadLastTickets();
    });
    eventBus.$on('Slave.Load', (message) => {
      if (webViewIntegration.isActive() && has(window, 'WebAppListener')) {
        /* eslint-disable */
        WebAppListener.sendMessage('Slave.Loaded');
      }
      const { data } = message;
      if (data.user.token) this.updatePlayerInfo(data.user);
      if (data.user.id) {
        this.addSubChannels(data.product.cpvUuid, data.user.id);
      } else {
        this.removeSubChannels();
      }
    });
    eventBus.$on('ticketUpdate', (data) => {
      this.clearCheckTimeout();
      if (data.id && this.appConfig.company.gravityEnabled) {
        gravity.sendMessage(
          'Tickets.Resolved', {
            accepted: true,
            ticket: data,
          },
        );
      }
    });
    eventBus.$on('ReturnToLobby', () => {
      if (this.appConfig.company.gravityEnabled) {
        if (webViewIntegration.isActive() && has(window, 'WebAppListener')) {
          /* eslint-disable */
          WebAppListener.sendMessage('Navigation.Back');
        } else {
          gravity.sendMessage(
            'UI.Show', {
              name: 'Lobby',
            },
          );
        }
      }
    });
  },
  watch: {
    'player.id': function () {
      // this.connectToGcm(this.appConfig, this.$route.query);
    },
  },
};
</script>

<style lang="scss">
* {
    margin: 0;
    padding: 0;
    outline-style: none;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}
html, body {
    background-color: var(--bg-color-1);
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    -ms-overflow-style: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
#app {
    font-family: 'Roboto', sans-serif;
}

.app {
    background-repeat: no-repeat;
    background-size: cover;
    float: left;
    width: 100%;
    height: 100%;
    @include theme(var(--bg-color-1));
    &.theme-dark {
        background-image: url('assets/L6_bg_dark.jpg');
    }
    &.theme-light {
    }
}

@media (-webkit-device-pixel-ratio: 1.1) {
  .ticket-wrapper {
    zoom: 0.9;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .ticket-wrapper {
    zoom: 0.8;
  }
}
</style>

<template>
  <div class="add-remove-bet"
       v-if="betslipConfig.layout === 'Expanded'">
    <button class="button remove-bet"
            @click="clearSelection"
            :class="{disabled: !clearBtnEnabled}"
            :disabled="!clearBtnEnabled">
      <i class="icon icon-trash"></i>
    </button>
    <button class="button add-bet brand"
            :class="{disabled: !bettingEnabled || !actionsEnabled}"
            :disabled="!bettingEnabled || !actionsEnabled"
            @click="addToBetslip">
      {{addLabel}}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { audio } from '@nsoft/games-sdk-js/src/utility/index';
import eventBus from '../../../utility/eventBus';

export default {
  name: 'AddRemoveBet',
  computed: {
    ...mapGetters([
      'bet',
      'betsModel',
      'betslipConfig',
      'bettingEnabled',
      'translations',
      'ticket',
      'rules',
      'editMode',
      'player',
      'isAudioOn',
    ]),
    addLabel() {
      return this.editMode
        ? this.translations.general_update_bet
        : this.translations.general_add_bet;
    },
    actionsEnabled() {
      return this.bet.outcomes.length > 0
        || this.betsModel.items.length > 0;
    },
    clearBtnEnabled() {
      return this.actionsEnabled || this.editMode;
    },
  },
  methods: {
    ...mapActions([
      'addDataToBetslip',
      'resetBet',
      'resetBets',
      'resetSelection',
      'checkPayin',
      'selectAllColors',
      'updateNotifications',
      'resetSelectedBet',
    ]),
    addToBetslip() {
      const data = this.betsModel.items.length ? this.betsModel : this.bet;
      if (!this.player.loggedIn) {
        this.updateNotifications({
          status: 'error',
          message: 'general_player_login',
          duration: 7000,
        });
      }
      if (!this.bettingEnabled) {
        this.updateNotifications({
          status: 'info',
          message: this.rules.minBallSelection.message,
        });
        return;
      }
      if (!this.maxBetValid() && !this.editMode) {
        const msg = this.translations.general_max_bet_count_rule.supplant({
          value: this.rules.maxBetNumber.value,
        });
        this.updateNotifications({
          status: 'error',
          message: msg,
        });
        return;
      }
      this.addDataToBetslip(data);
      this.checkPayin();
      this.clearSelection();
      this.selectAllColors(false);
      if (this.isAudioOn) audio.playSound('click');
      eventBus.$emit('addBet');
    },
    maxBetValid() {
      const { maxBetNumber } = this.rules;
      const betsLen = this.ticket.bets.length;
      return betsLen < maxBetNumber.value;
    },
    clearSelection(event) {
      this.resetBet();
      this.resetBets();
      this.resetSelection();
      this.resetSelectedBet();
      this.selectAllColors(false);
      if (this.isAudioOn && event) audio.playSound('clear');
    },
  },
  watch: {
    editMode(edit) {
      if (!edit) this.clearSelection();
    },
  },
};
</script>

<style lang="scss" scoped>
  .add-remove-bet {
    height: 56px;
    float: right;
    width: 100%;
    background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .remove-bet {
      width: 40px;
      height: 40px;
      margin-right: 8px;
      font-size: 16px;
      transition: all .5s ease;
      border-radius: var(--custom-border-radius, var(--border-radius));
      background-color: var(--main-primary);
      color: var(--brand-text-color, var(--text-primary-1));

      &.disabled {
        background-color: var(--button-pressed-bg);
        color: var(--text-primary-2);
      }
    }

    .add-bet {
      width: 152px;
      font-size: 14px;
      background-color: var(--add-bet-button-bg, var(--brand-bg));
      background: var(--add-bet-button-bg, var(--brand-bg));
      color: var(--add-to-betslip-button-text-color, var(--brand-text-color));
      transition: all .5s ease;

      &:hover {
        background-color: var(--add-bet-button-hover-bg);
      }

      &:active {
        background-color: var(--add-bet-button-pressed-bg);
      }

      &.disabled {
        background-color: var(--brand-bg-disabled);
        color: var(--brand-text-disabled-color);
      }
    }
  }
</style>

import { each } from 'lodash';

const checkBallParity = (ball) => (ball % 2 !== 0);

class Result {
  constructor(data, config) {
    this.config = config;
    this.balls = data.balls;
    this.bonusBalls = data.bonusBalls;
    this.eventId = data.eventId;
    this.preballsSum = 0;
    this.preballsSumText = null;
    this.odd = false;
  }

  reset() {
    this.preballsOddCount = 0;
    this.preballsEvenCount = 0;
    this.preballsSum = 0;
    this.preballsSumText = null;
  }

  update() {
    this.reset();
    each(this.balls, (item) => {
      if (item.id <= 5) {
        this.odd = checkBallParity(item.ball);
        this.preballsOddCount += (this.odd) ? 1 : 0;
        this.preballsEvenCount += (this.odd) ? 0 : 1;
        this.preballsSum += item.ball;

        // Set preballs parity text
        this.preballsParityText = (this.preballsOddCount < this.preballsEvenCount)
          ? this.config.bets[11].outcomes[0] : this.config.bets[11].outcomes[1];

        // Set preballs sum text
        this.preballsSumText = (this.preballsSum < this.config.bets[6].limit)
          ? this.config.bets[6].outcomes[0] : this.config.bets[6].outcomes[1];
      }
    });
  }
}

export { Result };

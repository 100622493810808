var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "radio-button" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.selectedPick,
          expression: "selectedPick",
        },
      ],
      attrs: { type: "radio", id: _vm.id, name: "radio" },
      domProps: {
        value: _vm.id,
        checked: _vm.selectedPick,
        checked: _vm._q(_vm.selectedPick, _vm.id),
      },
      on: {
        change: [
          function ($event) {
            _vm.selectedPick = _vm.id
          },
          function ($event) {
            return _vm.onChange(_vm.id)
          },
        ],
      },
    }),
    _c("div", { staticClass: "check" }),
    _c(
      "label",
      {
        attrs: { for: _vm.id },
        on: {
          click: function ($event) {
            return _vm.onChange(_vm.id)
          },
        },
      },
      [_vm._v(" " + _vm._s(_vm.id) + " " + _vm._s(_vm.fullLabel) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stats-wrapper" }, [
    _vm.bets
      ? _c("div", { staticClass: "toggle-bet-stats" }, [
          _vm.bets["7"].value
            ? _c(
                "div",
                { staticClass: "bet-stats" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title",
                      attrs: {
                        title: _vm._f("uppercase")(
                          _vm._f("translate")("games_first_ball_odd_even")
                        ),
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("uppercase")(
                              _vm._f("translate")("games_first_ball_odd_even")
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "subtitle" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("uppercase")(
                            _vm._f("translate")("luckysix_first_number_label")
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _vm.stats && _vm.stats.firstBallOddEven.even
                    ? _c(
                        "vue-circle",
                        {
                          attrs: {
                            progress: Number(_vm.stats.firstBallOddEven.even),
                            size: _vm.circleSize,
                            reverse: false,
                            "line-cap": "square",
                            fill: _vm.fill,
                            "empty-fill": "#d1d1d1",
                            "animation-start-value": 0.0,
                            "start-angle": -1.57,
                            "insert-mode": "append",
                            thickness: 3,
                            "show-percent": true,
                          },
                        },
                        [
                          _c("p", [
                            _vm._v(_vm._s(_vm._f("translate")("general_even"))),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "indicators" }, [
                    _c("div", { staticClass: "odd" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("translate")("general_odd"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "even" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("translate")("general_even"))),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.bets["8"].value
            ? _c(
                "div",
                { staticClass: "bet-stats" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title",
                      attrs: {
                        title: _vm._f("uppercase")(
                          _vm._f("translate")("luckysix_first_ball_low_high")
                        ),
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("uppercase")(
                              _vm._f("translate")(
                                "luckysix_first_ball_low_high"
                              )
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "subtitle" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("uppercase")(
                            _vm._f("translate")("luckysix_first_number_label")
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _vm.stats && _vm.stats.firstBallLowHigh.high
                    ? _c(
                        "vue-circle",
                        {
                          staticClass: "circle",
                          attrs: {
                            progress: Number(_vm.stats.firstBallLowHigh.high),
                            size: _vm.circleSize,
                            reverse: false,
                            "line-cap": "square",
                            fill: _vm.fill,
                            "empty-fill": "#d1d1d1",
                            "animation-start-value": 0.0,
                            "start-angle": -1.57,
                            "insert-mode": "append",
                            thickness: 3,
                            "show-percent": true,
                          },
                        },
                        [
                          _c("p", [
                            _vm._v(_vm._s(_vm._f("translate")("general_over"))),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "indicators" }, [
                    _c("div", { staticClass: "odd" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("translate")("general_under"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "even" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("translate")("general_over"))),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.bets["11"].value
            ? _c(
                "div",
                { staticClass: "bet-stats" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title",
                      attrs: {
                        title: _vm._f("uppercase")(
                          _vm._f("translate")("luckysix_more_preballs")
                        ),
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("uppercase")(
                              _vm._f("translate")("luckysix_more_preballs")
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "subtitle" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("uppercase")(
                            _vm._f("translate")("luckysix_preballs_label")
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _vm.stats && _vm.stats.preballsMoreOddEven.even
                    ? _c(
                        "vue-circle",
                        {
                          attrs: {
                            progress: Number(
                              _vm.stats.preballsMoreOddEven.even
                            ),
                            size: _vm.circleSize,
                            reverse: false,
                            "line-cap": "square",
                            fill: _vm.fill,
                            "empty-fill": "#d1d1d1",
                            "animation-start-value": 0.0,
                            "start-angle": -1.57,
                            "insert-mode": "append",
                            thickness: 3,
                            "show-percent": true,
                          },
                        },
                        [
                          _c("p", [
                            _vm._v(_vm._s(_vm._f("translate")("general_even"))),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "indicators" }, [
                    _c("div", { staticClass: "odd" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("translate")("general_odd"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "even" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("translate")("general_even"))),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.bets["6"].value
            ? _c(
                "div",
                { staticClass: "bet-stats" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title",
                      attrs: {
                        title: _vm._f("uppercase")(
                          _vm._f("translate")("luckysix_preballs_sum")
                        ),
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("uppercase")(
                              _vm._f("translate")("luckysix_preballs_sum")
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "subtitle" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("uppercase")(
                            _vm._f("translate")("luckysix_preballs_sum_label")
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _vm.stats && _vm.stats.preballsSumLowHigh.high
                    ? _c(
                        "vue-circle",
                        {
                          attrs: {
                            progress: Number(_vm.stats.preballsSumLowHigh.high),
                            size: _vm.circleSize,
                            reverse: false,
                            "line-cap": "square",
                            fill: _vm.fill,
                            "empty-fill": "#d1d1d1",
                            "animation-start-value": 0.0,
                            "start-angle": -1.57,
                            "insert-mode": "append",
                            thickness: 3,
                            "show-percent": true,
                          },
                        },
                        [
                          _c("p", [
                            _vm._v(_vm._s(_vm._f("translate")("general_over"))),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "indicators" }, [
                    _c("div", { staticClass: "odd" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("translate")("general_under"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "even" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("translate")("general_over"))),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _c("div", { staticClass: "number-bet-stats" }, [
      _c("div", { staticClass: "drawn-numbers" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm._f("uppercase")(_vm._f("translate")("general_hot_numbers"))
              ) +
              " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "balls" },
          _vm._l(_vm.mostDrawnNumbers, function (value, key) {
            return _c(
              "div",
              {
                key: key,
                staticClass: "ball",
                class: [
                  { active: true },
                  "color-border-" + _vm.getBallColor(value),
                ],
              },
              [
                _vm._v(" " + _vm._s(value.ball) + " "),
                _c("div", { staticClass: "count" }, [
                  _vm._v(" " + _vm._s(value.count) + " "),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "drawn-numbers" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm._f("uppercase")(_vm._f("translate")("general_cold_numbers"))
              ) +
              " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "balls" },
          _vm._l(_vm.leastDrawnNumbers, function (value, key) {
            return _c(
              "div",
              {
                key: key,
                staticClass: "ball",
                class: [
                  { active: true },
                  "color-border-" + _vm.getBallColor(value),
                ],
              },
              [
                _vm._v(" " + _vm._s(value.ball) + " "),
                _c("div", { staticClass: "count" }, [
                  _vm._v(" " + _vm._s(value.count) + " "),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
    _c("div", { staticClass: "color-bet-stats" }, [
      _c("div", { staticClass: "bet-stats" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(
            _vm._s(
              _vm._f("uppercase")(
                _vm._f("translate")("general_color_frequency")
              )
            )
          ),
        ]),
        _c(
          "div",
          { staticClass: "colors" },
          _vm._l(_vm.firstBallColor, function (value, key, index) {
            return _c("div", { key: index, staticClass: "color" }, [
              _c("div", { staticClass: "color-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("capitalize")(
                        _vm._f("translate")("luckysix_color_" + index)
                      )
                    ) +
                    " "
                ),
              ]),
              _c(
                "div",
                { staticClass: "card", class: "color-group-" + index },
                [_vm._m(0, true)]
              ),
              _c("div", { staticClass: "color-count" }, [
                _vm._v(_vm._s(value)),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-color" }, [
      _c("input", { attrs: { type: "checkbox" } }),
      _c("label"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
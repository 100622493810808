<template>
  <div class="special-bets-wrapper">
    <div class="main-special-bets">
      <div v-for="(specialBet, index) in specialBets"
           class="bet-wrapper"
           :key="index">
        <div v-if="specialBet.visible">
          <span class="bet-name"
                :title="specialBet.subtitle | translate">
            {{specialBet.subtitle | translate}}
          </span>
          <div class="actions"
               :class="{single: singleBetActive}">
            <div class="bet-outcome"
                 :class="{expanded: specialBet.visible}"
                 v-for="(outcome, key) in specialBet.outcomes"
                 :key="key">
              <multi-select-bet v-if="specialBet.multiselect"
                                :rules="specialBet.rules"
                                :outcome="outcome"
                                @change="handler(specialBet, $event)">
              </multi-select-bet>
              <button class="button"
                      :class="{active: outcome.active}"
                      v-if="!specialBet.multiselect"
                      :key="key"
                      @click="handler(specialBet, outcome)">
              <span>
                {{outcome.title | translate}}
              </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  cloneDeep,
  filter,
  remove,
  each,
  isNil,
} from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { audio } from '@nsoft/games-sdk-js/src/utility/index';
import MultiSelectBet from './MultiSelectBet';

export default {
  name: 'GameSpecialBets',
  components: {
    MultiSelectBet,
  },
  data() {
    return {
      allColorsState: false,
      specialBet: {
        outcomes: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      'specialBets',
      'bet',
      'bets',
      'rules',
      'allColorsSelected',
      'selectedBet',
      'isAudioOn',
      'preball',
      'bettingLocked',
    ]),
    singleBetActive() {
      const single = filter(this.specialBets, (bet) => bet.visible);
      return single.length === 1 && single[0].id === 5;
    },
  },
  methods: {
    ...mapActions([
      'resetBets',
      'resetBet',
      'resetSpecialBets',
      'createBet',
      'generateRandomNumbers',
      'toggleColors',
      'selectAllColors',
      'enableBetting',
      'setPreball',
    ]),
    handler(bet, outcome) {
      if (this.bettingLocked) return;
      this.selectBet(bet, outcome);
      if (this.isAudioOn) {
        if (outcome.active) {
          audio.playSound('select');
        } else {
          audio.playSound('deselect');
        }
      }
    },
    selectBet(bet, outcome) {
      this.selectAllColors(false);
      this.setPreball(false);
      const selectedOutcome = outcome;
      const activeOutcomes = filter(bet.outcomes, (o) => o.active);
      if (activeOutcomes.length >= this.rules.multiselect.maxOutcomeSelection.value) {
        selectedOutcome.active = false;
      } else {
        selectedOutcome.active = !selectedOutcome.active;
      }
      this.specialBet = cloneDeep(bet);
      this.specialBet.outcomes = selectedOutcome.active ? this.updateOutcomes(selectedOutcome) : [];
      this.specialBet.subtitle = this.$options.filters.translate(this.specialBet.subtitle);
      this.specialBet.stake = this.rules.minBetAmount.value;
      this.createBet(this.specialBet);
      this.toggleBet(this.specialBet);

      if (bet.multiselect) {
        this.multiSelectBet(bet, selectedOutcome);
      }

      this.checkBetRules(this.specialBet);
    },
    updateOutcomes(outcome) {
      this.specialBet.outcomes = [];
      this.specialBet.outcomes.push(outcome);
      return this.specialBet.outcomes;
    },
    checkBetRules(bet) {
      let rule;
      let enable = true;

      if (bet.multiselect) {
        const outcomeLen = this.bet.outcomes.length;
        rule = this.rules.multiselect;

        if (outcomeLen === rule.edgeCaseSelection.value
          || outcomeLen > rule.maxOutcomeSelection.value) {
          enable = false;
        }
      }

      if (!bet.outcomes.length) enable = false;
      this.enableBetting(enable);
    },
    toggleBet(bet) {
      if (bet.id && !isNil(bet.outcomes[0])) {
        for (let i = 0; i < this.specialBets.length; i += 1) {
          const specialBet = this.specialBets[i];

          for (let j = 0; j < specialBet.outcomes.length; j += 1) {
            const outcome = specialBet.outcomes[j];
            if (bet.id === 5) {
              outcome.active = specialBet.id === bet.id && outcome.active;
            } else {
              outcome.active = specialBet.id === bet.id && bet.outcomes[0].id === outcome.id;
            }
          }
        }
      }
    },
    multiSelectBet(bet, selectedOutcome) {
      this.specialBet.outcomes = filter(bet.outcomes, (outcome) => outcome.active);
      const maxSelection = this.rules.multiselect.maxOutcomeSelection;

      if (this.specialBet.outcomes.length > maxSelection.value) {
        remove(this.specialBet.outcomes, (outcome) => outcome.id === selectedOutcome.id);
      }

      this.createBet(this.specialBet);
    },
  },
  watch: {
    selectedBet(bet) {
      const betItem = cloneDeep(bet);
      const specialBet = filter(this.specialBets,
        (specialItem) => specialItem.id === betItem.id)[0];
      betItem.outcomes = [];
      if (specialBet) {
        if (betItem.type !== 'Standard' && betItem.type !== 'SYSTEM') {
          each(specialBet.outcomes, (outcome) => {
            each(betItem.title, (value) => {
              if (value.title) {
                if (value.title === outcome.title) {
                  betItem.outcomes.push(outcome);
                  this.selectBet(betItem, outcome);
                }
              }
            });
          });
        }
      }
    },
    preball(value) {
      if (value && this.specialBet.outcomes.length) {
        this.specialBet.outcomes = [];
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .special-bets-wrapper {

    &.disabled {
      opacity: .4;
    }

    .main-special-bets {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      align-content: space-between;
      text-align: center;

      .bet-wrapper {
        flex-grow: 1;
        width: 45%;
        margin-right: 16px;
        margin-top: 16px;

        &:nth-child(odd) {
          margin-right: 0;
        }

        &:first-child {
          margin-top: 0;
        }

        .actions {
          display: flex;
          align-items: center;
          justify-content: center;
          float: left;
          width: 100%;

          &.single {
            flex-wrap: wrap;
          }

          .bet-outcome {
            flex-grow: 1;
            margin: 4px;
            display: flex;

            .button {
              transition: all .5s ease;

              .checkbox {
                background-color: var(--checkbox-bg);
              }
            }

            &.expanded {
              width: 20%;
            }
          }

          .button {
            flex-grow: 1;

            &:last-child {
              margin-right: 0;
            }
          }

          .checkbox-button {
            width: auto;
          }
        }

        &:first-child {
          width: 100%;
        }

        .bet-name {
          float: left;
          width: 100%;
          margin-bottom: 8px;
          font-size: 0.875em;
          color: var(--text-primary-2);
          @include truncate;
        }
      }
    }
  }
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isGameLoaded
    ? _c(
        "div",
        { staticClass: "layout-compact" },
        [
          _c("div", { staticClass: "main-area" }, [
            _c(
              "div",
              { staticClass: "header" },
              [_c("GamesHeader", { attrs: { player: _vm.player } })],
              1
            ),
            _c(
              "div",
              { staticClass: "visualization", style: _vm.mobileVisualization },
              [_c("Visualization")],
              1
            ),
            _c(
              "div",
              { staticClass: "betting-part", style: _vm.modalOpenedStyle },
              [
                _c("Betting"),
                _c(
                  "stake-fade-transition",
                  [
                    _vm.showStakeBox
                      ? _c("StakeBox", {
                          attrs: {
                            showPayment: _vm.isMob,
                            predefinedStakes: _vm.predefinedStakeValues,
                            showActions: _vm.isMob,
                          },
                          on: { close: _vm.toggleStakeBox },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showBetslip,
                        expression: "showBetslip",
                      },
                    ],
                    staticClass: "betslip-component",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "betslip-header" },
                      _vm._l(_vm.ticketWrapperTabs, function (tab, key) {
                        return _c(
                          "div",
                          {
                            key: key,
                            staticClass: "header-item",
                            class: {
                              active: key === _vm.selectedTicketTab,
                              disabled: tab.disabled,
                            },
                            attrs: { disabled: tab.disabled },
                            on: {
                              click: function ($event) {
                                return _vm.selectTicketTab(key)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("translate")(tab.name)) +
                                    " "
                                ),
                                tab.indicator ? _c("BetsCounter") : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "betslip-content" },
                      [
                        _vm.selectedTicketTab === 0 && !_vm.showStakeBox
                          ? _c("ExpandedBetslip", {
                              attrs: { bettingLocked: _vm.bettingLocked },
                            })
                          : _vm._e(),
                        _vm.selectedTicketTab === 1 && !_vm.showStakeBox
                          ? _c("LastTickets", {
                              attrs: {
                                tickets: _vm.lastTicketsData,
                                isMob: _vm.isMob,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "info-bar" }),
            !_vm.showModalStats
              ? _c(
                  "div",
                  { staticClass: "ticket-area" },
                  [_c("TicketWrapper")],
                  1
                )
              : _vm._e(),
            _vm.showModalStats
              ? _c("div", { staticClass: "modal-stats" }, [
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "header" }, [
                      _c("div", { staticClass: "info" }, [
                        _c("div", { staticClass: "product-info" }, [
                          _vm._v("Lucky Six"),
                        ]),
                        _c("div", { staticClass: "round-info" }, [
                          _vm._v(
                            _vm._s(_vm._f("translate")("general_round")) +
                              " " +
                              _vm._s(_vm.round - 1)
                          ),
                        ]),
                      ]),
                      _c("i", {
                        staticClass: "icon icon-close-a",
                        on: {
                          click: function ($event) {
                            return _vm.toggleStats()
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "body" }, [
                      _c(
                        "div",
                        { staticClass: "tabs" },
                        _vm._l(_vm.eventDetailsTabs, function (tab, key) {
                          return _c(
                            "div",
                            {
                              key: key,
                              staticClass: "tab",
                              class: [
                                { active: tab.name === _vm.selectedTab.name },
                                { disabled: tab.disabled },
                              ],
                              attrs: { disabled: tab.disabled },
                              on: {
                                click: function ($event) {
                                  return _vm.selectTab(tab)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("translate")(tab.name)) +
                                  " "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        { staticClass: "tab-content" },
                        [_c(_vm.selectedTab.component, { tag: "component" })],
                        1
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "ticket-history-modal" },
            [
              _vm.showTicketHistory && _vm.player.loggedIn
                ? _c(
                    "Modal",
                    {
                      attrs: { open: _vm.showTicketHistory },
                      on: {
                        close: function ($event) {
                          _vm.showTicketHistory = false
                        },
                      },
                    },
                    [
                      _c("h3", { attrs: { slot: "header" }, slot: "header" }),
                      _c("TicketsHistory", {
                        attrs: { slot: "body" },
                        on: {
                          close: function ($event) {
                            _vm.showTicketHistory = false
                          },
                        },
                        slot: "body",
                      }),
                      _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ticket-details" },
            [
              _vm.showTicketDetails && _vm.player.loggedIn
                ? _c(
                    "Modal",
                    {
                      staticClass: "ticket-details",
                      attrs: { open: _vm.showTicketDetails },
                      on: {
                        close: function ($event) {
                          _vm.showTicketDetails = false
                        },
                      },
                    },
                    [
                      _c("h3", { attrs: { slot: "header" }, slot: "header" }),
                      _c("TicketDetails", {
                        attrs: { slot: "body", ticket: _vm.ticket },
                        on: {
                          close: function ($event) {
                            _vm.showTicketDetails = false
                          },
                        },
                        slot: "body",
                      }),
                      _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "cancel-ticket" },
            [
              _vm.showCancelTicket && _vm.player.loggedIn
                ? _c(
                    "Modal",
                    {
                      staticClass: "cancel-ticket",
                      attrs: { open: _vm.showCancelTicket },
                      on: {
                        close: function ($event) {
                          _vm.showCancelTicket = false
                        },
                      },
                    },
                    [
                      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("translate")("general_cancel_ticket")
                            ) +
                            " "
                        ),
                      ]),
                      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("translate")(
                                "general_cancel_ticket_confirm"
                              )
                            ) +
                            " " +
                            _vm._s(_vm.ticket.id) +
                            " "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.showCancelTicket = false
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("translate")("general_close")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "button",
                            { on: { click: _vm.approveTicketCancel } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("translate")("general_accept")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "quick-pick" },
            [
              _vm.showQuickPick
                ? _c(
                    "Modal",
                    {
                      attrs: { open: _vm.showQuickPick },
                      on: {
                        close: function ($event) {
                          _vm.showQuickPick = false
                        },
                      },
                    },
                    [
                      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                        _vm._v(_vm._s(_vm._f("translate")("general_random"))),
                      ]),
                      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                        _c(
                          "div",
                          { staticClass: "pick" },
                          _vm._l(_vm.pickNumbers, function (pick, index) {
                            return _c("Radio", {
                              key: index,
                              attrs: {
                                id: pick.value,
                                label: pick.label,
                                subLabel: pick.subLabel,
                              },
                              on: { change: _vm.setRandomNumbers },
                            })
                          }),
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.showQuickPick = false
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("translate")("general_close")) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "quick-pick" },
            [
              _vm.showFutureBets && _vm.isMob
                ? _c(
                    "Modal",
                    {
                      attrs: { open: _vm.showFutureBets },
                      on: {
                        close: function ($event) {
                          _vm.showFutureBets = false
                        },
                      },
                    },
                    [
                      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                        _vm._v(
                          _vm._s(_vm._f("translate")("general_future_bet"))
                        ),
                      ]),
                      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                        _c(
                          "div",
                          { staticClass: "pick" },
                          _vm._l(_vm.maxFutureBets, function (item, index) {
                            return _c("Radio", {
                              key: index,
                              attrs: { id: item },
                              on: { change: _vm.setFutureBets },
                            })
                          }),
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.showFutureBets = false
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("translate")("general_close")) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.player.id ? _c("FullScreen") : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue';
import Router from 'vue-router';
import Expanded from '@/layouts/Expanded';
import Home from '@/layouts/Home';
import Statistics from '@/components/web/drawDetails/Statistics';
import Results from '@/components/web/drawDetails/Results';
import DrawDetails from '@/components/web/drawDetails/DrawDetailsWrapper';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'web',
      component: Home,
    },
    {
      path: '/draw-details',
      name: 'drawDetails',
      component: DrawDetails,
    },
    {
      path: '/statistics',
      name: 'statistics',
      component: Statistics,
    },
    {
      path: '/results',
      name: 'results',
      component: Results,
    },
    {
      path: '/tickets-history',
      name: 'ticketsHistory',
      component: Expanded,
    },
  ],
});

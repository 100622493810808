<template>
  <div>
    <button class="ball"
            v-for="(ball, index) in balls"
            :key="index"
            :class="[{'active': ball.active}, `color-border-${ball.colorId}`]"
            @mousedown="handler(ball)">
      {{ball.number}}
    </button>
  </div>
</template>

<script>
import {
  each,
  filter,
  find,
  every,
  remove,
  upperFirst,
} from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { helpers, audio } from '@nsoft/games-sdk-js/src/utility/index';

const n = 6;

export default {
  name: 'GameBall',
  data() {
    return {
      ball: {
        id: null,
        number: null,
        active: null,
      },
      systemBet: {
        id: 0,
        title: [],
        subtitle: 'Standard',
        type: 'Standard',
        odds: null,
        outcomes: [],
        stake: 1,
        combinations: 1,
      },
      standardBet: {
        id: 0,
        title: [],
        subtitle: 'Standard',
        type: 'Standard',
        odds: null,
        outcomes: [],
        stake: 1,
      },
      preballBet: {
        id: 10,
        title: [],
        subtitle: 'general_preball',
        type: 'SPECIAL',
        odds: 8,
        outcomes: [],
        stake: 1,
      },
    };
  },
  computed: {
    ...mapGetters([
      'totalBalls',
      'balls',
      'colors',
      'colorGroup',
      'betsModel',
      'bet',
      'randomNumbers',
      'allColorsSelected',
      'rules',
      'preball',
      'colorGroupValue',
      'selectedBet',
      'editMode',
      'isAudioOn',
      'bettingLocked',
      'bets',
    ]),
  },
  methods: {
    ...mapActions([
      'createBalls',
      'resetBets',
      'createBet',
      'updateBet',
      'updateBets',
      'enableBetting',
      'resetBalls',
      'selectAllColors',
      'selectColorGroup',
      'resetColorGroups',
      'setPreball',
      'generateRandomNumbers',
    ]),
    handler(ball) {
      if (this.bettingLocked) return;
      this.selectBall(ball);
      if (this.isAudioOn) {
        if (this.ball.active) {
          audio.playSound('select');
        } else {
          audio.playSound('deselect');
        }
      }
    },
    setDefaultBallColor(id) {
      if (id) {
        this.ball.defaultColorId = (id - 1) % 8;
      }
    },
    setBallColor(number) {
      if (number) {
        const index = (number - 1) % 8;
        this.ball.color = this.colors[index].title;
        this.ball.colorId = index;
      }
    },
    selectBall(ball, color) {
      this.selectAllColors(false);
      this.ball = ball;
      this.ball.active = !ball.active;
      this.systemBet.outcomes = this.getOutcomes();
      this.standardBet.outcomes = this.getOutcomes();
      this.standardBet.subtitle = color ? color.subtitle
        : upperFirst(this.bets[0].name.toLowerCase());

      if (this.systemBet.outcomes.length > this.rules.minBallSelection.value) {
        this.createSystemBet();
        return;
      }

      this.checkColorGroup();
      this.createBet(this.standardBet);
      this.checkBetRules(this.standardBet);

      if (this.preball) {
        if (this.standardBet.outcomes.length > 1) {
          this.setPreball(false);
        } else {
          this.createPreballBet();
        }
      }
    },
    checkColorGroup() {
      if (this.standardBet.outcomes[0]) {
        const firstSelectedColor = this.standardBet.outcomes[0].colorId;
        const colorGroup = every(this.standardBet.outcomes, { colorId: firstSelectedColor });
        if (colorGroup && this.standardBet.outcomes.length === this.rules.minBallSelection.value) {
          this.standardBet.subtitle = this.colors[firstSelectedColor].subtitle;
          this.selectColorGroup(firstSelectedColor);
        } else {
          this.resetColorGroups();
        }
      }
    },
    createPreballBet() {
      this.preballBet.outcomes = this.bet.outcomes;
      this.preballBet.subtitle = `${this.$options.filters.translate('general_preball')}`;

      if (this.preball && this.bet.outcomes.length) {
        this.enableBetting(true);
        this.createBet(this.preballBet);
      }
    },
    createSystemBet() {
      this.checkMaxBallRule();
      this.systemBet.id = Number(this.systemBet.outcomes.length - n);
      this.systemBet.subtitle = `${this.$options.filters.translate('general_system')}
        ${n}/${this.systemBet.outcomes.length}`;
      for (let i = 7; i <= 10; i += 1) {
        if (this.systemBet.id === i - n) {
          this.systemBet.combinations = helpers.getCombinations(i, n);
        }
      }
      this.createBet(this.systemBet);
      this.checkColorGroup();
    },
    checkMaxBallRule() {
      if (this.systemBet.outcomes.length > this.rules.maxBallSelection.value) {
        this.ball.active = false;
        remove(this.systemBet.outcomes, (outcome) => outcome.number === this.ball.number);
      }
    },
    checkBetRules(bet) {
      let enable = true;
      const outcomesLen = bet.outcomes.length;

      if (outcomesLen < this.rules.minBallSelection.value) {
        enable = false;
      }

      this.enableBetting(enable);
    },
    getOutcomes() {
      const activeBalls = filter(this.balls, (ball) => ball.active);
      return activeBalls.map((ball) => ball);
    },
    createBall(balls) {
      each(balls, (value) => {
        this.ball = value;
        this.setDefaultBallColor(this.ball.id);
        this.setBallColor(this.ball.number);
      });
    },
    filterRandomBalls(val) {
      return filter(this.balls, (ball) => ball.number === val)[0];
    },
    activateBall(outcome) {
      this.ball = find(this.balls, { number: outcome });
      this.ball.active = true;
    },
  },
  watch: {
    selectedBet(bet) {
      const betValue = String(bet.value);
      const betValueList = betValue.split(',');
      if (bet.title) {
        if (upperFirst(bet.type.toLowerCase()) === 'Standard'
          || bet.title.length > this.rules.minBallSelection.value || bet.id === 10) {
          this.resetBalls();
          each(betValueList, (num) => {
            const ball = this.filterRandomBalls(Number(num));
            this.selectBall(ball);
          });
        }
      }
    },
    allColorsSelected(value) {
      if (value) this.resetBalls();
    },
    'colorGroup.outcomes': function activateBall(outcomes) {
      this.resetBalls();
      each(outcomes, (outcome) => {
        this.activateBall(outcome);
      });
    },
    randomNumbers(numbers) {
      if (numbers) {
        this.resetBalls();
        each(numbers, (val) => {
          const ball = this.filterRandomBalls(val);
          this.selectBall(ball);
        });
      }
    },
    preball() {
      this.createPreballBet();
    },
    colorGroupValue(value) {
      if (value) {
        const ballsColorId = filter(this.balls,
          (ball) => ball.colorId === this.colorGroupValue.colorId);
        each(ballsColorId, (ball) => {
          this.selectBall(ball, value);
        });
      }
    },
  },
  created() {
    this.createBalls(this.totalBalls);
    this.createBall(this.balls);
  },
};
</script>

<style lang="scss" scoped>
  @media (min-width: 306px) and (max-width: 360px) {
    .ball {
      width: 38px;
      height: 38px;
      margin: 0 3px 3px 0;
      font-size: 0.875em;
    }
  }
  @media (min-width: 360px) and (max-width: 659px) {
    .ball {
      margin: 0 3px 3px 0;
    }
  }

  @media (min-width: 660px) {
    .ball {
      margin: 0 8px 8px 0;
    }
  }
</style>

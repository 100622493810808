<template>
  <div class="overlay-wrapper"
       v-if="errorOverlay.active">
    <div class="overlay-message-box">
      <div class="error-icon">
        <i class="icon icon-void"></i>
      </div>
      <div class="message">
        {{errorOverlay.message}}
      </div>
      <div class="close-overlay">
        <i class="icon icon-close-a"
           @click="closeOverlay">
        </i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ErrorOverlay',
  computed: {
    ...mapGetters([
      'errorOverlay',
    ]),
  },
  methods: {
    ...mapActions([
      'closeErrorOverlay',
    ]),
    closeOverlay() {
      this.closeErrorOverlay();
    },
  },
};
</script>

<style lang="scss">
  .overlay-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(18, 19, 20, 0.7);

    .overlay-message-box {
      height: 36px;
      width: 100%;
      background-color: #e53935;
      position: absolute;
      top: 0;
      color: #efefef;
      line-height: 36px;
      padding: 0 8px;
      font-family: 'Roboto', sans-serif;
      font-size: 12px;

      .error-icon {
        float: left;
        margin-right: 8px;
        line-height: 38px;
      }

      .message {
        float: left;
      }

      .close-overlay {
        float: right;
        cursor: pointer;
      }
    }
  }
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "special-bets-wrapper" }, [
    _c(
      "div",
      { staticClass: "main-special-bets" },
      _vm._l(_vm.specialBets, function (specialBet, index) {
        return _c("div", { key: index, staticClass: "bet-wrapper" }, [
          specialBet.visible
            ? _c("div", [
                _c(
                  "span",
                  {
                    staticClass: "bet-name",
                    attrs: { title: _vm._f("translate")(specialBet.subtitle) },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("translate")(specialBet.subtitle)) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "actions",
                    class: { single: _vm.singleBetActive },
                  },
                  _vm._l(specialBet.outcomes, function (outcome, key) {
                    return _c(
                      "div",
                      {
                        key: key,
                        staticClass: "bet-outcome",
                        class: { expanded: specialBet.visible },
                      },
                      [
                        specialBet.multiselect
                          ? _c("multi-select-bet", {
                              attrs: {
                                rules: specialBet.rules,
                                outcome: outcome,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handler(specialBet, $event)
                                },
                              },
                            })
                          : _vm._e(),
                        !specialBet.multiselect
                          ? _c(
                              "button",
                              {
                                key: key,
                                staticClass: "button",
                                class: { active: outcome.active },
                                on: {
                                  click: function ($event) {
                                    return _vm.handler(specialBet, outcome)
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("translate")(outcome.title)
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="ticket-details-wrapper">
    <div class="header">
      <div class="product-title">
        <span>{{productTitle}}</span>
      </div>
      <div class="ticket-id">
        <span>{{ticketIdLabel}}{{ticket.id}}</span>
        <i class="icon icon-copy" @click="copyTicketId(ticket.id)"></i>
      </div>
      <div class="separator">|</div>
      <div class="ticket-date">
        <span>{{ticket.createdAt}}</span>
      </div>
    </div>
    <div class="body">
      <Scrollable>
        <div class="bets-table">
          <div class="columns">
            <div class="column wide">
              {{'general_bet' | translate | uppercase}}
            </div>
            <div class="column">
              {{'general_odds' | translate | uppercase}}
            </div>
            <div class="column">
              {{'general_round' | translate | uppercase}}
            </div>
            <div class="column wide">
              {{betTypeLabel | uppercase}}
            </div>
            <div class="column">
              {{'general_stake' | translate | uppercase}}
            </div>
            <div class="column">
              {{'general_winning' | translate | uppercase}}
            </div>
            <div class="column"></div>
          </div>
          <div class="rows" v-if="!mobile">
            <div class="row"
                 v-for="(bet, key) in ticket.bets"
                 :key="key">
              <div class="bet-item wide" v-if="isOutcomeArray(bet.outcome.title)">
              <span v-for="(item, index) in bet.outcome.title"
                    :class="{active: isBallDrawn(bet, item),
                    'played-numbers': [0, 1, 2, 3, 4].includes(bet.type)}"
                    :key="index">
                {{item | translate}}
              </span>
              </div>
              <div class="bet-item wide"
                   v-if="!isOutcomeArray(bet.outcome.title)">
                {{bet.outcome.title | translate}}
              </div>
              <div class="bet-item">{{bet.odd ? bet.odd.toFixed(2) : '-'}}</div>
              <div class="bet-item">{{bet.eventId}}</div>
              <div class="bet-item wide">{{bet.typeValue}}</div>
              <div class="bet-item">{{bet.amount.toFixed(2)}}</div>
              <div class="bet-item">{{bet.winnings ? bet.winnings.toFixed(2) : '-'}}</div>
              <div class="bet-item">
                <i class="icon" :class="`
              icon-${getStatusIcon(bet)}
              status-${bet.status.value.toLowerCase()}`">
                </i>
              </div>
            </div>
          </div>
          <div class="rows mobile" v-if="mobile">
            <div class="row"
                 v-for="(bet, key) in ticket.bets"
                 :key="key">
              <div class="bet-item" v-if="mobile">
                <div class="subtitle">
                <span class="normal">
                  {{'general_round' | translate | capitalize}}{{': '}}
                </span>
                  <span class="bolded">
                  {{bet.eventId}}
                </span> |
                  <span class="normal">
                  {{bet.typeValue | translate}}
                </span>
                  <span class="status" :class="`status-${bet.status.value.toLowerCase()}`">
                  {{bet.status.name.toUpperCase()}} {{bet.winnings ? bet.winnings.toFixed(2) : ''}}
                </span>
                </div>
                <div class="title"
                     v-if="isOutcomeArray(bet.outcome.title)">
                <span class="played-numbers"
                      v-for="(value, key) in formatOutcome(bet.outcome.title)"
                      :class="{active: isBallDrawn(bet, value)}"
                      :key="key">
                  {{value | translate}}
                </span>
                </div>
                <div class="title"
                     v-if="!isOutcomeArray(bet.outcome.title)">
                  {{bet.outcome.title | translate}}
                </div>
                <div class="odd">
                <span class="normal">
                  {{bet.odd ? bet.odd.toFixed(2) : ''}}
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Scrollable>
      <div class="payment-info">
        <div class="row">
          <div class="payin-info">
            <span class="label">
              {{'general_pay_in_details' | translate}}
            </span>
            <span class="value">
              {{ticket.payin.toFixed(2)}}
            </span>
          </div>
          <div class="tax-info"
               v-if="ticket.payinTax">
            <span class="label">
              {{'general_payin_tax' | translate}}
            </span>
            <span class="value">
              {{ticket.payinTax.toFixed(2)}}
            </span>
          </div>
        </div>
        <div class="row winning">
            <span class="label">
              {{'general_winning' | translate}}
            </span>
          <span class="value">
              {{ticket.payout.toFixed(2)}}
            </span>
        </div>
        <div class="row bonus"
             v-if="ticket.superBonus">
          <div class="super-bonus">
            <span class="label">
              <i class="icon icon-ajs"></i>
              {{ticket.superBonus.name}}
              {{` +${ticket.superBonus.amount.toFixed(2)}`}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="left">
        <button @click="cancelTicket(ticket)"
                v-if="ticketOpen && appConfig.company.ticketCancelAllowed">
          {{'general_cancel_ticket' | translate}}
        </button>
      </div>
      <div class="right">
        <button @click="rebet(ticket)">
          {{'general_rebet' | translate}}
        </button>
        <button @click="$emit('close')">
          {{'general_close' | translate}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import eventBus from '@/utility/eventBus';
import { isArray } from 'lodash';
import Scrollable from '@nsoft/games-sdk-js/src/components/Scrollable';

export default {
  name: 'TicketDetails',
  components: {
    Scrollable,
  },
  props: {
    ticket: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mobile: window.innerWidth <= 500,
    };
  },
  computed: {
    ...mapGetters([
      'appConfig',
    ]),
    ticketIdLabel() {
      return `${this.$options.filters.translate('general_ticket')} ID: `;
    },
    betTypeLabel() {
      return `${this.$options.filters.translate('general_type')}`;
    },
    ticketOpen() {
      return this.ticket.status.value === 'OPEN';
    },
    productTitle() {
      const title = this.ticket.product.split(/(?=[A-Z])/);
      return `${title[0]} ${title[1]}`;
    },
  },
  methods: {
    isOutcomeArray(outcome) {
      return isArray(outcome);
    },
    rebet(ticket) {
      eventBus.$emit('rebet', ticket);
      this.$emit('close');
    },
    cancelTicket(ticket) {
      eventBus.$emit('cancelTicket', ticket);
    },
    formatOutcome(outcome) {
      let tempOutcome = outcome;
      if (tempOutcome.constructor === Array) {
        return tempOutcome;
      }

      if (tempOutcome.constructor === Number) {
        tempOutcome = String(outcome);
      }
      return tempOutcome.split(',');
    },
    isBallDrawn(bet, value) {
      if (bet.outcome.value.length > 1) {
        return bet.eventValue.split(',').includes(value);
      }

      return false;
    },
    getStatusIcon(data) {
      let icon = null;
      switch (data.status.value.toLowerCase()) {
        case 'lost':
          icon = 'close';
          break;
        case 'closed':
          icon = 'void';
          break;
        case 'won':
        case 'payedout':
          icon = 'check-a';
          break;
        case 'open':
        case 'pending':
        case 'in_play':
          icon = 'clock';
          break;
        default:
          icon = 'close';
          break;
      }

      return icon;
    },
    copyTicketId(id) {
      const input = document.createElement('input');
      input.value = id;
      input.style.opacity = '0';
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
    },
  },
};
</script>

<style lang="scss" scoped>
  .ticket-details-wrapper {
    .header {
      float: left;
      width: 100%;

      .product-title {
        float: left;
        width: 100%;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 2px;
        color: var(--text-primary-1);
      }

      .separator {
        float: left;
        margin: 0 5px;
        color: var(--text-primary-2);
        opacity: .6;
      }

      .ticket-id,
      .ticket-date {
        line-height: 21px;
        float: left;
        color: var(--text-primary-2);
        opacity: .6;
        font-size: 14px;
      }

      .icon-copy {
        margin: 0 10px;
        cursor: pointer;

        &:hover {
          color: var(--text-primary-1);
        }
      }
    }

    .body {
      float: left;
      width: 100%;
      margin-top: 24px;
      z-index: 1;

      .bets-table {
        float: left;
        width: 100%;
        .columns {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 8px;
          padding: 0 16px;

          .column {
            font-size: 12px;
            color: var(--text-primary-2);
            opacity: .6;
            width: calc(100% / 6);
            text-align: center;

            &.wide {
              width: 45%;
            }

            &:first-child {
              text-align: left;
            }
          }
        }
        .rows {
          .row {
            display: flex;
            justify-content: space-between;
            background-color: var(--card-section-bg);
            padding: 0 16px;
            color: var(--text-primary-1);
            margin-bottom: 2px;
            border-radius: 3px;
            line-height: 32px;

            .bet-item {
              width: calc(100% / 6);
              text-align: center;
              font-size: 14px;

              span {
                margin-right: 4px;
                float: left;
              }

              &:first-child {
                text-align: left;
              }

              &:last-child {
                text-align: right;
              }

              .played-numbers {
                color: var(--text-primary-3);

                &.active {
                  color: var(--text-primary-1);
                }
              }

              .odd {
                float: right;
              }

              .icon {
                font-size: 16px;
                &.status-lost {
                  color: var(--system-negative);
                }

                &.status-won, &.status-payedout {
                  color: var(--system-positive);
                }

                &.status-open,
                &.status-in_play,
                &.status-pending,
                &.status-closed {
                  color: var(--system-neutral);
                }
              }
            }

            .wide {
              width: 45%;
            }
          }
        }
      }

      .payment-info {
        float: left;
        width: 100%;
        margin-top: 8px;
        color: var(--text-primary-1);

        .row {
          background-color: var(--card-section-bg);
          border-radius: 3px;
          float: left;
          width: 100%;
          padding: 8px 16px;
          margin-bottom: 2px;

          &.winning {
            font-size: 14px;
          }
          &.bonus {
            background-color: var(--system-neutral);
            color: var(--system-neutral-text-color);
            margin-top: 8px;
            font-size: 12px;

            .icon {
              color: var(--system-neutral-text-color);
              position: relative;
              top: 1px;
              margin-right: 10px;
            }
          }

          .payin-info,
          .tax-info {
            color: var(--text-primary-2);
            opacity: .6;
            float: left;
            width: 100%;
          }

          .label {
            float: left;
          }

          .value {
            float: right;
          }

          .payin-info {
            font-size: 14px;
            margin-bottom: 2px;
          }

          .tax-info {
            font-size: 12px;
          }
        }
      }
    }

    .footer {
      float: left;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 27px;

      button {
        background: transparent;
        border: 0;
        outline: 0;
        font-size: 14px;
        color: var(--accent-bg, var(--brand-bg));
        text-transform: uppercase;
        cursor: pointer;
      }

      .right {
        button {
          margin-left: 50px;
        }
      }
    }
  }

  @media (min-width: 320px) and (max-width: 640px) {
    .ticket-details-wrapper {
      .header {
        float: left;
        width: 100%;
        padding: 8px 16px;
        position: absolute;
        top: 0;
        background: var(--bg-color-2);
      }
      .body {
        margin-top: 65px;
        background-color: var(--bg-color-2);
        padding-bottom: 75px;
        .bets-table {
          .columns {
            display: none;
          }
          .rows {
            .row {
              background-color: var(--card-bg);
              line-height: 35px;
              padding: 16px 16px;

              .bet-item {
                .title {
                  color: var(--text-primary-1);
                }
                .played-numbers {
                  color: var(--text-primary-3);

                  &.active {
                    color: var(--text-primary-1);
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }

        .payment-info {
          margin-top: 0;
        }

        .rows {
          .row {
            height: 68px;
            line-height: 1.2 !important;
            background-color: var(--card-section-bg);

            .bet-item {
              width: 100% !important;
              text-align: left !important;

              .status {
                margin-bottom: 3px;
                font-size: 12px;

                &.status-lost {
                  color: var(--system-negative);
                }

                &.status-won, &.status-payedout {
                  color: var(--system-positive);
                }

                &.status-open,
                &.status-in_play,
                &.status-pending,
                &.status-closed {
                  color: var(--system-neutral);
                }
              }

              .subtitle {
                top: 13px;
                font-size: 12px;
                color: var(--text-primary-2);
                width: 100%;
                margin-bottom: 3px;

                .bolded {
                  font-weight: 700;
                  color: var(--text-primary-1);
                }

                .status {
                  float: right;
                  font-weight: 500;
                }
              }

              .title {
                float: left;
                margin-right: 5px;
                font-size: 14px;
              }

              .payout {
                float: right;
                font-weight: 600;
                color: #efefef;
                font-size: 12px;
              }
            }
          }
        }
      }

      .footer {
        position: absolute;
        bottom: 0;
        background-color: var(--card-bg);
        border-top: 2px solid var(--tab-border-color);
        line-height: 73px;
        padding: 0 16px;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  @media (min-width: 641px) {
    .ticket-details-wrapper {
      .body {
        .rows {
          max-height: 25vh;
        }
      }
    }
  }
</style>

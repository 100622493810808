var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-header" }, [
    _c("div", { staticClass: "settings-section" }, [
      _vm.showBackButton
        ? _c("i", {
            staticClass: "icon icon-arrow-left",
            on: { click: _vm.returnToLobby },
          })
        : _vm._e(),
      _vm.showStats
        ? _c("i", {
            staticClass: "icon icon-info",
            on: {
              click: function ($event) {
                return _vm.toggleStats()
              },
            },
          })
        : _vm._e(),
      _vm.sound
        ? _c("i", {
            class: [_vm.audioIcon],
            on: {
              click: function ($event) {
                return _vm.handler()
              },
            },
          })
        : _vm._e(),
    ]),
    _vm.showBalance
      ? _c("div", { staticClass: "player-info" }, [
          _c("div", { staticClass: "balance" }, [
            _vm._v(_vm._s(_vm.balance) + " " + _vm._s(_vm.currency)),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bet-info-bar-wrapper" }, [
    _vm.bet.outcomes.length
      ? _c("div", { staticClass: "bet-info" }, [
          _c("span", { staticClass: "subtitle" }, [
            _vm._v(_vm._s(_vm.bet.subtitle)),
          ]),
          _c(
            "div",
            { staticClass: "title" },
            _vm._l(_vm.bet.title, function (value, key) {
              return _c("span", { key: key }, [
                _vm._v(" " + _vm._s(_vm._f("translate")(value.title)) + " "),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
    !_vm.bet.outcomes.length
      ? _c("div", { staticClass: "empty-message" }, [
          _c("i", { staticClass: "icon icon-info" }),
          _c("span", [
            _vm._v(_vm._s(_vm._f("translate")("general_ticket_empty_message"))),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
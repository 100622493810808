<template>
  <div class="compact-betting-body">
    <mq-layout :mq="['smallMax', 'mediumMax', 'largeMin', 'large', 'largeMax']">
      <div class="game-bets">
        <div class="main-bets">
          <selection-grid></selection-grid>
        </div>
        <div class="special-bets">
          <ShortcutBets/>
          <special-bets></special-bets>
        </div>
      </div>
    </mq-layout>
    <mq-layout :mq="['s', 'm', 'smallMin', 'l', 'xl']">
      <div class="tabs">
        <div class="tab" v-for="(tab, key) in tabs"
             :class="[{'active': tab.id === selectedTab}]"
             @click="selectTab(tab)"
             :key="key">
          {{tab.name | translate}}
        </div>
      </div>
      <div class="game-bets">
        <div class="tab-content"
             v-show="selectedTab === 0">
          <div class="main-bets">
            <selection-grid></selection-grid>
          </div>
          <ShortcutBets/>
        </div>
        <div class="tab-content"
             v-show="selectedTab === 1">
          <div class="special-bets">
            <special-bets></special-bets>
          </div>
        </div>
      </div>
    </mq-layout>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SelectionGrid from './SelectionGrid';
import ShortcutBets from './ShortcutBets';
import SpecialBets from './SpecialBets';

export default {
  name: 'CompactBettingBody',
  components: {
    SelectionGrid,
    ShortcutBets,
    SpecialBets,
  },
  data() {
    return {
      tabs: [
        {
          id: 0,
          name: 'games_tab_betting',
        },
        {
          id: 1,
          name: 'games_tab_betting_special',
        },
      ],
      selectedTab: 0,
    };
  },
  computed: {
    ...mapGetters([
      'ticket',
    ]),
  },
  methods: {
    ...mapActions([
      'resetBet',
      'resetSelection',
    ]),
    selectTab(tab) {
      this.selectedTab = tab.id;
    },
    clearSelection() {
      this.resetBet();
      this.resetSelection();
    },
  },
  watch: {
    'ticket.bets': function addedBet() {
      this.clearSelection();
    },
  },
};
</script>

<style lang="scss">
  .compact-betting-body {
    width: 100%;
    float: left;
    margin: 0 auto;
    border-radius: $border-radius;
    position: relative;

    .tabs {
      float: left;
      width: 100%;
      height: 40px;
      border-bottom: 2px solid var(--tab-border-color);
      background-color: var(--tab-bg, var(--card-bg));
      font-size: 14px;
      color: var(--tab-text-color, var(--text-primary-1));

      .tab {
        float: left;
        min-width: 24%;
        line-height: 38px;
        cursor: pointer;
        padding-left: 1em;
        text-align: center;
        background-color: var(--tab-bg, var(--card-bg));
        transition: all .5s ease;
        border-bottom: 2px solid var(--tab-border-color);

        &:hover {
          background-color: var(--tab-hover-bg);
        }

        &:active {
          background-color: var(--tab-pressed-bg);
        }

        &.active {
          border-bottom: 2px solid var(--accent-bg, var(--brand-bg));
          color: var(--accent-bg, var(--brand-bg));
        }
      }
    }

    .game-bets {
      width: 100%;
      height: 100%;
      float: left;
      padding: 16px 8px;
      background-color: var(--card-bg);

      .main-bets {
        width: 50%;
        height: 100%;
        float: left;
      }

      .special-bets {
        width: 50%;
        height: 100%;
        float: right;

        .shortcut-bets {
          flex-direction: row;
          margin-bottom: 16px;

          .button {
            margin-bottom: 8px;
          }
        }

        .main-special-bets {
          .bet-wrapper {
            margin-bottom: 24px;
            margin-top: 0;
            .actions {
              flex-wrap: nowrap;
            }
          }
        }
      }
    }
  }

  @media (min-width: 306px) and (max-width: 659px) {
    .tabs {
      .tab {
        width: 50%;
      }
    }

    .game-bets {
      .tab-content {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;

        .main-bets {
          width: 100%;
          order: 2;
        }

        .shortcut-bets {
          width: 100%;
          order: 1;
          margin-bottom: 8px;
        }

        .special-bets {
          width: 100%
        }
      }
    }
  }

  @media screen and (min-width: 660px) and (max-width: 1100px),
  (min-width: 1268px) and (max-width: 1483px) {
    .compact-betting-body {
      .tabs {
        display: block;
      }

      .game-bets {
        .tab-content {
          .main-bets {
            width: 410px;

            .main-bets-wrapper {
              .main-bets-balls {
                .ball {
                  margin: 0 11px 11px 0;
                }
              }
              .color-groups {
                .colors-wrap {
                  padding-right: 23px;
                }
              }
            }
          }
          .special-bets {
            width: 100%;
            float: left;
          }
        }
      }
    }
  }

  @media (min-width: 770px) and (max-width: 840px) {
    .compact-betting-body {
      .game-bets {
        .main-bets {
          width: 56%;

          .main-bets-wrapper {
            .main-bets-balls {
              .ball {
                margin: 0 11px 11px 0;
              }
            }
            .color-groups {
              .colors-wrap {
                padding-right: 23px;
              }
            }
          }
        }
        .special-bets {
          width: 44%;
        }
      }
    }
  }

  @media (min-width: 841px) {
    .compact-betting-body {
      .game-bets {
        .main-bets {
          width: 51%;

          .main-bets-wrapper {
            .main-bets-balls {
              .ball {
                margin: 0 11px 11px 0;
              }
            }
            .color-groups {
              .colors-wrap {
                // padding-right: 20px;
                padding-right: 24px;
              }
            }
          }
        }
        .special-bets {
          width: 49%;
        }
      }
    }
  }
</style>

<template>
  <div class="game-help">
    <div class="title">
      STANDARD BET - 6 NUMBERS OUT OF 35 (6/35)
    </div>
    <div class="description">
      There are 48 numbers in an electronic drawing machine and each number individually is set
      on one of the balls, which are then drawn at random. The player can choose and bet on 6
      numbers out of 48 numbers offered. If the player hits 6 numbers out of 35 numbers which
      are drawn in each round, he achieves a winning accordingly with the coefficient at which
      he hit the last number.
    </div>
    <div class="description">
      There are 8 groups that consist of 6 numbers each. Every group is marked by a specified
      color: red, green, blue, purple, brown, yellow, orange and black. Groups are organized
      in a following way:
      <ol>
        <li>Red group: 1, 9, 17, 25, 33, 41;</li>
        <li>Green group: 2, 10, 18, 26, 34, 42;</li>
        <li>Blue group: 3, 11, 19, 27, 35, 43;</li>
        <li>Purple group: 4, 12, 20, 28, 36, 44;</li>
        <li>Brown group: 5, 13, 21, 29, 37, 45;</li>
        <li>Yellow group: 6, 14, 22, 30, 38, 46;</li>
        <li>Orange group: 7, 15, 23, 31, 39, 47;</li>
        <li>Black group: 8, 16, 24, 32, 40, 48;</li>
      </ol>

      Winnings = Odd x Total Stake

      Coefficient s are awarded to numbers, starting from the sixth to the thirty-fifth
      generated number, as follows:

      <ul>
        <li>6th generated number, coefficient -  10 000</li>
        <li>7th generated number, coefficient - 7 500</li>
        <li>8th generated number, coefficient - 5 000</li>
        <li>9th generated number, coefficient - 2 500</li>
        <li>10th generated number, coefficient - 1 000</li>
        <li>11th generated number, coefficient - 500</li>
        <li>12th generated number, coefficient - 300</li>
        <li>13th generated number, coefficient - 200</li>
        <li>14th generated number, coefficient - 150</li>
        <li>15th generated number, coefficient - 100</li>
        <li>16th generated number, coefficient - 90</li>
        <li>17th generated number, coefficient - 80</li>
        <li>18th generated number, coefficient - 70</li>
        <li>19th generated number, coefficient - 60</li>
        <li>20th generated number, coefficient - 50</li>
        <li>21st generated number, coefficient - 40</li>
        <li>22nd generated number, coefficient - 30</li>
        <li>23rd generated number, coefficient - 25</li>
        <li>24th generated number, coefficient - 20</li>
        <li>25th generated number, coefficient - 15</li>
        <li>26th generated number, coefficient - 10</li>
        <li>27th generated number, coefficient - 9</li>
        <li>28th generated number, coefficient - 8</li>
        <li>29th generated number, coefficient - 7</li>
        <li>30th generated number, coefficient - 6</li>
        <li>31st generated number, coefficient - 5</li>
        <li>32nd generated number, coefficient - 4</li>
        <li>33rd generated number, coefficient - 3</li>
        <li>34th generated number, coefficient - 2</li>
        <li>35th generated number, coefficient - 1</li>
      </ul>

      Among 35 displayed numbers generated during a single round, there are 2 pictorial
      symbols as well (hereinafter: symbol). The symbol provides a player with a bonus
      coefficient  in the following cases:

      If 6 numbers chosen by the player are among 35 generated numbers and 1 of 6 hit
      lands on a field marked with a lucky symbol, coefficient on which the last hit
      number of the winning combination is drawn will be doubled.
      If 6 numbers chosen by the player are among 35 generated numbers and 2 of 6 hit
      numbers land on fields marked with lucky symbols, coefficient on which the last
      hit number of the winning combination is drawn will be tripled.
      The symbol position on the afore mentioned display results from a random selection
      and cannot be anticipated.
    </div>
    <div class="title">
      GAME OF 6 BALLS 1 COLOR (6/1)
    </div>
    <div class="description">
      According to the rules of this special game, 48 balls are divided into 8 groups by 6
      balls in the same color and the player can bet that 6 balls of the same color will
      be drawn. The same coefficients and rules are valid for the special games as for the
      standard game 6/35. The player can event bet on all 8 colors by pressing the
      ‘All colors’ button.
    </div>
    <div class="title">
      FIRST BALL EVEN/ODD NUMBER
    </div>
    <div class="description">
      According to the rules of this special game, the player can bet on whether the first
      ball drawn in a single round will be an odd or an even number. The coefficient on any
      two selections is 1.8.
    </div>
    <div class="title">
      MORE EVEN/ODD NUMBERS IN FIRST 5
    </div>
    <div class="description">
      According to the rules of this special game, the player can bet whether more even or
      odd numbers will be drawn in first 5 numbers in a single round. 35 numbers are drawn
      in each round, and on the selection of a more od or an even numbers in first 5 is 1.8.
    </div>
    <div class="title">
      SUM OF THE FIRST 5 NUMBERS (-122.5+)
    </div>
    <div class="description">
      According to the rules of this special game, the player can bet whether the sum of
      the first 5 (five) balls drawn in a single round will be less or more than 122.5.
      The coefficient on any of the two selections is 1.8.
    </div>
    <div class="title">
      A NUMERICAL VALUE OF THE FIRST BALL (-24.5+)
    </div>
    <div class="description">
      According to the rules of this special game, the player can bet whether the first ball
      drawn in a round will be less or more than 24.5. The coefficient on any of the two selections
      is 1.8.
    </div>
    <div class="title">
      THE NUMBER IN FIRST 5 BALLS
    </div>
    <div class="description">
      According to the rules of this special game, the player can bet whether the number will be
      drawn in first 5 balls. The coefficient on any number is 8.
    </div>
    <div class="title">
      COLOR OF THE FIRST BALL
    </div>
    <div class="description">
      This special game is played in the way that the player guesses color of the first ball which
      will be drawn from the electronic drawing machine. The player can bet on a single ticket
      1 (one), 2 (two) or 4 (four) colors when guessing color of the first ball from the machine.
      The coefficient on 1 (one) color is 7.2, on 2 (two) colors is 3.6, on 4 (four) colors is 1.8.
    </div>
    <div class="title">
      SYSTEM GAME FOR LUCKY SIX
    </div>
    <div class="description">
      According to the rules of this system game, the player can select max. 10 numbers out of
      48 offered, and in the following round in which 35 numbers will be drawn, he must hit
      6 numbers to make a gain. Possible systems are 6/7, 6/8, 6/9 and 6/10.

      Total bet on the betting slip is divided by the possible number of combinations, after
      which it is multiplied by the coefficient on which it was drawn. Minimal bet per combination
      is [minimal bet per combination amount] expect on 6/7 system ticket where a minimal bet is
      [minimal amount for system ticket].

      The number of combinations by the system is as follows:
      <ol>
        <li>For system 6/7 possible number of combinations is 7</li>
        <li>For system 6/8 possible number of combinations is 28</li>
        <li>For system 6/9 possible number of combinations is 84</li>
        <li>For system 6/10 possible number of combinations is 210</li>
      </ol>
    </div>
    <div class="title">
      FUTURE BET OPTION
    </div>
    <div class="description">
      A future bet option is an option which enables betting on the same number of 10 consecutive
      rounds in forward on a single betting slip, counting from the first upcoming round
      upon the bet. Special, standard and system games can be played on the basis of Future option.
    </div>
    <div class="title">
      BETSLIP CANCELLATION
    </div>
    <div class="description">
      The players are allowed to cancel their betslip up to [number of seconds] seconds
      before the end of the countdown. In case of future bets, the possibility of cancellation
      is up to [number of seconds] before the end of the countdown of the first round. In a
      later stage the cancellation of betslips is not possible.
    </div>
    <div class="title">
      CANCELLATION AND POSTPONEMENT OF THE ROUND
    </div>
    <div class="description">
      The organizer is not responsible for any interruption or postponement of the round,
      which occurs due to unforeseen circumstances, and whose consequences cannot be modified.
      Unforeseen circumstances include, but are not related only to: war events, armed conflicts,
      terrorist attacks, public disorder, natural disasters, and general electricity and
      Internet disconnections. In the event of unforeseen circumstances, the organizer reserves
      the right to disable betting on offered games.
    </div>
  </div>
</template>

<script>
export default {
  name: 'GameHelp',
};
</script>

<style lang="scss" scoped>
  .game-help {
    text-align: left;
    padding: 8px;
    font-family: 'Roboto', sans-serif;

    .title {
      color: var(--text-primary-1);
      font-weight: bold;
    }
    .description {
      line-height: 1.3;
      font-size: 14px;
      color: var(--text-primary-2);
      opacity: 0.6;
      margin-top: 8px;
      margin-bottom: 16px;

      ol, ul {
        padding-left: 40px;
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
</style>

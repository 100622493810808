export default {
  install: function install(Vue) {
    Vue.component('LocalizedLabel', {
      name: 'LocalizedLabel',
      functional: true,
      props: ['translation'],
      render(createElement, context) {
        const key = context.props.translation;
        const store = context.parent.$store;
        const translation = store.getters.localizedTranslations[key];
        return createElement(
          'span',
          { staticClass: '' },
          translation,
        );
      },
    });
  },
};

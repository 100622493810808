<template>
  <iframe class="feed-frame"
          frameborder="0"
          scrolling="no"
          :src="url">
  </iframe>
</template>

<script>
import { mapGetters } from 'vuex';
import removeWhiteSpace from '../../../utility/removeWhiteSpace';

export default {
  name: 'Feed',
  computed: {
    ...mapGetters([
      'appConfig',
    ]),
    showFeed() {
      return this.appConfig.company.showFeed;
    },
    url() {
      const { companyUuid } = this.appConfig.company;
      return removeWhiteSpace(`
        https://games-achievement.netlify.app/feed
        ?tenantId=${companyUuid}
      `);
    },
  },
};
</script>

<style>
.feed-frame {
  width: 100%;
  height: 560px;
}
</style>

<template>
  <div class="game-wrapper"
       v-if="isGameLoaded">
    <div class="main-content"
         :style="hideContent"
         :class="[{gateway: isGateway()},
         {'stake-box-active': showStakeBox}, {modal: showModalStats}, `${integrationMode}`,
         {mobile: isMob}]">
      <div class="betting-content">
        <GameHeader :player="player"></GameHeader>
        <div class="visualization"
             :style="mobileVisualization">
          <Visualization></Visualization>
        </div>
        <div class="betting-area"
             :class="{disabled: bettingLocked}">
          <Betting></Betting>
          <stake-fade-transition>
            <StakeBox v-if="showStakeBox"
                      @close="toggleStakeBox"
                      :showPayment="isMob"
                      :predefinedStakes="predefinedStakeValues"
                      :showActions="isMob">
            </StakeBox>
          </stake-fade-transition>
          <!-- Mobile version of betslip -->
          <div class="betslip-component"
               v-show="showBetslip">
            <div class="betslip-header">
              <div class="header-item"
                   v-for="(tab, key) in ticketWrapperTabs"
                   :key="key"
                   @click="selectTab(key)"
                   :disabled="tab.disabled"
                   :class="{active: key === selectedTicketTab, disabled: tab.disabled}">
                <span>
                  {{ tab.name | translate }}
                  <BetsCounter v-if="tab.indicator"></BetsCounter>
                </span>
              </div>
            </div>
            <div class="betslip-content">
              <ExpandedBetslip
                v-if="selectedTicketTab === 0 && !showStakeBox"
                :bettingDisabled="bettingDisabled"
                :bettingLocked="bettingLocked"/>
              <LastTickets
                v-if="selectedTicketTab === 1 && !showStakeBox"
                :tickets="lastTicketsData"
                :isMob="isMob"/>
            </div>
          </div>
          <RegulatoryMessage :msg="regulatoryMsg.text"
                             v-if="regulatoryMsg.visible">
          </RegulatoryMessage>
        </div>
      </div>
      <div class="draw-details-sidebar">
        <DrawDetails :tabs="drawDetailsTabs"></DrawDetails>
      </div>
      <ModalEventDetails v-if="showModalStats && isMob"></ModalEventDetails>
    </div>
    <TicketWrapper :style="hideContent"></TicketWrapper>
    <ErrorOverlay></ErrorOverlay>
    <TicketsHistory v-if="ticketHistoryStandAlone && player.loggedIn"></TicketsHistory>
    <div class="ticket-history-modal">
      <Modal v-if="showTicketHistory && player.loggedIn"
             :open="showTicketHistory"
             @close="showTicketHistory = false">
        <h3 slot="header"></h3>
        <TicketsHistory slot="body" @close="showTicketHistory = false"></TicketsHistory>
        <div slot="footer"></div>
      </Modal>
    </div>
    <div class="ticket-details">
      <Modal v-if="showTicketDetails && player.loggedIn"
             :open="showTicketDetails"
             class="ticket-details"
             @close="showTicketDetails = false">
        <h3 slot="header"></h3>
        <TicketDetails slot="body"
                       :ticket="ticket"
                       @close="showTicketDetails = false">
        </TicketDetails>
        <div slot="footer"></div>
      </Modal>
    </div>
    <div class="cancel-ticket">
      <Modal v-if="showCancelTicket && player.loggedIn"
             :open="showCancelTicket"
             class="cancel-ticket"
             @close="showCancelTicket = false">
        <h3 slot="header">
          {{'general_cancel_ticket' | translate}}
        </h3>
        <div slot="body">
          {{'general_cancel_ticket_confirm' | translate}} {{ticket.id}}
        </div>
        <div class="footer" slot="footer">
          <button @click="showCancelTicket = false">
            {{'general_close' | translate}}
          </button>
          <button @click="approveTicketCancel">
            {{'general_accept' | translate}}
          </button>
        </div>
      </Modal>
    </div>
    <div class="game-help">
      <Modal v-if="showModalStats && !isMob"
             :open="showModalStats"
             @close="showModalStats()">
        <ModalEventDetails slot="body"
                           v-if="showModalStats && !isMob">
        </ModalEventDetails>
        <div slot="footer"></div>
      </Modal>
    </div>
    <div class="quick-pick">
      <Modal v-if="showQuickPick"
             :open="showQuickPick"
             @close="showQuickPick = false">
        <h3 slot="header">{{ 'general_random' | translate }}</h3>
        <div slot="body">
          <div class="pick">
            <Radio v-for="(pick, index) in pickNumbers"
                   :key="index"
                   @change="setRandomNumbers"
                   :id="pick.value"
                   :label="pick.label"
                   :subLabel="pick.subLabel">
            </Radio>
          </div>
        </div>
        <div class="footer" slot="footer">
          <button @click="showQuickPick = false">
            {{'general_close' | translate}}
          </button>
        </div>
      </Modal>
    </div>
    <div class="quick-pick">
        <Modal v-if="showFutureBets && isMob"
              :open="showFutureBets"
              @close="showFutureBets = false">
          <h3 slot="header">{{ 'general_future_bet' | translate }}</h3>
          <div slot="body">
            <div class="pick">
              <Radio v-for="(item, index) in maxFutureBets"
                     @change="setFutureBets"
                     :key="index"
                     :id="item">
              </Radio>
            </div>
          </div>
          <div class="footer" slot="footer">
            <button @click="showFutureBets = false">
              {{'general_close' | translate}}
            </button>
          </div>
        </Modal>
      </div>
    <FullScreen v-if="player.id"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { each, has } from 'lodash';
import Visualization from '@/components/web/visualization/VisualizationWrapper';
import Betting from '@/components/web/betting/BettingBody';
import DrawDetails from '@/components/web/drawDetails/DrawDetailsWrapper';
import ErrorOverlay from '@/components/web/common/ErrorOverlay';
import RegulatoryMessage from '@/components/web/common/RegulatoryMessage';
import TicketWrapper from '@/components/web/ticket/TicketWrapper';
import TicketsHistory from '@/components/web/ticketsHistory/TicketsHistoryWrapper';
import TicketDetails from '@/components/web/ticketDetails/TicketDetailsWrapper';
import Modal from '@/components/web/common/Modal';
import eventBus from '@/utility/eventBus';
import integrator from '@/utility/integrator';
import webViewIntegration from '@/utility/webViewIntegration';
import GameHeader from '@/components/web/header/GameHeader';
import { audio } from '@nsoft/games-sdk-js/src/utility/index';
import gravity from '@/utility/gravityGateway';
import ModalEventDetails from '@/components/web/betting/ModalEventDetails';
import isMobile from 'ismobilejs';
import ExpandedBetslip from '@nsoft/games-sdk-js/src/components/Betslip/Expanded';
import LastTickets from '@nsoft/games-sdk-js/src/components/LastTickets/LastTicketsWrapper';
import BetsCounter from '@/components/web/common/BetsCounter';
import Radio from '@/components/web/common/Radio';
import StakeBox from '@nsoft/games-sdk-js/src/components/Betslip/StakeBox';
import StakeFadeTransition from '@nsoft/games-sdk-js/src/components/Betslip/StakeFadeTrasition';
import FullScreen from '@/components/web/common/FullScreen';

export default {
  name: 'LuckySix',
  components: {
    GameHeader,
    Visualization,
    Betting,
    DrawDetails,
    ErrorOverlay,
    RegulatoryMessage,
    TicketWrapper,
    TicketsHistory,
    TicketDetails,
    Modal,
    ModalEventDetails,
    ExpandedBetslip,
    LastTickets,
    BetsCounter,
    Radio,
    StakeBox,
    StakeFadeTransition,
    FullScreen,
  },
  data() {
    return {
      maxFutureBets: 0,
      showFutureBets: false,
      ticketHistoryStandAlone: false,
      selectedTicketTab: 0,
      ticketWrapperTabs: [
        {
          name: 'general_betslip',
          indicator: true,
        },
        {
          name: 'general_active_tickets',
        },
      ],
      showStakeBox: false,
      showQuickPick: false,
      showModal: false,
      showTicketHistory: false,
      showTicketDetails: false,
      showCancelTicket: false,
      roundId: null,
      ticket: {},
      ticketDetails: {},
      drawDetailsTabs: [
        {
          name: 'general_statistics',
          component: 'Statistics',
        },
        {
          name: 'general_results',
          component: 'Results',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'isGameLoaded',
      'gameBgColor',
      'appConfig',
      'player',
      'isAudioOn',
      'lastTicketsData',
      'platformName',
      'showModalStats',
      'pickNumbers',
      'payinForbidden',
      'ticketUnpayable',
      'showBetslip',
      'round',
      'roundInfo',
      'bettingLocked',
      'bettingDisabled',
      'predefinedStakeValues',
      'integrationMode',
      'showFeed',
    ]),
    hideContent() {
      return {
        display: this.ticketHistoryStandAlone ? 'none' : 'inline-block',
      };
    },
    mobileVisualization() {
      return this.isMob ? 'padding-top: 75%' : 'padding-top: 56.25%';
    },
    regulatoryMsg() {
      return this.appConfig.company.regulatoryMessage;
    },
    isMob() {
      return isMobile().any;
    },
  },
  methods: {
    ...mapActions([
      'loadLastTickets',
      'rebetTicket',
      'cancelTicket',
      'quickPayin',
      'generateRandomNumbers',
      'resetBets',
      'setTotalPayment',
    ]),
    toggleStakeBox() {
      this.showStakeBox = false;
    },
    setRandomNumbers(pick) {
      eventBus.$emit('selectPick', pick);
      this.showQuickPick = false;
    },
    selectTab(key) {
      if (!this.player.id || this.bettingLocked) return;
      this.selectedTicketTab = key;
    },
    getLastTickets() {
      this.loadLastTickets();
    },
    openTicketDetails(ticket) {
      this.ticket = ticket;
      this.showTicketDetails = true;
    },
    approveTicketCancel() {
      this.cancelTicket(this.ticket);
      this.showCancelTicket = false;
      this.showTicketDetails = false;
    },
    isGateway() {
      return window.location !== window.parent.location && this.appConfig.company.gravityEnabled;
    },
    setFutureBets(futureBets) {
      this.showFutureBets = false;
      eventBus.$emit('setFutureBets', futureBets);
    },
  },
  created() {
    if (!this.player.id) {
      this.ticketWrapperTabs[1].disabled = true;
    }
    eventBus.$on('openFutureBets', (maxFutureBets) => {
      this.showFutureBets = true;
      this.maxFutureBets = maxFutureBets;
    });
    eventBus.$on('openStakeBox', () => {
      this.showStakeBox = !this.showStakeBox;
    });
    eventBus.$on('modalQuickPick', () => {
      this.showQuickPick = true;
    });
    eventBus.$on('rebet', () => {
      this.selectTab(0);
      this.showCancelTicket = false;
      this.showTicketDetails = false;
      this.showTicketHistory = false;
    });
    eventBus.$on('cancelTicket', (ticket) => {
      this.ticket = ticket;
      this.showCancelTicket = true;
    });
    eventBus.$on('refreshTicketsList', () => {
      this.getLastTickets();
    });
    eventBus.$on('openTicketHistory', () => {
      if (this.player.loggedIn) {
        this.showTicketHistory = true;
      }
    });
    eventBus.$on('ticketDetails', (ticket) => {
      this.openTicketDetails(ticket);
    });
    eventBus.$on('loginRequired', () => {
      if (this.isAudioOn) {
        audio.playSound('click');
      }
      const { gravityEnabled } = this.appConfig.company;
      if (gravityEnabled) {
        if (webViewIntegration.isActive() && has(window, 'WebAppListener')) {
          /* eslint-disable */
          WebAppListener.sendMessage('LoginActivity.SignIn');
        } else {
          gravity.sendMessage('User.LoginRequired');
        }
      } else {
        integrator.sendMessage({
          type: 'loginRequired',
        });
      }
    });
    eventBus.$on('roundResults', (eventId) => {
      this.roundId = eventId;
    });
    eventBus.$on('removeBet', () => {
      if (this.isAudioOn) {
        audio.playSound('clear');
      }
    });
    eventBus.$on('clearBetslip', () => {
      if (this.isAudioOn) {
        audio.playSound('clear');
      }
    });
    eventBus.$on('payin', () => {
      if (this.isAudioOn) {
        audio.playSound('click');
      }
    });
    eventBus.$on('quickPayin', (bet) => {
      this.quickPayin(bet);
    });
    eventBus.$on('addBet', () => {
      this.selectTab(0);
      if (this.isAudioOn) {
        audio.playSound('click');
      }
    });
    eventBus.$on('ticketUpdate', () => {
      eventBus.$emit('clearBetslip');
    });
    if (this.$route.name === 'ticketsHistory') {
      this.ticketHistoryStandAlone = true;
    }
  },
  watch: {
    showFeed(value) {
      if(value) {
        this.drawDetailsTabs.unshift(
          {
            name: 'Feed',
            component: 'Feed',
          },
        );
      }
    },
    'player.id'(value) {
      this.ticketWrapperTabs[1].disabled = !value;
    },
    lastTicketsData(newValue) {
      if (!newValue.length) {
        this.selectTab(0);
        return;
      }
      if (newValue.length && this.roundId) {
        each(newValue, (ticket) => {
          if (ticket.status.value === 'PAYEDOUT' || ticket.status.value === 'WON') {
            each(ticket.bets, (bet) => {
              if (bet.eventId === this.roundId && this.isAudioOn) {
                audio.playSound('win');
                this.roundId = null;
              }
            });
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.game-wrapper {
  width: 100%;
  float: left;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: transparent;
  justify-content: center;

  .main-content {
    position: relative;
    background-color: var(--bg-color-2);
    scrollbar-width: none;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0;
        height: 0;
      }

      &.standalone {
        overflow: auto;
        max-height: 100vh;
      }

    &.mobile {
      max-height: none;
    }

      .betting-content {
        .visualization {
          float: left;
          width: 100%;
          position: relative;
          overflow: hidden;
        }
        .betting-area {
          .betslip-component {
            display: none;
          }

          &.disabled {
            opacity: .4;
          }
        }
      }
    }

    .ticket-history-modal {
      .header {
        display: flex;
        position: relative;

        .icon {
          cursor: pointer;
          font-size: 18px;
          position: absolute;
          right: 0;
          top: 5px;
        }
      }

      .modal-container {
        height: 90%;
      }
    }

    .cancel-ticket {
      button {
        background: transparent;
        border: 0;
        outline: 0;
        font-size: 14px;
        color: #ffc107;
        text-transform: uppercase;
        margin-left: 30px;
        cursor: pointer;
      }

      .footer {
        float: right;
      }
    }

    .quick-pick {
      .modal-mask {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
}

@media (min-width: 306px) and (max-width: 659px) {
  .game-wrapper {
    padding-top: 0;

    .main-content {
      width: 100%;
      float: left;
      padding-bottom: 90px;

      &.modal {
        height: 100vh;
        padding-bottom: 0;
      }

      &.stake-box-active {
        padding-bottom: 0;
        height: 100vh;
        z-index: 100;
        background-color: var(--card-bg);
      }

      .betting-content {
        margin: 0;
        float: left;

        .betting-area {
          float: left;
          width: 100%;
          margin-top: 0;
          position: relative;

          .stake-box {
            bottom: 0;
            left: 0;
            height: 100%;
            z-index: 10;
          }

          .betslip-component {
            display: block;
            position: absolute;
            width: 100%;
            z-index: 1;
            background-color: var(--betslip-bg, var(--bg-color-2));
            height: 100%;

            .betslip-header {
              float: left;
              width: 100%;
              height: 40px;

              .header-item {
                font-size: 14px;
                position: relative;
                float: left;
                width: 50%;
                height: 100%;
                padding: 10px;
                text-align: center;
                cursor: pointer;
                background-color: var(--card-bg);
                color: var(--text-primary-1);
                border-bottom: 2px solid var(--tab-border-color);

                &.active {
                  border-bottom: 2px solid var(--accent-bg, var(--brand-bg));
                  color: var(--accent-bg, var(--brand-bg));
                }

                &.disabled, &:disabled {
                  background-color: var(--tab-pressed-bg);
                  color: var(--text-primary-3);
                }

                span {
                  position: relative;

                  .bets-counter {
                    position: absolute;
                    right: -25px;
                    top: 0;
                  }
                }
              }
            }

            .betslip-content {
              height: 100%;
              padding-bottom: 20px;

              .expanded-wrapper {
                width: 100%;
                height: 100%;
                overflow: auto;
                padding-bottom: 20px;
              }
            }
          }
        }
      }
      .draw-details-sidebar {
        display: none;
      }
    }

    .ticket-wrapper {
      width: 100%;
      position: fixed;
      bottom: 0;
      z-index: 2;
    }
  }
}

@media (min-width: 660px) and (max-width: 959px) {
  .main-content {
    width: 660px;
    margin: 0 auto;
    padding: 8px 0 50px 0;

    .betting-content {
      margin-right: 0;
      float: left;
      margin-bottom: 8px;
      width: 100%;

      .betting-area {
        .stake-box {
          position: fixed;
          bottom: 63px;
          height: 144px;
          width: 300px;
        }
      }
    }
    .draw-details-sidebar {
      float: left;
      width: 100%;
      margin: 0 auto;
    }
  }

  .ticket-wrapper {
    width: 660px;
    position: fixed;
    bottom: 0;
  }
}

@media (min-width: 960px) and (max-width: 1099px) {
  .main-content {
    width: 660px;
    margin: 0 auto;
    padding: 8px;

    .betting-content,
    .draw-details-sidebar {
      width: 644px;
      margin: 0 auto;
      float: left;
    }

    .betting-content {
      margin-bottom: 8px;
    }
  }
}

@media (min-width: 1100px) and (max-width: 1267px) {
  .main-content {
    width: 800px;
    margin: 0 auto;
    padding: 8px;

    .betting-content,
    .draw-details-sidebar {
      width: 784px;
      margin: 0 auto;
      float: left;
    }

    .betting-content {
      margin-bottom: 8px;
    }
  }
}

@media (min-width: 1268px) and (max-width: 1483px) {
  .main-content {
    width: 968px;
    margin: 0 auto;
    padding: 8px;

    .betting-content {
      width: 640px;
      float: left;
      margin-right: 8px;
    }
    .draw-details-sidebar {
      width: 304px;
      float: left;
    }
  }
}

@media (min-width: 1484px) {
  .main-content {
    margin: 0 auto;
    padding: 8px;

    .betting-content {
      width: 784px;
      float: left;
      margin-right: 8px;
    }
    .draw-details-sidebar {
      width: 376px;
      float: left;
    }
  }
}
</style>

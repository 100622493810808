var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.player.loggedIn
    ? _c(
        "div",
        { staticClass: "tickets-history-wrapper" },
        [
          _c("CircleLoader", {
            attrs: { loader: _vm.ticketHistoryInProgress },
          }),
          _c("div", { staticClass: "header" }, [
            _c("div", { staticClass: "product-title" }, [
              _c("span", [_vm._v(_vm._s("Lucky Six"))]),
              _c("i", {
                staticClass: "icon icon-close-a",
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "date-picker" }, [
              _c("span", { staticClass: "label" }, [
                _vm._v(
                  " " + _vm._s(_vm._f("translate")("general_date_range")) + " "
                ),
              ]),
              _c(
                "div",
                { staticClass: "date-pick" },
                [
                  _c("i", { staticClass: "icon icon-calendar" }),
                  _c("date-picker", {
                    staticClass: "date-picker-field",
                    attrs: {
                      mode: _vm.mode,
                      popover: _vm.popover,
                      masks: _vm.masks,
                      locale: _vm.appConfig.language,
                    },
                    on: { input: _vm.applyFilters },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var inputProps = ref.inputProps
                            var inputEvents = ref.inputEvents
                            return _c(
                              "input",
                              _vm._g(
                                _vm._b(
                                  { attrs: { id: "date", readonly: "" } },
                                  "input",
                                  inputProps,
                                  false
                                ),
                                inputEvents
                              )
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      1836829630
                    ),
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("Scrollable", [
            _c("div", { staticClass: "body" }, [
              _c("div", { staticClass: "bets-table" }, [
                _c(
                  "div",
                  { staticClass: "rows mobile" },
                  _vm._l(_vm.ticketHistoryData, function (ticket, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "row",
                        on: {
                          click: function ($event) {
                            return _vm.openTicketDetails(ticket)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "bet-item" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tickets-history-status",
                              class:
                                "status-" + ticket.status.value.toLowerCase(),
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(ticket.status.name.toUpperCase()) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "tickets-history-payment-info" },
                            [
                              _c("div", { staticClass: "info" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("translate")("general_stake")
                                      ) +
                                      _vm._s(": ") +
                                      " "
                                  ),
                                ]),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(
                                    " " + _vm._s(ticket.payin.toFixed(2)) + " "
                                  ),
                                ]),
                              ]),
                              ticket.payinTax
                                ? _c("div", { staticClass: "info" }, [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("translate")(
                                              "general_payin_tax"
                                            )
                                          ) +
                                          _vm._s(": ") +
                                          " "
                                      ),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(ticket.payinTax.toFixed(2)) +
                                          " "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "info" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("translate")("general_payout")
                                      ) +
                                      _vm._s(": ") +
                                      " "
                                  ),
                                ]),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(
                                    " " + _vm._s(ticket.payout.toFixed(2)) + " "
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "footer" }, [
            _vm.ticketHistoryRangeError.message
              ? _c(
                  "div",
                  { staticClass: "notification-area", class: { wide: true } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        class: _vm.ticketHistoryRangeError.status.toLowerCase(),
                      },
                      [
                        _c("div", { staticClass: "item-icon" }, [
                          _c("i", {
                            staticClass: "icon",
                            class: "icon-" + _vm.ticketHistoryRangeError.icon,
                          }),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "item-title",
                            attrs: {
                              title: "" + _vm.ticketHistoryRangeError.message,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("translate")(
                                    _vm.ticketHistoryRangeError.message
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.noDataMessage &&
            !_vm.showNotification &&
            !_vm.ticketHistoryRangeError.message
              ? _c("div", { staticClass: "info" }, [
                  _c("i", { staticClass: "icon icon-info" }),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("translate")("general_warning_no_data"))
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
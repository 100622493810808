<template>
  <div class="ticket-wrapper">
    <div class="ticket-wrapper-content">
      <div class="tabs" v-if="appLayout === 'Expanded'">
        <div class="tab"
             v-for="(tab, key) in ticketWrapperTabs"
             :key="key"
             @click="selectTab(tab, key)"
             :disabled="tab.disabled"
             :class="{active: key === selectedTicketTab, disabled: tab.disabled}">
          <span class="title">
            {{tab.name | translate}}
            <BetsCounter v-if="tab.indicator"></BetsCounter>
          </span>
        </div>
      </div>
      <!-- Desktop version of betslip -->
      <div class="betslip-box"
           :class="{disabled: bettingLocked}">
        <Betslip v-show="selectedTicketTab === 0"
                 :config="getBetslipConfig()"
                 :betslipType="getBetslipType()"
                 :theme="appConfig.company.theme"
                 :balance="player.balance"
                 :bettingLocked="bettingLocked"
                 :bettingDisabled="bettingDisabled"
                 :predefinedStakes="predefinedStakeValues"
                 :payinDisabled="payinDisabled">
        </Betslip>
        <Notifications v-if="!isMobile && selectedTicketTab === 0
        && showNotification && appLayout !== 'Compact'" />
      </div>
      <LastTickets v-show="selectedTicketTab === 1"
                   :isMob="isMobile"
                   :tickets="lastTicketsData">
      </LastTickets>
    </div>
  </div>
</template>

<script>
import { has } from 'lodash';
import isMobile from 'ismobilejs';
import { mapGetters } from 'vuex';
import Betslip from '@nsoft/games-sdk-js/src/components/Betslip/Betslip';
import LastTickets from '@nsoft/games-sdk-js/src/components/LastTickets/LastTicketsWrapper';
import BetsCounter from '@/components/web/common/BetsCounter';
import Notifications from '@/components/web/common/Notifications';
import webViewIntegration from '@/utility/webViewIntegration';
import eventBus from '@/utility/eventBus';

export default {
  name: 'TicketWrapper',
  components: {
    Betslip,
    LastTickets,
    BetsCounter,
    Notifications,
  },
  props: {
    type: {
      type: String,
      default: 'Expanded',
    },
  },
  data() {
    return {
      longPress: false,
      selectedTicketTab: 0,
      ticketWrapperTabs: [
        {
          name: 'general_betslip',
          indicator: true,
        },
        {
          name: 'general_active_tickets',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'payinDisabled',
      'player',
      'appConfig',
      'lastTicketsData',
      'notifications',
      'ticket',
      'bettingLocked',
      'appLayout',
      'bettingDisabled',
      'predefinedStakeValues',
    ]),
    isMobile() {
      return isMobile().any || window.innerWidth <= 1024;
    },
    showNotification() {
      return !!(this.notifications.length && !this.notifications[0].httpCode);
    },
  },
  methods: {
    selectTab(tab, key) {
      if (this.bettingLocked) return;
      if (tab && tab.disabled) return;
      this.selectedTicketTab = key;
    },
    getBetslipConfig() {
      return this.appConfig.company.betslip;
    },
    getBetslipType() {
      const { betslip } = this.appConfig.company;
      const compactBreakpoints = ['s', 'm', 'smallMin', 'l', 'xl', 'smallMax'];
      betslip.layout = compactBreakpoints.indexOf(this.$mq) > -1 || this.appLayout === 'Compact'
      || this.type === 'Compact' ? 'Compact' : 'Expanded';
      return betslip.layout;
    },
  },
  created() {
    if (!this.player.id) {
      this.ticketWrapperTabs[1].disabled = true;
    }
    if (webViewIntegration.isActive() && has(window, 'WebAppListener')) {
      /* eslint-disable */
      this.longPress = true;
    }
    eventBus.$on('rebet', () => {
      this.selectTab({},0);
    });
    eventBus.$on('addBet', () => {
      this.selectTab({},0);
    });
  },
  watch: {
    'player.id'(value) {
      this.ticketWrapperTabs[1].disabled = !value;
    },
    lastTicketsData(value) {
      if (!value.length) this.selectTab({}, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
  .ticket-wrapper {
    float: right;
    width: 300px;
    position: relative;

    .ticket-wrapper-content {
      float: left;
      width: 100%;
      position: relative;
      box-shadow: 0 20px 40px 0 var(--box-shadow);
    }

    .tabs {
      height: 40px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      background-color: var(--card-bg);
      position: relative;
      font-size: 14px;

      .tab {
        width: 50%;
        line-height: 40px;
        flex-grow: 1;
        text-align: center;
        border-bottom: 2px solid var(--tab-border-color);
        cursor: pointer;
        color: var(--text-primary-1);
        background-color: var(--tab-bg, var(--card-bg));
        transition: all .5s ease;

        &:hover {
          background-color: var(--tab-hover-bg);
        }

        &:active {
          background-color: var(--tab-pressed-bg);
        }

        &.disabled, &:disabled {
          background-color: var(--tab-pressed-bg);
          color: var(--text-primary-3);
        }

        .title {
          position: relative;

          .bets-counter {
            top: 0px;
            right: -23px;
          }
        }

        &.active {
          border-bottom: 2px solid var(--accent-bg, var(--brand-bg));
          color: var(--accent-bg, var(--brand-bg));

          .title {
            .bets-counter {
              background: var(--accent-bg, var(--brand-bg));
              color: var(--accent-text, var(--text-primary-1));
            }
          }
        }
      }
    }

    .betslip-box {
      position: relative;
      float: left;
      height: 100%;
      width: 100%;

      ::v-deep {
        .betslip-wrapper {
          .empty-betslip {
            border-radius: var(--custom-border-radius, var(--border-radius));
            background-color: var(--main-primary);
            color: var(--brand-text-color);
            font-size: 16px;
          }
          .payin-button,
          .login-button {
            background: var(--payin-button-bg);
          }
        }
      }

      .notification-wrapper {
        position: absolute;
        bottom: 107px;
      }
    }
  }

  @media (max-width: 960px) {
    .ticket-wrapper {
      .tabs {
        display: none;
      }
    }
  }
</style>

import SevenStrategy from './sevenStrategy';
import PlaytechStrategy from './playtechStrategy';

export default class PlatformContext {
  constructor(config, queryParams) {
    switch (config.platform) {
      case 'seven':
        this.strategy = new SevenStrategy(config, queryParams);
        break;
      case 'playtech':
        this.strategy = new PlaytechStrategy(config, queryParams);
        break;
      default:
        this.strategy = new SevenStrategy(config, queryParams);
    }
  }

  contextInterface() {
    this.strategy.loadGame();
  }
}

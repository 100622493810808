var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ticket.bets.length
    ? _c("div", { staticClass: "bets-counter" }, [
        _c("span", [_vm._v(_vm._s(_vm.betsCount))]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
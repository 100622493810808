var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isGameLoaded
    ? _c(
        "div",
        { staticClass: "game-wrapper" },
        [
          _c(
            "div",
            {
              staticClass: "main-content",
              class: [
                { gateway: _vm.isGateway() },
                { "stake-box-active": _vm.showStakeBox },
                { modal: _vm.showModalStats },
                "" + _vm.integrationMode,
                { mobile: _vm.isMob },
              ],
              style: _vm.hideContent,
            },
            [
              _c(
                "div",
                { staticClass: "betting-content" },
                [
                  _c("GameHeader", { attrs: { player: _vm.player } }),
                  _c(
                    "div",
                    {
                      staticClass: "visualization",
                      style: _vm.mobileVisualization,
                    },
                    [_c("Visualization")],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "betting-area",
                      class: { disabled: _vm.bettingLocked },
                    },
                    [
                      _c("Betting"),
                      _c(
                        "stake-fade-transition",
                        [
                          _vm.showStakeBox
                            ? _c("StakeBox", {
                                attrs: {
                                  showPayment: _vm.isMob,
                                  predefinedStakes: _vm.predefinedStakeValues,
                                  showActions: _vm.isMob,
                                },
                                on: { close: _vm.toggleStakeBox },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showBetslip,
                              expression: "showBetslip",
                            },
                          ],
                          staticClass: "betslip-component",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "betslip-header" },
                            _vm._l(_vm.ticketWrapperTabs, function (tab, key) {
                              return _c(
                                "div",
                                {
                                  key: key,
                                  staticClass: "header-item",
                                  class: {
                                    active: key === _vm.selectedTicketTab,
                                    disabled: tab.disabled,
                                  },
                                  attrs: { disabled: tab.disabled },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectTab(key)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("translate")(tab.name)
                                          ) +
                                          " "
                                      ),
                                      tab.indicator
                                        ? _c("BetsCounter")
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticClass: "betslip-content" },
                            [
                              _vm.selectedTicketTab === 0 && !_vm.showStakeBox
                                ? _c("ExpandedBetslip", {
                                    attrs: {
                                      bettingDisabled: _vm.bettingDisabled,
                                      bettingLocked: _vm.bettingLocked,
                                    },
                                  })
                                : _vm._e(),
                              _vm.selectedTicketTab === 1 && !_vm.showStakeBox
                                ? _c("LastTickets", {
                                    attrs: {
                                      tickets: _vm.lastTicketsData,
                                      isMob: _vm.isMob,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm.regulatoryMsg.visible
                        ? _c("RegulatoryMessage", {
                            attrs: { msg: _vm.regulatoryMsg.text },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "draw-details-sidebar" },
                [_c("DrawDetails", { attrs: { tabs: _vm.drawDetailsTabs } })],
                1
              ),
              _vm.showModalStats && _vm.isMob
                ? _c("ModalEventDetails")
                : _vm._e(),
            ],
            1
          ),
          _c("TicketWrapper", { style: _vm.hideContent }),
          _c("ErrorOverlay"),
          _vm.ticketHistoryStandAlone && _vm.player.loggedIn
            ? _c("TicketsHistory")
            : _vm._e(),
          _c(
            "div",
            { staticClass: "ticket-history-modal" },
            [
              _vm.showTicketHistory && _vm.player.loggedIn
                ? _c(
                    "Modal",
                    {
                      attrs: { open: _vm.showTicketHistory },
                      on: {
                        close: function ($event) {
                          _vm.showTicketHistory = false
                        },
                      },
                    },
                    [
                      _c("h3", { attrs: { slot: "header" }, slot: "header" }),
                      _c("TicketsHistory", {
                        attrs: { slot: "body" },
                        on: {
                          close: function ($event) {
                            _vm.showTicketHistory = false
                          },
                        },
                        slot: "body",
                      }),
                      _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ticket-details" },
            [
              _vm.showTicketDetails && _vm.player.loggedIn
                ? _c(
                    "Modal",
                    {
                      staticClass: "ticket-details",
                      attrs: { open: _vm.showTicketDetails },
                      on: {
                        close: function ($event) {
                          _vm.showTicketDetails = false
                        },
                      },
                    },
                    [
                      _c("h3", { attrs: { slot: "header" }, slot: "header" }),
                      _c("TicketDetails", {
                        attrs: { slot: "body", ticket: _vm.ticket },
                        on: {
                          close: function ($event) {
                            _vm.showTicketDetails = false
                          },
                        },
                        slot: "body",
                      }),
                      _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "cancel-ticket" },
            [
              _vm.showCancelTicket && _vm.player.loggedIn
                ? _c(
                    "Modal",
                    {
                      staticClass: "cancel-ticket",
                      attrs: { open: _vm.showCancelTicket },
                      on: {
                        close: function ($event) {
                          _vm.showCancelTicket = false
                        },
                      },
                    },
                    [
                      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("translate")("general_cancel_ticket")
                            ) +
                            " "
                        ),
                      ]),
                      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("translate")(
                                "general_cancel_ticket_confirm"
                              )
                            ) +
                            " " +
                            _vm._s(_vm.ticket.id) +
                            " "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.showCancelTicket = false
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("translate")("general_close")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "button",
                            { on: { click: _vm.approveTicketCancel } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("translate")("general_accept")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "game-help" },
            [
              _vm.showModalStats && !_vm.isMob
                ? _c(
                    "Modal",
                    {
                      attrs: { open: _vm.showModalStats },
                      on: {
                        close: function ($event) {
                          return _vm.showModalStats()
                        },
                      },
                    },
                    [
                      _vm.showModalStats && !_vm.isMob
                        ? _c("ModalEventDetails", {
                            attrs: { slot: "body" },
                            slot: "body",
                          })
                        : _vm._e(),
                      _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "quick-pick" },
            [
              _vm.showQuickPick
                ? _c(
                    "Modal",
                    {
                      attrs: { open: _vm.showQuickPick },
                      on: {
                        close: function ($event) {
                          _vm.showQuickPick = false
                        },
                      },
                    },
                    [
                      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                        _vm._v(_vm._s(_vm._f("translate")("general_random"))),
                      ]),
                      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                        _c(
                          "div",
                          { staticClass: "pick" },
                          _vm._l(_vm.pickNumbers, function (pick, index) {
                            return _c("Radio", {
                              key: index,
                              attrs: {
                                id: pick.value,
                                label: pick.label,
                                subLabel: pick.subLabel,
                              },
                              on: { change: _vm.setRandomNumbers },
                            })
                          }),
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.showQuickPick = false
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("translate")("general_close")) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "quick-pick" },
            [
              _vm.showFutureBets && _vm.isMob
                ? _c(
                    "Modal",
                    {
                      attrs: { open: _vm.showFutureBets },
                      on: {
                        close: function ($event) {
                          _vm.showFutureBets = false
                        },
                      },
                    },
                    [
                      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                        _vm._v(
                          _vm._s(_vm._f("translate")("general_future_bet"))
                        ),
                      ]),
                      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                        _c(
                          "div",
                          { staticClass: "pick" },
                          _vm._l(_vm.maxFutureBets, function (item, index) {
                            return _c("Radio", {
                              key: index,
                              attrs: { id: item },
                              on: { change: _vm.setFutureBets },
                            })
                          }),
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.showFutureBets = false
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("translate")("general_close")) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.player.id ? _c("FullScreen") : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "app",
      staticClass: "app",
      class: "theme-" + _vm.mode,
      attrs: { id: "app" },
    },
    [_c("router-view"), _c("NewFeatures")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shortcut-bets" },
    [
      _c("DropDown", {
        attrs: {
          options: _vm.pickNumbers,
          label: _vm.translations.general_random,
        },
        on: {
          getCurrentValue: function ($event) {
            return _vm.randomBallsSelection($event)
          },
          modalOpen: _vm.modalOpen,
          setSelectionOption: function ($event) {
            return _vm.randomBallsSelection($event)
          },
        },
      }),
      _vm.bets[10].value
        ? _c("Button", {
            class: { disabled: _vm.disabledPreball, active: _vm.preball },
            attrs: {
              disabled: _vm.disabledPreball,
              label: _vm.translations.general_preball,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.selectPreball()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="regulatory-message-wrapper">
    <i class="icon icon-info"></i>
    <span>{{msg}}</span>
  </div>
</template>

<script>
export default {
  name: 'RegulatoryMessage',
  props: {
    msg: {
      type: String,
      default: 'Účast na hazardní hře může být škodlivá',
    },
  },
};
</script>

<style lang="scss">
  .regulatory-message-wrapper {
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    height: 40px;
    float: left;
    border-radius: 3px;
    color: #b9b9b9;
    padding: 12px;
    position: relative;
    z-index: 0;

    i {
      margin-right: 0.5em;
      font-size: 1em;
      float: left;
    }

    span {
      font-size: 0.875em;
      float: left;
    }

  }
</style>

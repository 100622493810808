<template>
  <div class="colors-wrap">
    <div class="checkbox color"
         @click="selectColor(color, index)"
         :class="[{active: color.active || allColorsSelected},
         `color-group-${index}`]">
      <input type="checkbox">
      <label></label>
    </div>
  </div>
</template>

<script>
import { each, assign, filter } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { audio } from '@nsoft/games-sdk-js/src/utility/index';

export default {
  name: 'GameBallColorGroup',
  props: {
    color: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      colorGroupBet: {
        active: false,
        id: 0,
        title: [],
        subtitle: 'Standard',
        type: 'Standard',
        odds: null,
        outcomes: [],
        stake: 1,
        combinations: 1,
      },
    };
  },
  computed: {
    ...mapGetters([
      'colors',
      'allColorsSelected',
      'balls',
      'selectedBet',
      'isAudioOn',
      'bettingLocked',
    ]),
    active() {
      return this.color.active;
    },
  },
  methods: {
    ...mapActions([
      'resetBalls',
      'updateBets',
      'resetBets',
      'colorizedBalls',
      'selectAllColors',
      'resetGridSelection',
      'enableBetting',
      'resetColorGroups',
    ]),
    selectColor(color) {
      if (this.bettingLocked) return;
      this.selectAllColors(false);
      if (color.outcomes && !color.outcomes.length) {
        this.resetBalls();
        this.resetBets();
      }
      const selectedColor = color;
      selectedColor.active = !selectedColor.active;
      if (this.isAudioOn) {
        if (selectedColor.active) {
          audio.playSound('select');
        } else {
          audio.playSound('deselect');
        }
      }
      selectedColor.subtitle = this.$options.filters.translate(color.subtitle);

      this.toggleColorGroup(color);
      this.checkBetRules(this.colorGroupBet);
      if (color.active) {
        this.colorizedBalls(color);
      }
    },
    checkBetRules(bet) {
      let enable = true;
      if (!bet.outcomes.length) enable = false;
      this.enableBetting(enable);
    },
    getOutcomes(colorIndex) {
      const outcomes = filter(this.balls, (ball) => ball.colorId === colorIndex);
      return outcomes.map((ball) => ball);
    },
    selectAllColorGroups() {
      this.colorGroupBet = assign(this.colorGroupBet, this.color);
      this.colorGroupBet.outcomes = this.getOutcomes(this.color.colorId);
      this.colorGroupBet.subtitle = this.$options.filters.translate(this.color.subtitle);
      if (!this.allColorsSelected) {
        this.colorGroupBet.outcomes = [];
      }

      this.updateBets(this.colorGroupBet);
    },
    toggleColorGroup(colorGroup) {
      const inactiveColors = filter(this.colors, (color) => color.colorId !== colorGroup.colorId);
      each(inactiveColors, (val) => {
        const color = val;
        color.active = false;
      });
    },
  },
  watch: {
    selectedBet(bet) {
      if (bet.outcomes && !bet.value) {
        this.selectColor(bet);
      }
    },
    allColorsSelected(value) {
      if (value) {
        this.resetGridSelection();
        this.selectAllColorGroups();
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .colors-wrap {
    float: left;
    margin-left: 8px;
    padding-right: 13px;
    margin-top: 6px;

    @for $i from 1 through length($luckysix-ball-colors) {
      $color: nth($luckysix-ball-colors, $i);
      $index: $i - 1;

      .active.color-group-#{$index} {
        background: rgba($color, 0.5);
      }

      .color-group-#{$index} label {
        background: $color;
      }
    }
  }

  @media (min-width: 306px) and (max-width: 359px) {
    .colors-wrap {
      margin-left: 6px;
      padding-right: 9px;
    }
  }

  @media (min-width: 361px) and (max-width: 659px) {
    .colors-wrap {
      padding-right: 15px;
    }
  }

  @media (min-width: 660px) {
    .colors-wrap {
      padding-right: 20px;
    }
  }
</style>

<template>
  <div class="shortcut-bets">
    <DropDown :options="pickNumbers"
              :label="translations.general_random"
              @getCurrentValue="randomBallsSelection($event)"
              @modalOpen="modalOpen"
              @setSelectionOption="randomBallsSelection($event)">
    </DropDown>
    <Button @click.native="selectPreball()"
            v-if="bets[10].value"
            :class="{disabled: disabledPreball, active: preball}"
            :disabled="disabledPreball"
            :label="translations.general_preball">
    </Button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { helpers, audio } from '@nsoft/games-sdk-js/src/utility/index';
import eventBus from '@/utility/eventBus';
import Button from '../common/Button';
import DropDown from '../common/DropDown';

export default {
  name: 'ShortcutBets',
  components: {
    Button,
    DropDown,
  },
  data() {
    return {
      selected: false,
    };
  },
  computed: {
    ...mapGetters([
      'translations',
      'bet',
      'editMode',
      'preball',
      'selectedBet',
      'isAudioOn',
      'pickNumbers',
      'bettingLocked',
      'appConfig',
    ]),
    disabledPreball() {
      return this.bet.outcomes.length > 1;
    },
    bets() {
      return this.appConfig.product.bets;
    },
  },
  methods: {
    ...mapActions([
      'generateRandomNumbers',
      'resetSelection',
      'resetBets',
      'resetBet',
      'setPreball',
      'resetSpecialBets',
    ]),
    randomBallsSelection(pick) {
      if (this.bettingLocked) return;
      this.resetBets();
      const randomNumbers = helpers.getNumbers(1, 48, pick);
      this.generateRandomNumbers(randomNumbers);
      if (this.isAudioOn) {
        audio.playSound('select');
      }
    },
    selectPreball() {
      if (this.bettingLocked) return;
      this.selected = !this.selected;
      this.setPreball(this.selected);
      this.resetSpecialBets();
      if (!this.selected) {
        this.resetSelection();
        this.resetBets();
      }
      if (this.isAudioOn) {
        if (this.selected) {
          audio.playSound('select');
        } else {
          audio.playSound('deselect');
        }
      }
    },
    modalOpen() {
      eventBus.$emit('modalQuickPick');
    },
  },
  watch: {
    selectedBet(bet) {
      this.selected = bet.id === 10 && this.editMode;
      if (this.selected) this.setPreball(true);
    },
    'bet.outcomes': function betOutcomes(value) {
      if (value.length !== 1) {
        this.selected = false;
        this.setPreball(this.selected);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.shortcut-bets {
  display: flex;
  align-items: center;
  justify-content: center;

  &.disabled {
    opacity: .4;
  }

  .button, .dropdown {
    flex-grow: 1;
    width: 100%;
    margin-bottom: 8px;
    margin-right: 4px;
    transition: all .5s ease;
  }
}

@media only screen and (min-width: 660px) and (max-width: 1099px),
(min-width: 1268px) and (max-width: 1483px) {
  .shortcut-bets {
    flex-direction: column;
    align-items: normal;

    .button, .dropdown {
      margin-right: 8px;
    }
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.betslipConfig.layout === "Expanded"
    ? _c("div", { staticClass: "add-remove-bet" }, [
        _c(
          "button",
          {
            staticClass: "button remove-bet",
            class: { disabled: !_vm.clearBtnEnabled },
            attrs: { disabled: !_vm.clearBtnEnabled },
            on: { click: _vm.clearSelection },
          },
          [_c("i", { staticClass: "icon icon-trash" })]
        ),
        _c(
          "button",
          {
            staticClass: "button add-bet brand",
            class: { disabled: !_vm.bettingEnabled || !_vm.actionsEnabled },
            attrs: { disabled: !_vm.bettingEnabled || !_vm.actionsEnabled },
            on: { click: _vm.addToBetslip },
          },
          [_vm._v(" " + _vm._s(_vm.addLabel) + " ")]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-bets-wrapper" }, [
    _c("div", { staticClass: "main-bets-balls" }, [_c("ball")], 1),
    _c(
      "div",
      { staticClass: "color-groups" },
      _vm._l(_vm.colors, function (color, index) {
        return _c("color-group", {
          key: index,
          attrs: { color: color, index: index, reset: _vm.reset },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ticket-details-wrapper" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "product-title" }, [
        _c("span", [_vm._v(_vm._s(_vm.productTitle))]),
      ]),
      _c("div", { staticClass: "ticket-id" }, [
        _c("span", [_vm._v(_vm._s(_vm.ticketIdLabel) + _vm._s(_vm.ticket.id))]),
        _c("i", {
          staticClass: "icon icon-copy",
          on: {
            click: function ($event) {
              return _vm.copyTicketId(_vm.ticket.id)
            },
          },
        }),
      ]),
      _c("div", { staticClass: "separator" }, [_vm._v("|")]),
      _c("div", { staticClass: "ticket-date" }, [
        _c("span", [_vm._v(_vm._s(_vm.ticket.createdAt))]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "body" },
      [
        _c("Scrollable", [
          _c("div", { staticClass: "bets-table" }, [
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column wide" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("uppercase")(_vm._f("translate")("general_bet"))
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "column" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("uppercase")(_vm._f("translate")("general_odds"))
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "column" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("uppercase")(_vm._f("translate")("general_round"))
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "column wide" }, [
                _vm._v(
                  " " + _vm._s(_vm._f("uppercase")(_vm.betTypeLabel)) + " "
                ),
              ]),
              _c("div", { staticClass: "column" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("uppercase")(_vm._f("translate")("general_stake"))
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "column" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("uppercase")(
                        _vm._f("translate")("general_winning")
                      )
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "column" }),
            ]),
            !_vm.mobile
              ? _c(
                  "div",
                  { staticClass: "rows" },
                  _vm._l(_vm.ticket.bets, function (bet, key) {
                    return _c("div", { key: key, staticClass: "row" }, [
                      _vm.isOutcomeArray(bet.outcome.title)
                        ? _c(
                            "div",
                            { staticClass: "bet-item wide" },
                            _vm._l(bet.outcome.title, function (item, index) {
                              return _c(
                                "span",
                                {
                                  key: index,
                                  class: {
                                    active: _vm.isBallDrawn(bet, item),
                                    "played-numbers": [0, 1, 2, 3, 4].includes(
                                      bet.type
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("translate")(item)) +
                                      " "
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      !_vm.isOutcomeArray(bet.outcome.title)
                        ? _c("div", { staticClass: "bet-item wide" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("translate")(bet.outcome.title)) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "bet-item" }, [
                        _vm._v(_vm._s(bet.odd ? bet.odd.toFixed(2) : "-")),
                      ]),
                      _c("div", { staticClass: "bet-item" }, [
                        _vm._v(_vm._s(bet.eventId)),
                      ]),
                      _c("div", { staticClass: "bet-item wide" }, [
                        _vm._v(_vm._s(bet.typeValue)),
                      ]),
                      _c("div", { staticClass: "bet-item" }, [
                        _vm._v(_vm._s(bet.amount.toFixed(2))),
                      ]),
                      _c("div", { staticClass: "bet-item" }, [
                        _vm._v(
                          _vm._s(bet.winnings ? bet.winnings.toFixed(2) : "-")
                        ),
                      ]),
                      _c("div", { staticClass: "bet-item" }, [
                        _c("i", {
                          staticClass: "icon",
                          class:
                            "\n            icon-" +
                            _vm.getStatusIcon(bet) +
                            "\n            status-" +
                            bet.status.value.toLowerCase(),
                        }),
                      ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm.mobile
              ? _c(
                  "div",
                  { staticClass: "rows mobile" },
                  _vm._l(_vm.ticket.bets, function (bet, key) {
                    return _c("div", { key: key, staticClass: "row" }, [
                      _vm.mobile
                        ? _c("div", { staticClass: "bet-item" }, [
                            _c("div", { staticClass: "subtitle" }, [
                              _c("span", { staticClass: "normal" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("capitalize")(
                                        _vm._f("translate")("general_round")
                                      )
                                    ) +
                                    _vm._s(": ") +
                                    " "
                                ),
                              ]),
                              _c("span", { staticClass: "bolded" }, [
                                _vm._v(" " + _vm._s(bet.eventId) + " "),
                              ]),
                              _vm._v(" | "),
                              _c("span", { staticClass: "normal" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("translate")(bet.typeValue)) +
                                    " "
                                ),
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass: "status",
                                  class:
                                    "status-" + bet.status.value.toLowerCase(),
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(bet.status.name.toUpperCase()) +
                                      " " +
                                      _vm._s(
                                        bet.winnings
                                          ? bet.winnings.toFixed(2)
                                          : ""
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _vm.isOutcomeArray(bet.outcome.title)
                              ? _c(
                                  "div",
                                  { staticClass: "title" },
                                  _vm._l(
                                    _vm.formatOutcome(bet.outcome.title),
                                    function (value, key) {
                                      return _c(
                                        "span",
                                        {
                                          key: key,
                                          staticClass: "played-numbers",
                                          class: {
                                            active: _vm.isBallDrawn(bet, value),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("translate")(value)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            !_vm.isOutcomeArray(bet.outcome.title)
                              ? _c("div", { staticClass: "title" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("translate")(bet.outcome.title)
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "odd" }, [
                              _c("span", { staticClass: "normal" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(bet.odd ? bet.odd.toFixed(2) : "") +
                                    " "
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "payment-info" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "payin-info" }, [
              _c("span", { staticClass: "label" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("translate")("general_pay_in_details")) +
                    " "
                ),
              ]),
              _c("span", { staticClass: "value" }, [
                _vm._v(" " + _vm._s(_vm.ticket.payin.toFixed(2)) + " "),
              ]),
            ]),
            _vm.ticket.payinTax
              ? _c("div", { staticClass: "tax-info" }, [
                  _c("span", { staticClass: "label" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("translate")("general_payin_tax")) +
                        " "
                    ),
                  ]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(" " + _vm._s(_vm.ticket.payinTax.toFixed(2)) + " "),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "row winning" }, [
            _c("span", { staticClass: "label" }, [
              _vm._v(
                " " + _vm._s(_vm._f("translate")("general_winning")) + " "
              ),
            ]),
            _c("span", { staticClass: "value" }, [
              _vm._v(" " + _vm._s(_vm.ticket.payout.toFixed(2)) + " "),
            ]),
          ]),
          _vm.ticket.superBonus
            ? _c("div", { staticClass: "row bonus" }, [
                _c("div", { staticClass: "super-bonus" }, [
                  _c("span", { staticClass: "label" }, [
                    _c("i", { staticClass: "icon icon-ajs" }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.ticket.superBonus.name) +
                        " " +
                        _vm._s(" +" + _vm.ticket.superBonus.amount.toFixed(2)) +
                        " "
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "left" }, [
        _vm.ticketOpen && _vm.appConfig.company.ticketCancelAllowed
          ? _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.cancelTicket(_vm.ticket)
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("translate")("general_cancel_ticket")) +
                    " "
                ),
              ]
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "right" }, [
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                return _vm.rebet(_vm.ticket)
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm._f("translate")("general_rebet")) + " ")]
        ),
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm._f("translate")("general_close")) + " ")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropdown,
          expression: "closeDropdown",
        },
      ],
      staticClass: "dropdown",
      class: { expanded: _vm.expanded },
    },
    [
      _c("label", { on: { click: _vm.getCurrentValue } }, [
        _vm._v(
          " " +
            _vm._s(_vm._f("translate")(_vm.label)) +
            " " +
            _vm._s(_vm.item) +
            " "
        ),
      ]),
      _c("i", {
        staticClass: "icon",
        class: _vm.dropdownIcon,
        on: { click: _vm.expandDropdown },
      }),
      _vm.expanded
        ? _c(
            "div",
            { staticClass: "dropdown-list" },
            _vm._l(_vm.options, function (option, key) {
              return _c(
                "div",
                {
                  key: key,
                  staticClass: "dropdown-item",
                  on: {
                    click: function ($event) {
                      return _vm.setSelection(option)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(option.value) + " ")]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
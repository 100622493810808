var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fullScreenAvailable
    ? _c("div", {
        staticClass: "full-screen",
        on: { click: _vm.goToFullScreen },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "button",
      class: { active: _vm.outcome.active },
      on: {
        click: function ($event) {
          return _vm.click(_vm.outcome)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "checkbox",
          class: [
            { active: _vm.outcome.active },
            "color-group-" + _vm.outcome.id,
          ],
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.outcome.active,
                expression: "outcome.active",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.outcome.active)
                ? _vm._i(_vm.outcome.active, null) > -1
                : _vm.outcome.active,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.outcome.active,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.outcome, "active", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.outcome,
                        "active",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.outcome, "active", $$c)
                }
              },
            },
          }),
          _c("label"),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
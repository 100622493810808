export default {
  appConfig: (state) => state.appConfig,
  appLayout: (state) => state.layout || 'Expanded',
  clientApp: (state) => state.clientApp,
  palette: (state) => state.palette,
  platformName: (state) => state.platformName,
  platforms: (state) => state.platforms,
  platform: (state) => state.platforms[state.platformName],
  token: (state) => state.player.auth.token,
  gameBgColor: (state) => state.gameBgColor,
  bettingBodyBgColor: (state) => state.bettingBodyBgColor,
  colors: (state) => state.colors,
  totalBalls: (state) => state.totalBalls,
  isGameLoaded: (state) => state.isGameLoaded,
  colorGroup: (state) => state.colorGroup,
  balls: (state) => state.balls,
  luckySixBet: (state) => state.luckySixBet,
  betsModel: (state) => state.betsModel,
  bet: (state) => state.bet,
  specialBets: (state) => state.specialBets,
  ticket: (state) => state.ticket,
  randomNumbers: (state) => state.randomNumbers,
  resetAllBets: (state) => state.resetAllBets,
  ball: (state) => state.ball,
  rules: (state) => state.rules,
  bettingEnabled: (state) => state.bettingEnabled,
  round: (state) => state.round,
  roundInfo: (state) => state.roundInfo,
  allColorsSelected: (state) => state.allColorsSelected,
  stats: (state) => state.stats,
  resultsData: (state) => state.resultsData,
  player: (state) => state.player,
  notifications: (state) => state.notifications,
  timeForRemoveNotification: (state) => state.timeForRemoveNotification,
  translations: (state) => state.translations,
  preball: (state) => state.preball,
  colorGroupValue: (state) => state.colorGroupValue,
  payinDisabled: (state) => state.payinDisabled,
  requestDisabled: (state) => state.requestDisabled,
  lastTicketsData: (state) => state.lastTicketsData,
  ticketHistoryData: (state) => state.ticketHistoryData,
  expandTicketHistory: (state) => state.expandTicketHistory,
  errorOverlay: (state) => state.errorOverlay,
  lang: (state) => state.lang,
  popTenants: (state) => state.popTenants,
  plugins: (state) => state.plugins,
  isAudioOn: (state) => state.player.audio,
  drawnBalls: (state) => state.drawnBalls,
  showModalStats: (state) => state.showModalStats,
  pickNumbers: (state) => state.pickNumbers,
  maxFutureBets: (state) => state.appConfig.company.betslip.meta.maxFutureBets,
  bettingLocked: (state) => state.bettingLocked,
  lastTicketsInProgress: (state) => state.lastTicketsInProgress,
  ticketHistoryInProgress: (state) => state.ticketHistoryInProgress,
  ticketHistoryRangeError: (state) => state.ticketHistoryRangeError,
  bettingDisabled: (state) => state.bettingDisabled,
  ticketBlockTime: (state) => state.appConfig.ticketBlockTime,
  predefinedStakeValues: (state) => state.appConfig.company.predefinedStakes,
  isFullScreen: (state) => state.isFullScreen,
  localizedTranslations: (state) => state.localizedTranslations,
  integrationMode: (state) => state.appConfig.company.mode,
  bets: (state) => state.appConfig.product.bets,
  showNewFeatures: (state) => state.showNewFeatures,
  showFeed: (state) => state.showFeed,
  thirdPartyToken: (state) => state.thirdPartyToken,
};

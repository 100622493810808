import store from '@/store';
import assign from 'lodash/assign';
import { merge } from 'lodash';
import api from '../api/index';
import GameStrategy from './gameStrategy';

export default class PlaytechGameStrategy extends GameStrategy {
  constructor(config, queryParams) {
    super();
    this.config = config;
    this.params = queryParams;
    this.mandatoryParams = [
      'tenantId',
      'platform',
      'tempToken',
      'username',
      'game',
      'clientplatform',
      'clienttype',
    ];
  }

  loadGame() {
    api.getAppConfig(this.config).then((response) => {
      const playtechGameConfig = response;
      this.config.product.bets = assign(playtechGameConfig.data.config.bets);
      this.config = merge(this.config, playtechGameConfig.data.config);
      this.setSpecialsVisibility();
      this.setDrawDetails();
      store.dispatch('updateAppConfig', this.config);
      store.dispatch('updateProductRules', playtechGameConfig.data.config.rules);
      store.dispatch('updateFutureBets', playtechGameConfig.data.config.futureBets.value);

      if (!this.missingParams(this.params)) {
        if (this.isPopIntegration()) {
          const tenantDetails = store.getters.popTenants[this.config.tenantId];
          this.config.password = tenantDetails.password;
          this.getTemporaryToken();
        } else {
          this.login();
        }
      } else {
        store.dispatch('updateNotifications', {
          status: 'error',
          message: 'Query parameters are missing.',
        });
      }
    });
  }

  isPopIntegration() {
    const { tenantId } = this.config;
    return Object.keys(store.getters.popTenants).includes(tenantId);
  }

  getTemporaryToken() {
    api.getTempToken(this.config).then((response) => {
      const { tempToken } = response.data;
      store.dispatch('updatePlayerToken', tempToken);
      if (tempToken) this.login(tempToken);
    });
  }

  login(token) {
    if (token) this.config.tempToken = token;
    if (this.config.tempToken) {
      api.playTechLogin(this.config).then((loginResponse) => {
        store.dispatch('updatePlayerInfo', loginResponse.data);
        if (loginResponse.data) store.dispatch('loadLastTickets');
      }, (error) => {
        if (error.response) {
          const errorData = error.response.data || {};
          const errorMessage = errorData.message ? errorData.message : 'general_login_error';
          store.dispatch('updateNotifications',
            {
              status: 'error',
              message: errorMessage,
              duration: 7000,
            });
        }
      });
    }
  }
}

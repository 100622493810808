<template>
  <button class="button"
          :class="{active: outcome.active}"
          @click="click(outcome)">
    <div class="checkbox"
         :class="[{active: outcome.active}, `color-group-${outcome.id}`]">
      <input type="checkbox"
             v-model="outcome.active"/>
      <label></label>
    </div>
  </button>
</template>

<script>
export default {
  name: 'FirstBallColor',
  props: {
    outcome: {
      type: Object,
      default: () => {},
      required: false,
    },
    rules: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  data() {
    return {
      selection: [],
    };
  },
  methods: {
    click(outcome) {
      this.$emit('change', outcome);
    },
  },
};
</script>

<style scoped lang="scss">
  @for $i from 1 through length($luckysix-ball-colors) {
    $color: nth($luckysix-ball-colors, $i);
    $index: $i - 1;

    .color-group-#{$index} label {
      background: $color;
    }
  }
</style>

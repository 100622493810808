<template>
  <div class="accordion-wrapper">
    <div class="accordion-header"
         @click="setExpanded">
      <div class="accordion-title">
        {{title}}
      </div>
      <i class="icon"
         :class="isExpanded && expanded ? 'icon-arrow-up-a' : 'icon-arrow-down-a'">
      </i>
    </div>
    <div v-show="isExpanded && expanded"
         class="accordion-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    setExpanded() {
      this.expanded = !this.expanded;
      this.$emit('change', this.expanded);
    },
  },
};
</script>

<style lang="scss">
  .accordion-wrapper {
    .accordion-header {
      width: 100%;
      height: 40px;
      background-color: var(--dropdown-bg, var(--button-bg));
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--text-primary-1);
      font-size: 14px;
      padding: 0 16px;
      border-radius: 2px;
      cursor: pointer;
      transition: all .5s ease;

      &:hover {
        background-color: var(--dropdown-hover-bg, var(--button-hover-bg));
      }

      &:active {
        background-color: var(--dropdown-pressed-bg, var(--button-pressed-bg));
      }

      &.disabled {
        background-color: var(--dropdown-pressed-bg, var(--button-pressed-bg));
        color: var(--text-primary-3);
      }
    }

    .accordion-content {
      width: 100%;
      float: left;
      overflow: hidden;
    }
  }
</style>

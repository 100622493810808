<template>
  <div class="full-screen"
       v-if="fullScreenAvailable"
       @click="goToFullScreen">
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import isMobile from 'ismobilejs';

export default {
  name: 'FullScreen',
  computed: {
    ...mapGetters([
      'appConfig',
      'isFullScreen',
    ]),
    fullScreenAvailable() {
      return isMobile().any
        && this.appConfig.product.fullScreenEnabled
        && this.fullScreenEnabled
        && !this.isFullScreen;
    },
    fullScreenEnabled() {
      return document.fullscreenEnabled
        || document.webkitFullscreenEnabled
        || document.mozFullScreenEnabled
        || document.msFullscreenEnabled;
    },
  },
  methods: {
    ...mapActions([
      'setFullscreenStatus',
    ]),
    goToFullScreen() {
      if (this.fullScreenAvailable) {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen({
            navigationUI: 'hide',
          });
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT, {
            navigationUI: 'hide',
          });
        }
        this.setFullscreenStatus(true);
      }
    },
    onResize() {
      const fullscreen = (document.fullscreenElement)
        || (document.webkitFullscreenElement)
        || (document.mozFullScreenElement)
        || (document.msFullscreenElement);

      this.setFullscreenStatus(fullscreen);
    },
  },
  mounted() {
    this.setFullscreenStatus(false);
    window.addEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss" scoped>
.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
</style>

export default {
  common: (lang) => {
    switch (lang) {
      case 'hr':
      case 'bs':
      case 'cnr':
        return import(/* webpackChunkName: "i18.hr" */'./l6.hr');
      case 'cs':
        return import(/* webpackChunkName: "i18.cs" */'./l6.cs');
      case 'ro':
        return import(/* webpackChunkName: "i18.ro" */'./l6.ro');
      case 'rs-Latn':
        return import(/* webpackChunkName: "i18.rs-Latn" */'./l6.rs-Latn');
      case 'rs':
        return import(/* webpackChunkName: "i18.rs" */'./l6.rs');
      case 'de':
        return import(/* webpackChunkName: "i18.de" */'./l6.de');
      case 'sl':
        return import(/* webpackChunkName: "i18.sl" */'./l6.sl');
      case 'it':
        return import(/* webpackChunkName: "i18.it" */'./l6.it');
      case 'es':
        return import(/* webpackChunkName: "i18.es" */'./l6.es');
      case 'fr':
        return import(/* webpackChunkName: "i18.fr" */'./l6.fr');
      default:
        return import(/* webpackChunkName: "i18.en" */'./l6.en');
    }
  },
};

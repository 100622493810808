<template>
  <div class="notification-wrapper"
       :class="{wide: true}"
       v-if="notifications.length">
    <div class="item" v-for="(item, key) in notifications"
         :class="item.status"
         :key="key">
      <div class="item-icon">
        <i class="icon" :class="`icon-${item.icon}`"></i>
      </div>
      <div class="item-title"
           :title="`${item.message}`">
        {{item.message | translate}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TicketResolver',
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'notifications',
    ]),
  },
};
</script>

<style lang="scss" scoped>
  .notification-wrapper {
    float: left;
    width: 100%;
    font-size: 14px;
    cursor: default;
    border-radius: 2px;

    .item {
      height: 100%;
      line-height: 1.5;
      padding: 0 10px;
      border-radius: 2px;

      &.info {
        background: var(--brand-bg);
        color: var(--brand-text-color, var(--text-primary-1));
      }

      &.resolving {
        background: var(--system-neutral);
        color: var(--system-neutral-text-color);
      }

      &.success, &.closed {
        background: var(--system-positive);
        color: var(--text-primary-1);
      }

      &.error, &.rejected {
        background: var(--system-negative);
        color: var(--system-positive-text-color, var(--text-primary-1));
      }

      .item-icon {
        height: 100%;
        float: left;
        line-height: 42px;
        font-size: 12px;
      }

      .item-title {
        margin-left: 10px;
        float: left;
        width: 90%;
        height: 100%;
        overflow: hidden;
        padding: 10px 0;
      }
    }
  }
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accordion-wrapper" }, [
    _c(
      "div",
      { staticClass: "accordion-header", on: { click: _vm.setExpanded } },
      [
        _c("div", { staticClass: "accordion-title" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
        _c("i", {
          staticClass: "icon",
          class:
            _vm.isExpanded && _vm.expanded
              ? "icon-arrow-up-a"
              : "icon-arrow-down-a",
        }),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isExpanded && _vm.expanded,
            expression: "isExpanded && expanded",
          },
        ],
        staticClass: "accordion-content",
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="visualization-wrapper">
    <iframe class="nopointer-events"
            v-if="visualSource"
            id="luckysix-visual-frame"
            frameborder="0"
            scrolling="no"
            :src="visualSource"
            allow="autoplay">
    </iframe>
  </div>
</template>

<script>
import { has, shuffle, trimStart } from 'lodash';
import { mapGetters } from 'vuex';
import isMobile from 'ismobilejs';
import integrator from '@/utility/integrator';
import webViewIntegration from '@/utility/webViewIntegration';
import { routes, serverEnv } from '../../../../config/index';

export default {
  name: 'VisualizationWrapper',
  data() {
    return {
      visualSource: '',
      visualPlugin: {},
    };
  },
  computed: {
    ...mapGetters([
      'appConfig',
      'isAudioOn',
    ]),
    isMob() {
      return isMobile().any || window.innerWidth <= 660;
    },
    isWebViewIntegration() {
      return webViewIntegration.isActive() && has(window, 'WebAppListener');
    },
  },
  methods: {
    getPath(plugin) {
      const { companyUuid, dc } = this.appConfig.company;
      const { oddsType } = this.appConfig.product;
      const { language } = this.appConfig;
      const { cpvUuid } = this.appConfig.product;
      const env = serverEnv !== 'production' ? 'staging' : 'production';
      const device = this.isMob ? 'mobile' : 'web';
      const channel = this.isWebViewIntegration ? 'mobile' : 'web';

      if (plugin.type) {
        let path = `?layout=${device}&quality=high&locale=${language}&channel=${channel}&env=${env}&tenantId=${companyUuid}&productId=${cpvUuid}`;
        path += dc ? `&platformDataCenter=${trimStart(dc, '-')}` : '';
        return path;
      }
      return `/?mode=plugin&q=${device}&lang=${language}&company=${companyUuid}&odds=${oddsType}`;
    },
    setVisualSource() {
      const path = this.getPath(this.visualPlugin);
      this.visualSource = this.visualPlugin.origin[serverEnv] + path;
    },
    addGcmToSource() {
      const params = {
        scm: {
          url: shuffle(routes.gcmApi)[0],
          serverUrl: routes.sevenApi,
          env: serverEnv,
          channel: this.appConfig.product.cpvUuid,
          clientType: this.visualPlugin.clientType,
          clientSubType: this.visualPlugin.clientSubType,
          encoding: this.visualPlugin.encoding.default,
        },
      };

      const paramsStringified = JSON.stringify(params.scm);
      if (!this.visualPlugin.type) {
        this.visualSource += `&scm=${encodeURI(paramsStringified)}`;
      }
    },
    sendSoundSettings() {
      const targetFrame = document.getElementById('luckysix-visual-frame');
      integrator.sendMessage({
        type: 'soundSettings',
        data: {
          muted: !this.isAudioOn,
        },
      }, 0, targetFrame);
    },
  },
  created() {
  },
  mounted() {
    this.visualPlugin = this.appConfig.product.visualizationPlugin;
    this.setVisualSource();
    this.addGcmToSource();
    window.addEventListener('message', (event) => {
      if (event.data === 'VisualizationReady') {
        this.sendSoundSettings();
      }
    }, {
      once: true,
    });
  },
  watch: {
    isAudioOn() {
      this.sendSoundSettings();
    },
  },
};
</script>

<style scoped lang="scss">
  .visualization-wrapper {
    background: var(--card);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
</style>

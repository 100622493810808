<template>
  <div class="betting-body">
    <BetInfoBar></BetInfoBar>
    <AddRemoveBet></AddRemoveBet>
    <mq-layout :mq="['largeMin', 'largeMax']">
      <div class="game-bets">
        <div class="main-bets">
          <selection-grid></selection-grid>
        </div>
        <div class="special-bets">
          <ShortcutBets/>
          <special-bets></special-bets>
        </div>
      </div>
    </mq-layout>
    <mq-layout :mq="['s', 'm', 'l', 'xl', 'smallMin', 'smallMax', 'mediumMax', 'large']">
      <div class="tabs">
        <div class="tab" v-for="(tab, key) in tabs"
             :class="[{'active': tab.id === selectedTab}, {'visible': tab.visible}]"
             @click="selectTab(tab)"
             :key="key">
          <span v-if="tab.visible">{{tab.name | translate}}</span>
        </div>
      </div>
      <div class="game-bets">
        <div class="tab-content"
             v-show="selectedTab === 0">
          <div class="main-bets">
            <selection-grid></selection-grid>
          </div>
          <ShortcutBets/>
        </div>
        <div class="tab-content"
             v-show="selectedTab === 1">
          <div class="special-bets">
            <special-bets></special-bets>
          </div>
        </div>
      </div>
    </mq-layout>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { filter } from 'lodash';
import BetInfoBar from '../common/BetInfoBar';
import AddRemoveBet from './AddRemoveBet';
import SelectionGrid from './SelectionGrid';
import ShortcutBets from './ShortcutBets';
import SpecialBets from './SpecialBets';

export default {
  name: 'BettingBody',
  components: {
    BetInfoBar,
    AddRemoveBet,
    SelectionGrid,
    ShortcutBets,
    SpecialBets,
  },
  data() {
    return {
      tabs: [
        {
          id: 0,
          name: 'games_tab_betting',
          visible: true,
        },
        {
          id: 1,
          name: 'games_tab_betting_special',
          visible: true,
        },
      ],
      selectedTab: 0,
      notifyUser: false,
      activeButtons: [],
    };
  },
  computed: {
    ...mapGetters([
      'betAdded',
      'ticket',
      'colors',
      'specialBets',
    ]),
  },
  methods: {
    ...mapActions([
      'resetBet',
      'resetSelection',
    ]),
    selectTab(tab) {
      if (!tab.visible) return;
      this.selectedTab = tab.id;
    },
    clearSelection() {
      this.resetBet();
      this.resetSelection();
    },
  },
  created() {
    const visibleSpecialBets = filter(this.specialBets, (bet) => bet.visible);
    if (!visibleSpecialBets.length) {
      this.tabs[1].visible = false;
    }
  },
  watch: {
    'ticket.bets': function addedBet() {
      this.clearSelection();
    },
  },
};
</script>

<style scoped lang="scss">
  .betting-body {
    width: 100%;
    float: left;
    margin: 0 auto;
    border-radius: $border-radius;
    position: relative;

    .add-remove-bet, .bet-info-bar-wrapper {
      width: 50%;
    }

    .tabs {
      float: left;
      width: 100%;
      height: 40px;
      border-bottom: 2px solid var(--tab-border-color);
      background-color: var(--tab-bg, var(--card-bg));
      font-size: 14px;
      color: var(--tab-text-color, var(--text-primary-1));

      .tab {
        float: left;
        min-width: 24%;
        line-height: 38px;
        cursor: pointer;
        padding-left: 1em;
        text-align: center;
        background-color: var(--tab-bg, var(--card-bg));
        transition: all .5s ease;

        &.visible {
          border-bottom: 2px solid var(--tab-border-color);
        }

        &:hover {
          background-color: var(--tab-hover-bg);
        }

        &:active {
          background-color: var(--tab-pressed-bg);
        }

        &.active {
          border-bottom: 2px solid var(--accent-bg, var(--brand-bg));
          color: var(--accent-bg, var(--brand-bg));
        }
      }
    }

    .game-bets {
      width: 100%;
      height: 100%;
      float: left;
      padding: 16px 8px;
      background-color: var(--card-bg);

      .main-bets {
        width: 50%;
        height: 100%;
        float: left;
      }

      .special-bets {
        width: 48%;
        height: 100%;
        float: right;
      }
    }
  }

  @media (min-width: 306px) and (max-width: 659px) {
    .tabs {
      .tab {
        width: 50%;
      }
    }

    .game-bets {
      .tab-content {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;

        .main-bets {
          width: 100%;
          order: 2;
        }

        .shortcut-bets {
          width: 100%;
          order: 1;
          margin-bottom: 8px;
        }

        .special-bets {
          width: 100%
        }
      }
    }
  }

  @media screen and (min-width: 660px) and (max-width: 1100px),
    (min-width: 1268px) and (max-width: 1483px) {
    .betting-body {
      .tabs {
        display: block;
      }

      .game-bets {
        .tab-content {
          .main-bets {
            width: 62%;
          }
          .special-bets {
            width: 100%;
            float: left;
          }
        }
      }
    }
  }
</style>

<template>
  <div class="bet-info-bar-wrapper">
    <div class="bet-info" v-if="bet.outcomes.length">
      <span class="subtitle">{{ bet.subtitle }}</span>
      <div class="title">
      <span v-for="(value, key) in bet.title"
            :key="key">
        {{ value.title | translate }}
      </span>
      </div>
    </div>
    <div class="empty-message" v-if="!bet.outcomes.length">
      <i class="icon icon-info"></i>
      <span>{{'general_ticket_empty_message' | translate}}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BetInfoBar',
  computed: {
    ...mapGetters([
      'bet',
    ]),
  },
};
</script>

<style lang="scss">
  .bet-info-bar-wrapper {
    float: left;
    width: 100%;
    height: 56px;
    padding: 12px;
    line-height: 1.3;
    color: var(--text-primary-1);

    .title, .subtitle {
      width: 100%;
      float: left;
    }

    .title {
      font-size: 14px;

      span {
        margin-right: 7px;
      }
    }

    .subtitle {
      font-size: 12px;
      opacity: .6;
    }

    .empty-message {
      font-size: 12px;
      line-height: 34px;
      color: var(--text-primary-3);

      span {
        margin-left: 10px;
      }
    }
  }

  @media (max-width: 960px) {
    .bet-info-bar-wrapper {
      display: none;
    }
  }
</style>

<template>
  <div class="stats-wrapper">
    <div class="toggle-bet-stats" v-if="bets">
      <div class="bet-stats" v-if="bets['7'].value">
        <div class="title"
             :title="'games_first_ball_odd_even' | translate | uppercase">
          {{'games_first_ball_odd_even' | translate | uppercase}}
        </div>
        <div class="subtitle">
          {{'luckysix_first_number_label' | translate | uppercase}}
        </div>
        <vue-circle
          v-if="stats && stats.firstBallOddEven.even"
          :progress="Number(stats.firstBallOddEven.even)"
          :size="circleSize"
          :reverse="false"
          line-cap="square"
          :fill="fill"
          empty-fill="#d1d1d1"
          :animation-start-value="0.0"
          :start-angle="-1.57"
          insert-mode="append"
          :thickness="3"
          :show-percent="true">
          <p>{{'general_even' | translate}}</p>
        </vue-circle>
        <div class="indicators">
          <div class="odd">
            <span>{{'general_odd' | translate}}</span>
          </div>
          <div class="even">
            <span>{{'general_even' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="bet-stats" v-if="bets['8'].value">
        <div class="title"
             :title="'luckysix_first_ball_low_high' | translate | uppercase">
          {{'luckysix_first_ball_low_high' | translate | uppercase}}
        </div>
        <div class="subtitle">
          {{'luckysix_first_number_label' | translate | uppercase}}
        </div>
        <vue-circle class="circle"
                    v-if="stats && stats.firstBallLowHigh.high"
                    :progress="Number(stats.firstBallLowHigh.high)"
                    :size="circleSize"
                    :reverse="false"
                    line-cap="square"
                    :fill="fill"
                    empty-fill="#d1d1d1"
                    :animation-start-value="0.0"
                    :start-angle="-1.57"
                    insert-mode="append"
                    :thickness="3"
                    :show-percent="true">
          <p>{{'general_over' | translate}}</p>
        </vue-circle>
        <div class="indicators">
          <div class="odd">
            <span>{{'general_under' | translate}}</span>
          </div>
          <div class="even">
            <span>{{'general_over' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="bet-stats" v-if="bets['11'].value">
        <div class="title"
             :title="'luckysix_more_preballs' | translate | uppercase">
          {{'luckysix_more_preballs' | translate | uppercase}}
        </div>
        <div class="subtitle">
          {{'luckysix_preballs_label' | translate | uppercase}}
        </div>
        <vue-circle
          v-if="stats && stats.preballsMoreOddEven.even"
          :progress="Number(stats.preballsMoreOddEven.even)"
          :size="circleSize"
          :reverse="false"
          line-cap="square"
          :fill="fill"
          empty-fill="#d1d1d1"
          :animation-start-value="0.0"
          :start-angle="-1.57"
          insert-mode="append"
          :thickness="3"
          :show-percent="true">
          <p>{{'general_even' | translate}}</p>
        </vue-circle>
        <div class="indicators">
          <div class="odd">
            <span>{{'general_odd' | translate}}</span>
          </div>
          <div class="even">
            <span>{{'general_even' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="bet-stats" v-if="bets['6'].value">
        <div class="title"
             :title="'luckysix_preballs_sum' | translate | uppercase">
          {{'luckysix_preballs_sum' | translate | uppercase}}
        </div>
        <div class="subtitle">
          {{'luckysix_preballs_sum_label' | translate | uppercase}}
        </div>
        <vue-circle
          v-if="stats && stats.preballsSumLowHigh.high"
          :progress="Number(stats.preballsSumLowHigh.high)"
          :size="circleSize"
          :reverse="false"
          line-cap="square"
          :fill="fill"
          empty-fill="#d1d1d1"
          :animation-start-value="0.0"
          :start-angle="-1.57"
          insert-mode="append"
          :thickness="3"
          :show-percent="true">
          <p>{{'general_over' | translate}}</p>
        </vue-circle>
        <div class="indicators">
          <div class="odd">
            <span>{{'general_under' | translate}}</span>
          </div>
          <div class="even">
            <span>{{'general_over' | translate}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="number-bet-stats">
      <div class="drawn-numbers">
        <div class="title">
          {{'general_hot_numbers' | translate | uppercase}}
        </div>
        <div class="balls">
          <div class="ball"
               v-for="(value, key) in mostDrawnNumbers"
               :key="key"
               :class="[{'active': true}, `color-border-${getBallColor(value)}`]">
            {{value.ball}}
            <div class="count">
              {{value.count}}
            </div>
          </div>
        </div>
      </div>
      <div class="drawn-numbers">
        <div class="title">
          {{'general_cold_numbers' | translate | uppercase}}
        </div>
        <div class="balls">
          <div class="ball"
               v-for="(value, key) in leastDrawnNumbers"
               :key="key"
               :class="[{'active': true}, `color-border-${getBallColor(value)}`]">
            {{value.ball}}
            <div class="count">
              {{value.count}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="color-bet-stats">
      <div class="bet-stats">
        <div class="title">{{'general_color_frequency' | translate | uppercase}}</div>
        <div class="colors">
          <div class="color" :key="index"
               v-for="(value, key, index) in firstBallColor">
            <div class="color-title">
              {{`luckysix_color_${index}` | translate | capitalize}}
            </div>
            <div class="card"
                 :class="`color-group-${index}`">
              <div class="card-color">
                <input type="checkbox"/>
                <label></label>
              </div>
            </div>
            <div class="color-count">{{value}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueCircle from 'vue2-circle-progress/src/index';

export default {
  name: 'Stats',
  components: {
    VueCircle,
  },
  data() {
    return {
      circleSize: 120,
      completedSteps: 50,
      startAngle: '-1.57',
      totalSteps: 100,
    };
  },
  computed: {
    ...mapGetters([
      'stats',
      'appConfig',
    ]),
    mostDrawnNumbers() {
      return this.stats.drawnMostTimes;
    },
    leastDrawnNumbers() {
      return this.stats.drawnLeastTimes;
    },
    firstBallColor() {
      return this.stats.firstBallColor;
    },
    bets() {
      return this.appConfig.product.bets;
    },
    fill() {
      return {
        gradient: [this.appConfig.company.statsCircle],
      };
    },
  },
  methods: {
    getBallColor(number) {
      let colorId = '';

      if (number) {
        colorId = (number.ball - 1) % 8;
      }

      return colorId;
    },
  },
};
</script>

<style lang="scss" scoped>
  .stats-wrapper {
    float: left;
    width: 100%;
    background: var(--event-details-bg, var(--card-bg));
    .circle {
      color: var(--text-primary-1);
    }
    .title {
      color: var(--text-primary-1);
      font-weight: 500;
      font-size: 14px;
      @include truncate;
    }

    .bet-stats {
      margin-top: 16px;
    }

    .subtitle {
      color: var(--text-primary-2);
      font-size: 14px;
      margin-bottom: 10px;
    }

    .toggle-bet-stats {
      display: flex;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .bet-stats {
        width: calc(100% / 5);
        margin: 2%;
        text-align: center;

        .indicators {
          float: left;
          width: 100%;
          margin-top: 8px;

          .odd, .even {
            width: 15px;
            height: 15px;
            color: var(--text-primary-2);
            font-size: 14px;
            border-radius: 2px;
          }

          .odd {
            float: left;
            background-color: var(--text-primary-1);

            span {
              float: left;
              padding: 0 1.5em;
            }
          }

          .even {
            float: right;
            background-color: var(--brand-bg);

            span {
              float: right;
              padding: 0 1.5em;
            }
          }
        }
      }
    }

    .number-bet-stats {
      float: left;
      width: 100%;
      margin-top: 3px;

      .drawn-numbers {
        float: left;
        width: 50%;
        height: 100%;
        padding-bottom: 2em;

        .title, .subtitle {
          float: left;
          width: 100%;
          text-align: center;
          margin-bottom: 10px;
        }

        .balls {
          display: flex;
          position: relative;
          width: 100%;
          justify-content: center;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;

          .ball {
            border-width: 2px;
            line-height: 33px;
            flex-grow: 0;
            width: 38px;
            height: 38px;
            margin: 0 10px 0 0;
            text-align: center;
            cursor: default;

            .count {
              color: var(--text-primary-1);
              opacity: .5;
              font-size: 0.875em;
            }
          }
        }
      }
    }

    .color-bet-stats {
      float: left;
      width: 100%;

      .colors {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        .card {
          position: relative;
          border: none;
          outline: none;
          color: #e6e6e6;
          background-color: var(--button-bg);
          border-radius: $border-radius;
          height: $button-height;
          cursor: default;
        }

        .color {
          color: var(--text-primary-2);
          margin: 4px;
          flex-basis: 60px;

          .color-title {
            color: var(--text-primary-2);
            font-size: 0.875em;
            text-align: center;
          }

          .color-count {
            font-weight: 500;
            text-align: center;
          }
        }
      }
    }

    @for $i from 1 through length($luckysix-ball-colors) {
      $color: nth($luckysix-ball-colors, $i);
      $index: $i - 1;

      .color-group-#{$index} {
        margin: 8px 0;

        .card-color {
          position: absolute;
          width: 20px;
          height: 20px;
          background-color: var(--checkbox-bg);
          border-radius: 100%;
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
        }

        .card-color label {
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 100px;
          transition: all .5s ease;
          position: absolute;
          top: 5px;
          left: 5px;
        }

        .card-color input {
          display: none;
        }
      }

      .color-group-#{$index} label {
        background: $color;
      }
    }
  }

  @media (min-width: 320px) and (max-width: 660px) {
    .stats-wrapper {
      .toggle-bet-stats {
        .bet-stats {
          width: 47%;
          margin: 18px 0;
        }
      }
      .number-bet-stats {
        flex-wrap: wrap;
        .drawn-numbers {
          width: 100%;
        }
      }
      .color-bet-stats {
        text-align: center;

        .colors {
          justify-content: space-between;
          .color {
            flex-basis: 80px;
            margin: 0;
          }
        }
      }
    }
  }

  @media (min-width: 416px) and (max-width: 510px) {
    .stats-wrapper {
      .color-bet-stats {
        .colors {
          .color {
            flex-basis: 99px;
          }
        }
      }
    }
  }

  @media (min-width: 511px) and (max-width: 626px) {
    .stats-wrapper {
      .color-bet-stats {
        .colors {
          .color {
            flex-basis: 120px;
            margin: 1px;
          }
        }
      }
    }
  }

  @media (min-width: 626px) and (max-width: 640px) {
    .stats-wrapper {
      .color-bet-stats {
        .colors {
          .color {
            flex-basis: 120px;
            margin: 3px;
          }
        }
      }
    }
  }

  @media (min-width: 320px) and (max-width: 960px) {
    .stats-wrapper {
      float: left;
    }
  }

  @media (min-width: 1268px) {
    .stats-wrapper {
      .toggle-bet-stats {
        .bet-stats {
          width: 45%;
          margin: 2%;
        }
      }
      .number-bet-stats {
        .drawn-numbers {
          width: 100%;
        }
      }
    }
  }

  @media (min-width: 1484px) {
    .stats-wrapper {
      .color-bet-stats {
        .colors {
          .color {
            flex-basis: 80px;
            margin: 5px;
          }
        }
      }
    }
  }
</style>

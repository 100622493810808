var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "visualization-wrapper" }, [
    _vm.visualSource
      ? _c("iframe", {
          staticClass: "nopointer-events",
          attrs: {
            id: "luckysix-visual-frame",
            frameborder: "0",
            scrolling: "no",
            src: _vm.visualSource,
            allow: "autoplay",
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
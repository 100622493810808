var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showNewFeatures && _vm.newFeatures
    ? _c(
        "div",
        { staticClass: "new-features-wrapper" },
        [
          _c("Modal", [
            _c(
              "div",
              { attrs: { slot: "header" }, slot: "header" },
              [_c("LocalizedLabel", { attrs: { translation: "newFeatures" } })],
              1
            ),
            _c(
              "div",
              { attrs: { slot: "body" }, slot: "body" },
              _vm._l(_vm.newFeatures, function (feature) {
                return _c(
                  "div",
                  { key: feature.id, staticClass: "feature-list" },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/" +
                          feature.icon +
                          ".png"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "feature-text" }, [
                      _c(
                        "span",
                        { staticClass: "title" },
                        [
                          _c("LocalizedLabel", {
                            attrs: { translation: feature.title },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        { staticClass: "description" },
                        [
                          _c("LocalizedLabel", {
                            attrs: { translation: feature.description },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
            _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
              _c(
                "button",
                { on: { click: _vm.toggleNewFeatures } },
                [_c("LocalizedLabel", { attrs: { translation: "play" } })],
                1
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue';
import Vuex from 'vuex';
import { merge } from 'lodash';
import betslipStore from '@nsoft/games-client-betslip/src/store/storeConfig';
import ticketHistoryStore from '@nsoft/games-sdk-js/src/store/storeConfig';
import createPersistedState from 'vuex-persistedstate';
import storeConfig from './storeConfig';

Vue.use(Vuex);

export default new Vuex.Store(merge({}, betslipStore, ticketHistoryStore, storeConfig, {
  plugins: [createPersistedState({
    paths: ['showNewFeatures'],
  })],
}));

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-mask", on: { click: _vm.closeModal } },
    [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c(
          "div",
          {
            staticClass: "modal-container",
            on: {
              click: function ($event) {
                return $event.stopPropagation()
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "modal-header" },
              [
                _vm._t("header", function () {
                  return [_vm._v(" default header ")]
                }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _vm._t("body", function () {
                  return [_vm._v(" default body ")]
                }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "modal-footer" },
              [
                _vm._t("footer", function () {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "modal-default-button",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("close")
                          },
                        },
                      },
                      [_vm._v(" Close ")]
                    ),
                  ]
                }),
              ],
              2
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
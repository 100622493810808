import { v4uuid } from '@nsoft/games-sdk-js/src/utility/index';
import { upperFirst } from 'lodash';

export default {
  getGlobalHeaders(data) {
    const { companyUuid } = data.company;
    const { cpvUuid } = data.product;
    const { language } = data;
    return {
      'X-Nsft-Ngs-Company': companyUuid,
      'X-Nsft-Ngs-Product': cpvUuid,
      'X-Request-ID': v4uuid.generate(),
      'Accept-Language': language,
    };
  },
  getSevenHeaders(data) {
    const { companyUuid } = data.appConfig.company;
    const { productName } = data.appConfig.product;
    return {
      Authorization: `Bearer ${data.player.auth.token}`,
      'SEVEN-LOCALE': data.appConfig.language,
      'SEVEN-TP-TOKEN': data.player.auth.tpToken,
      'HTTP-X-SEVEN-CLUB-UUID': companyUuid,
      'HTTP-X-NAB-PRODUCTNAME': productName,
      'HTTP-X-NAB-PRODUCTINSTANCE-ID': productName,
      'HTTP-X-NAB-DP': upperFirst(data.appConfig.clientplatform) || 'Web',
    };
  },
  getSevenMetaData(data) {
    const { productName, cpvUuid } = data.appConfig.product;
    return {
      product: productName,
      deliveryPlatform: upperFirst(data.appConfig.clientplatform) || 'Web',
      cpvUuid,
      paymentMethod: 'VirtualMoney',
      requestUuid: v4uuid.generate(),
      appDeviceUuid: v4uuid.generate(),
      sources: [
        {
          type: 'player',
          uuid: data.player.id,
        },
        {
          type: 'productInstance',
          uuid: 'f1c961e7-db98-4b4c-9673-1a9a712a8bed',
        },
      ],
    };
  },
  getMetaData(player) {
    return {
      player: {
        uuid: player.username, // check this info with playtech
        username: player.username,
        firstname: '',
        lastname: '',
        isThirdParty: false,
      },
    };
  },
  getAdditionalMeta(data) {
    const requestId = v4uuid.generate();
    return {
      gsId: '135',
      gpId: 'POP',
      requestId,
      clientPlatform: data.clientplatform,
      clientType: data.clienttype,
    };
  },
  formatTicketData(params) {
    let { bets } = params;

    bets = bets.map((bet) => ({
      eventId: null,
      value: String(bet.value),
      payin: bet.payment,
      numEvents: bet.rounds,
      type: bet.id,
      ticketComment: 'Prva Firma',
      ticketOddsOptions: 2,
      ticketType: 3,
    }));

    return {
      payin: params.payin,
      bets,
    };
  },
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ticket-wrapper" }, [
    _c(
      "div",
      { staticClass: "ticket-wrapper-content" },
      [
        _vm.appLayout === "Expanded"
          ? _c(
              "div",
              { staticClass: "tabs" },
              _vm._l(_vm.ticketWrapperTabs, function (tab, key) {
                return _c(
                  "div",
                  {
                    key: key,
                    staticClass: "tab",
                    class: {
                      active: key === _vm.selectedTicketTab,
                      disabled: tab.disabled,
                    },
                    attrs: { disabled: tab.disabled },
                    on: {
                      click: function ($event) {
                        return _vm.selectTab(tab, key)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "title" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm._f("translate")(tab.name)) + " "
                        ),
                        tab.indicator ? _c("BetsCounter") : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "betslip-box",
            class: { disabled: _vm.bettingLocked },
          },
          [
            _c("Betslip", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectedTicketTab === 0,
                  expression: "selectedTicketTab === 0",
                },
              ],
              attrs: {
                config: _vm.getBetslipConfig(),
                betslipType: _vm.getBetslipType(),
                theme: _vm.appConfig.company.theme,
                balance: _vm.player.balance,
                bettingLocked: _vm.bettingLocked,
                bettingDisabled: _vm.bettingDisabled,
                predefinedStakes: _vm.predefinedStakeValues,
                payinDisabled: _vm.payinDisabled,
              },
            }),
            !_vm.isMobile &&
            _vm.selectedTicketTab === 0 &&
            _vm.showNotification &&
            _vm.appLayout !== "Compact"
              ? _c("Notifications")
              : _vm._e(),
          ],
          1
        ),
        _c("LastTickets", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.selectedTicketTab === 1,
              expression: "selectedTicketTab === 1",
            },
          ],
          attrs: { isMob: _vm.isMobile, tickets: _vm.lastTicketsData },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }